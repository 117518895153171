import React from 'react';

const AttendanceSvg = ({
  height = 21,
  width = 21,
  viewBox = '0 0 512 512'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <>
      <svg
        id="OBJECT"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        fill="currentColor"
      >
        <g>
          <g>
            <path d="m39.721 149.082c-7.629-3.217-16.421.365-19.635 7.995-9.008 21.38-15.036 43.935-17.921 67.037-1.112 8.904 5.814 16.851 14.895 16.851 7.452 0 13.91-5.551 14.858-13.136 2.543-20.375 7.86-40.263 15.8-59.112 3.214-7.63-.366-16.421-7.997-19.635z" />
            <path d="m119.159 435.139c-16.344-12.42-30.911-26.986-43.295-43.293-5.008-6.595-14.415-7.878-21.006-2.873-6.594 5.008-7.88 14.413-2.873 21.006 14.028 18.473 30.526 34.969 49.034 49.034 6.582 5.002 15.989 3.736 21.007-2.867 5.008-6.592 3.725-15.997-2.867-21.007z" />
            <path d="m458.107 101.931c-70.497-92.843-194.655-126.127-301.938-80.936-7.63 3.214-11.21 12.005-7.995 19.635 3.214 7.629 12.002 11.209 19.635 7.995 94.648-39.867 204.206-10.494 266.42 71.439 36.231 47.707 51.729 106.728 43.641 166.191-20.071 147.534-174.833 233.991-310.061 177.028-7.632-3.218-16.422.366-19.635 7.995-3.215 7.63.365 16.421 7.995 19.635 154.024 64.882 328.763-34.13 351.41-200.617 9.167-67.39-8.403-134.286-49.472-188.365z" />
            <path d="m31.917 284.081c-1.027-8.215-8.511-14.041-16.734-13.019-8.216 1.026-14.045 8.518-13.019 16.734 2.885 23.103 8.915 45.658 17.921 67.036 3.194 7.582 11.956 11.23 19.635 7.995 7.63-3.214 11.21-12.006 7.995-19.635-7.938-18.847-13.253-38.735-15.798-59.111z" />
            <path d="m54.853 122.933c6.593 5.011 15.998 3.727 21.007-2.865 12.425-16.349 26.991-30.916 43.295-43.296 6.594-5.007 7.88-14.412 2.873-21.006-5.006-6.594-14.413-7.881-21.006-2.873-18.468 14.023-34.965 30.521-49.034 49.033-5.009 6.593-3.727 15.998 2.865 21.007z" />
            <path d="m434.944 255.955c0-99.196-80.702-179.898-179.898-179.898s-179.897 80.702-179.897 179.898 80.702 179.898 179.898 179.898 179.897-80.703 179.897-179.898zm-249.228 86.664c-6.465-5.172-7.513-14.606-2.341-21.071l56.681-70.851v-99.682c0-8.279 6.712-14.991 14.991-14.991s14.991 6.712 14.991 14.991v104.94c0 3.404-1.159 6.707-3.285 9.365l-59.966 74.957c-5.175 6.47-14.61 7.512-21.071 2.342z" />
          </g>
        </g>
        <g id="Layer_1" />
      </svg>
    </>
  );
};
export default AttendanceSvg;
