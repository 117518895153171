import React from 'react';

const TelephonicInterviewSvg = ({
  height = 22,
  width = 22,
  viewBox = '0 0 100 100'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M62.5 75.0003H16.6667V16.667H62.5V20.8337H66.6667V14.5837C66.6667 11.821 65.5692 9.17147 63.6157 7.21796C61.6622 5.26446 59.0127 4.16699 56.25 4.16699H22.9167C20.154 4.16699 17.5045 5.26446 15.551 7.21796C13.5975 9.17147 12.5 11.821 12.5 14.5837V85.417C12.5 88.1797 13.5975 90.8292 15.551 92.7827C17.5045 94.7362 20.154 95.8337 22.9167 95.8337H56.25C59.0127 95.8337 61.6622 94.7362 63.6157 92.7827C65.5692 90.8292 66.6667 88.1797 66.6667 85.417V60.0628H62.5V75.0003ZM47.9167 87.5003H31.25V83.3337H47.9167V87.5003ZM81.25 22.917H56.25C54.5924 22.917 53.0027 23.5755 51.8306 24.7476C50.6585 25.9197 50 27.5094 50 29.167V41.667C50 43.3246 50.6585 44.9143 51.8306 46.0864C53.0027 47.2585 54.5924 47.917 56.25 47.917H62.5V54.167C62.5021 54.5783 62.6258 54.9798 62.8557 55.3209C63.0855 55.662 63.4112 55.9274 63.7917 56.0837C64.0389 56.2003 64.31 56.2574 64.5833 56.2503C64.8575 56.2519 65.1293 56.1994 65.3831 56.0957C65.637 55.992 65.8678 55.8392 66.0625 55.6462L73.7708 47.917H81.25C82.9076 47.917 84.4973 47.2585 85.6694 46.0864C86.8415 44.9143 87.5 43.3246 87.5 41.667V29.167C87.5 27.5094 86.8415 25.9197 85.6694 24.7476C84.4973 23.5755 82.9076 22.917 81.25 22.917ZM66.6667 37.5003H58.3333V33.3337H66.6667V37.5003ZM79.1667 37.5003H70.8333V33.3337H79.1667V37.5003ZM47.9167 52.0837C47.9167 53.7318 47.4279 55.343 46.5122 56.7134C45.5966 58.0838 44.2951 59.1519 42.7724 59.7827C41.2496 60.4134 39.5741 60.5784 37.9576 60.2569C36.3411 59.9353 34.8562 59.1417 33.6908 57.9762C32.5253 56.8108 31.7317 55.3259 31.4101 53.7094C31.0886 52.0929 31.2536 50.4174 31.8843 48.8946C32.5151 47.3719 33.5832 46.0704 34.9536 45.1548C36.324 44.2391 37.9352 43.7503 39.5833 43.7503C41.7935 43.7503 43.9131 44.6283 45.4759 46.1911C47.0387 47.7539 47.9167 49.8735 47.9167 52.0837ZM39.5833 62.5003C42.5352 62.5033 45.3907 63.5508 47.6442 65.4574C49.8978 67.3639 51.4039 70.0065 51.8958 72.917H27.2708C27.7628 70.0065 29.2689 67.3639 31.5224 65.4574C33.776 63.5508 36.6315 62.5033 39.5833 62.5003Z" />
    </svg>
  );
};
export default TelephonicInterviewSvg;
