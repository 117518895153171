import React from 'react';

const SubmitCandidateSvg = ({
  height = 21,
  width = 21,
  viewBox = '0 0 512 512'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <defs>
        <style>
          {`

      .cls-1, .cls-2 {
        stroke-width: 0px;
      }`}
        </style>
      </defs>
      <path
        fill="currentColor"
        className="cls-2"
        d="M426.1,347c22.8-17.1,27.4-49.5,10.2-72.3-17.1-22.8-49.5-27.4-72.3-10.2-22.8,17.1-27.4,49.5-10.2,72.3,2.9,3.9,6.4,7.4,10.3,10.3-8,3-15.5,7.1-22.4,12.2,17,16,42.5,45.7,37.7,78.7h72.5c9.2,0,17.7-4.4,23.1-11.9,19.4-29.1-23.1-70.8-49-79.1h0Z"
      />
      <path
        fill="currentColor"
        className="cls-2"
        d="M170.1,359.3c-6.8-5.1-14.3-9.2-22.3-12.2,22.8-17.3,27.2-49.7,9.9-72.5-9.7-12.8-24.8-20.3-40.8-20.4-49.4-.2-70.3,64.2-30.9,92.8-25.9,8.1-68.4,50.1-49.1,79.1,5.3,7.4,13.9,11.9,23.1,11.9h72.3c-4.2-30.3,17-58.8,37.8-78.7h0Z"
      />
      <path
        fill="currentColor"
        className="cls-1"
        d="M291.2,348c53.3-34.4,29.1-119-35.2-119-35.8.2-64.7,29.5-64.4,65.3.1,21.7,11.1,41.8,29.2,53.7-33.5,11-59.9,37.1-71.4,70.5-5.7,18,4.3,37.3,22.3,43,3.1,1,6.2,1.5,9.4,1.6h149c15.4-.1,28.9-10.4,33.2-25.2,4.1-19.2-6.9-37.5-18.6-52.2-13.6-17.6-32.2-30.8-53.4-37.7h0Z"
      />
      <path
        fill="currentColor"
        className="cls-2"
        d="M199.9,209.1h112.2c13.3,0,24-10.8,24-24v-112.2c0-13.3-10.8-24-24-24h-112.2c-13.3,0-24,10.8-24,24v112.2c0,13.3,10.8,24,24,24h0ZM222.3,123.3l25.4,25.5,41.3-56.6c2.7-3.5,7.7-4.2,11.3-1.5,3.4,2.6,4.2,7.4,1.7,11l-46.8,64.1c-2.7,3.6-7.7,4.4-11.3,1.7-.3-.2-.6-.5-.9-.7l-32-32c-3.1-3.2-2.9-8.3.3-11.4,3.1-3,8-3,11.1,0h0Z"
      />
    </svg>
  );
};
export default SubmitCandidateSvg;
