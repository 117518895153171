import React from 'react';

const HolidaySvg = ({
  height = 21,
  width = 21,
  viewBox = '0 0 512 512'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <>
      <svg
        id="OBJECT"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        fill="currentColor"
      >
        <path d="M60.6,484.7c-.9,1.7-.5,3.3-.2,4,.2.6.9,2.1,2.4,2.1h38.1c.9,0,1.7-.6,2.3-1.6l44.5-84.1h-44.9l-42.1,79.7Z" />
        <path d="M427.3,405h-44.8l44.6,84.1c.5,1.1,1.3,1.6,2.2,1.6h38.1c1.5,0,2.2-1.4,2.4-2,.2-.6.7-2.3-.2-4l-42.1-79.8Z" />
        <path d="M85.8,395.3h383.7c3.8-1.1,7.4-3,10.2-5.9,4.9-4.9,7.4-11.6,6.9-18.5h0c-.9-12.4-12-22.1-25.3-22.1h-199.8l30.8-120.3s0,0,0,0l6.8-26.4c0,0,.1,0,.2-.1,1.4-.9,2.8-1.7,4.3-2.5.3-.1.6-.3.8-.4,1.8-.9,3.7-1.6,5.7-2.2.5-.2,1-.3,1.5-.4,1.7-.5,3.4-.9,5.2-1.3.5,0,1-.2,1.5-.3,2.2-.4,4.5-.7,6.8-.8.5,0,.9,0,1.4,0,1.2,0,2.4-.1,3.6-.1s1,0,1.5,0c2.1,0,4.3.2,6.4.4,1,.1,2,.2,3,.3,3.2.4,6.4,1,9.7,1.9,2.7.7,5.3,1.5,7.9,2.5.8.3,1.6.6,2.4,1,1.7.7,3.4,1.4,5,2.1.9.4,1.7.9,2.6,1.3,1.4.8,2.9,1.5,4.2,2.4.8.5,1.6,1,2.4,1.5,1.3.9,2.6,1.8,3.8,2.7.7.5,1.4,1,2,1.6,1.3,1.1,2.5,2.2,3.7,3.3.4.4.9.9,1.4,1.3,1.5,1.6,2.9,3.3,4.2,5,.2.3.4.7.6,1,.8,1.2,1.6,2.4,2.3,3.6,20.3-102.5-1.6-190.2-52.4-203.2-50.7-13-112,53.4-143.5,153,1.1-.7,2.4-1.4,3.6-2,.4-.2.8-.5,1.2-.7,1.9-.9,3.9-1.7,6-2.3.7-.2,1.4-.4,2.1-.6,1.5-.4,3-.8,4.6-1.1.9-.2,1.8-.3,2.7-.4,1.4-.2,2.9-.4,4.4-.5,1,0,2-.1,3-.2,1.5,0,3.1,0,4.6,0,1,0,2,0,3,0,1.7.1,3.5.3,5.2.5.9.1,1.8.2,2.7.3,2.7.4,5.4.9,8.1,1.6,20.4,5.2,36.3,17,43,30.3l-32.4,126.7c0,.1,0,.2,0,.3l-5.4,21.2h-121.7c-6.2,0-11.9-3.4-14.8-9l-26.6-50.3c-7-13.3-17.6-24.5-30.5-32.3-12.9-7.8-27.7-11.9-42.7-11.9s-4.9,1.3-6.2,3.5c-1.3,2.2-1.5,4.8-.3,7.1l68.5,135.4c1.2,2.5,3.7,4,6.5,4Z" />
        <path d="M400.4,228.9c8-5.8,19.3-9,32-9s13.6.9,20.7,2.7c19.4,5,34.7,15.8,41.9,28.3,12.6-92.4-37.7-180.5-121.9-217.3,32.8,34.9,44.4,110.5,27.3,195.3Z" />
        <path d="M146.1,144c20.7,5.3,36.7,17.3,43.2,30.9,25.8-82.6,72.2-143.3,117.7-158.2-91.6-8.2-178,44.9-211.3,132.1,12.3-7.5,31-9.7,50.4-4.7h0Z" />
      </svg>
    </>
  );
};
export default HolidaySvg;
