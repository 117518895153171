import classNames from 'classnames';
import { useAppContext } from 'providers/AppProvider';
import { Navbar } from 'react-bootstrap';
import logo from 'assets/img/icons/logo.png';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import NavbarToggleButton from './NavbarToggleButton';
import { Link } from 'react-router-dom';
import { APP_TITLE } from 'config/app.config';
import { AppDarkLogo, AppLogo } from 'components/_trackivo/common/Icons';
import { getUserHomePageRoute, mainRoutes } from 'config/routing';
import { placeParamsToURL } from 'helpers/CommonHelper';

const NavbarBrand = () => {
  const {
    config: { navbarTopShape, navbarPosition }
  } = useAppContext();
  const { breakpoints } = useBreakpoints();

  return (
    <>
      <div className="navbar-logo">
        {breakpoints.down('lg') && <NavbarToggleButton />}
        <Navbar.Brand
          as={Link}
          to={getUserHomePageRoute()}
          className={classNames({
            'me-1 me-sm-3':
              navbarTopShape === 'slim' || navbarPosition === 'horizontal'
          })}
        >
          {navbarTopShape === 'slim' ? (
            <>
              {APP_TITLE}
              {/* <span className="text-1000 d-none d-sm-inline">slim</span> */}
            </>
          ) : (
            <div className="d-flex align-items-center ">
              <img
                src={AppLogo}
                alt={APP_TITLE}
                width={200}
                className="d-dark-none"
              />
              <img
                src={AppDarkLogo}
                alt={APP_TITLE}
                width={200}
                className="d-light-none"
              />
              {/* <p className="logo-text ms-2 d-none d-sm-block">{APP_TITLE}</p> */}
            </div>
          )}
        </Navbar.Brand>
      </div>
    </>
  );
};

export default NavbarBrand;
