import SearchBox, { SearchBoxProps } from 'components/common/SearchBox';
import React, {
  Children,
  PropsWithChildren,
  ReactElement,
  cloneElement,
  useState
} from 'react';
// import SearchBox, { SearchBoxProps } from './SearchBox';
import { Dropdown } from 'react-bootstrap';

interface DropdownSearchBoxProps extends SearchBoxProps {
  className?: string;
  searchBoxClassName?: string;
  dropdownRef?: any;
}

const GlobalSearchBox = ({
  children,
  className,
  searchBoxClassName,
  dropdownRef,
  ...rest
}: PropsWithChildren<DropdownSearchBoxProps>) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  return (
    <Dropdown
      className={className}
      ref={dropdownRef ? dropdownRef : null}
      onToggle={() => setOpenDropdown(!openDropdown)}
      show={openDropdown}
    >
      <Dropdown.Toggle as="div" aria-expanded={openDropdown} bsPrefix="toggle">
        <SearchBox
          placeholder="Search..."
          className={searchBoxClassName}
          value={searchInputValue}
          onChange={({ target }) => {
            setSearchInputValue(target.value);
            setOpenDropdown(true);
          }}
          {...rest}
        />
      </Dropdown.Toggle>
      {children && searchInputValue && (
        <Dropdown.Menu
          className="mt-0 dropdown-menu border border-300 font-base start-0 py-0 overflow-hidden w-100"
          style={{ width: 400 }}
        >
          {Children.map(children, child =>
            cloneElement(child as ReactElement, {
              searchValue: searchInputValue,
              setSearchInputValue: setSearchInputValue
            })
          )}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default GlobalSearchBox;
