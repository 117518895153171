import React from 'react';

const InterviewSvg = ({
  height = 21,
  width = 21,
  viewBox = '0 0 512 512'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <defs>
        <style>
          {`

      .cls-1, .cls-2 {
        stroke-width: 0px;
      }`}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <circle
          fill="currentColor"
          className="cls-1"
          cx="144"
          cy="272"
          r="64"
        />
        <path
          fill="currentColor"
          className="cls-1"
          d="M192,352h-96c-35.3,0-64,28.7-64,64v48c0,8.8,7.2,16,16,16h192c8.8,0,16-7.2,16-16v-48c0-35.3-28.7-64-64-64Z"
        />
        <path
          fill="currentColor"
          className="cls-2"
          d="M432,32h-192c-26.5,0-48,21.5-48,48v96c0,26.5,21.5,48,48,48h48v32c0,6,3.4,11.5,8.8,14.2,5.4,2.7,11.9,2.2,16.8-1.4l59.7-44.8h58.7c26.5,0,48-21.5,48-48v-96c0-26.5-21.5-48-48-48h0ZM272,144c-8.8,0-16-7.2-16-16s7.2-16,16-16,16,7.2,16,16-7.2,16-16,16ZM336,144c-8.8,0-16-7.2-16-16s7.2-16,16-16,16,7.2,16,16-7.2,16-16,16ZM400,144c-8.8,0-16-7.2-16-16s7.2-16,16-16,16,7.2,16,16-7.2,16-16,16Z"
        />
        <circle
          fill="currentColor"
          className="cls-1"
          cx="400"
          cy="320"
          r="48"
        />
        <path
          fill="currentColor"
          className="cls-1"
          d="M432,384h-64c-26.5,0-48,21.5-48,48v32c0,8.8,7.2,16,16,16h128c8.8,0,16-7.2,16-16v-32c0-26.5-21.5-48-48-48Z"
        />
      </g>
    </svg>
  );
};
export default InterviewSvg;
