const endpoints = {
  // Global Search
  globalSearch: { url: '/global-search', method: 'POST' },
  // ATS Dashboard
  atsDashboardCounter: { url: '/ats-dashboard-counter', method: 'POST' },
  atsDashboardHiringPipeline: {
    url: '/get-hiring-pipeline-count',
    method: 'POST'
  },
  atsDashboardApplicationChart: {
    url: '/ats-dashboard-application',
    method: 'POST'
  },
  atsDashboardCandidateStages: {
    url: '/ats-dashboard-candidate-stages',
    method: 'POST'
  },
  atsDashboardRateMeter: {
    url: '/ats-dashboard-rate-meter',
    method: 'POST'
  },
  // setting API
  testGetAPI: { url: '/', method: 'GET' },
  testPostAPI: { url: '/', method: 'POST' },
  settings: { url: '/settings', method: 'GET' },
  // login and profile module
  login: { url: '/login', method: 'POST' },
  generateMFA: { url: '/generate-mfa', method: 'GET' },
  enableMFA: { url: '/enable-mfa', method: 'POST' },
  removeMFA: { url: '/remove-mfa', method: 'GET' },
  forgotPassword: { url: '/forget-password', method: 'POST' },
  createPassword: { url: '/create-password', method: 'POST' },
  resetPassword: { url: '/reset-password', method: 'POST' },
  // verifyOTP: { url: '/verify-otp', method: 'POST' },
  verifyOTP: { url: '/verify-mfa', method: 'POST' },
  resendOTP: { url: '/resend-otp', method: 'POST' },
  logout: { url: '/logout', method: 'POST' },
  refreshToken: { url: '/refresh-token', method: 'POST' },
  formFieldList: { url: '/field-master', method: 'POST' },
  listFieldList: { url: '/list-master', method: 'POST' },
  // location
  countryList: { url: '/get-country', method: 'POST' },
  stateList: { url: '/get-state', method: 'POST' },
  cityList: { url: '/get-city', method: 'POST' },
  pincodeInfo: { url: '/fetch-pincode-info', method: 'POST' },
  currencyList: { url: '/get-currency', method: 'POST' },
  // company
  companyList: { url: '/get-company', method: 'POST' },
  addCompany: { url: '/company', method: 'POST' },
  getCompanyInfo: { url: '/get-company-info', method: 'POST' },
  editCompany: { url: '/company', method: 'PATCH' },
  industryList: { url: '/get-industry', method: 'POST' },
  // branch
  branchList: { url: '/get-branch', method: 'POST' },
  addBranch: { url: '/branch', method: 'POST' },
  getBranchInfo: { url: '/get-branch-info', method: 'POST' },
  editBranch: { url: '/branch', method: 'PATCH' },
  //leave type
  leaveTypeList: { url: '/get-leave-type', method: 'POST' },
  addLeaveType: { url: '/leave-type', method: 'POST' },
  getLeaveTypeInfo: { url: '/get-leave-type-info', method: 'POST' },
  editLeaveType: { url: '/leave-type', method: 'PATCH' },
  // leave tracker
  leaveList: { url: '/get-leave', method: 'POST' },
  myLeaveList: { url: '/my-leave', method: 'POST' },
  addLeave: { url: '/apply-for-leave', method: 'POST' },
  getLeaveInfo: { url: '/get-leave-info', method: 'POST' },
  getLeaveBalanceInfo: { url: '/leave-counter', method: 'POST' },
  editLeave: { url: '/leave-request', method: 'PATCH' },
  employeeAllowedLeaveTypeList: {
    url: '/get-allowed-leave-type',
    method: 'POST'
  },

  getLeaveRequestInfoTimeline: {
    url: '/get-leave-request-info',
    method: 'POST'
  },
  //pay type
  payTypeList: { url: '/get-pay-type', method: 'POST' },
  addPayType: { url: '/pay-type', method: 'POST' },
  getPayTypeInfo: { url: '/get-pay-type-info', method: 'POST' },
  editPayType: { url: '/pay-type', method: 'PATCH' },
  //job title
  jobTitleList: { url: '/get-job-title', method: 'POST' },
  addJobTitle: { url: '/job-title', method: 'POST' },
  getJobTitleInfo: { url: '/get-job-title-info', method: 'POST' },
  editJobTitle: { url: '/job-title', method: 'PATCH' },
  // visa type
  visaTypeList: { url: '/get-visa-type', method: 'POST' },
  addVisaType: { url: '/visa-type', method: 'POST' },
  getVisaTypeInfo: { url: '/get-visa-type-info', method: 'POST' },
  editVisaType: { url: '/visa-type', method: 'PATCH' },
  // service category
  serviceCategoryList: { url: '/get-service-category', method: 'POST' },
  addServiceCategory: { url: '/service-category', method: 'POST' },
  getServiceCategoryInfo: { url: '/get-service-category-info', method: 'POST' },
  editServiceCategory: { url: '/service-category', method: 'PATCH' },
  // service
  companyServiceList: { url: '/get-company-service', method: 'POST' },
  addCompanyService: { url: '/company-service', method: 'POST' },
  getCompanyServiceInfo: { url: '/get-company-service-info', method: 'POST' },
  editCompanyService: { url: '/company-service', method: 'PATCH' },
  // lead stage
  leadStageList: { url: '/get-lead-stages', method: 'POST' },
  addLeadStage: { url: '/lead-stages', method: 'POST' },
  getLeadStageInfo: { url: '/get-lead-stages-info', method: 'POST' },
  editLeadStage: { url: '/lead-stages', method: 'PATCH' },
  updateLeadStageOrder: { url: '/update-lead-stages-order', method: 'POST' },
  // Account
  accountList: { url: '/get-account', method: 'POST' },
  addAccount: { url: '/account', method: 'POST' },
  editAccount: { url: '/account', method: 'PATCH' },
  getAccountInfo: { url: '/get-account-info', method: 'POST' },
  accountTypeList: { url: '/get-account-type', method: 'POST' },
  // Lead
  leadList: { url: '/get-lead', method: 'POST' },
  addLead: { url: '/lead', method: 'POST' },
  leadCardParser: { url: '/lead-parser', method: 'POSTFORMDATA' },
  editLead: { url: '/lead', method: 'PATCH' },
  getLeadInfo: { url: '/get-lead-info', method: 'POST' },
  getLeadDetail: { url: '/get-lead-detail', method: 'POST' },
  getLeadTimeline: { url: '/get-lead-timeline', method: 'POST' },
  getLeadStageCount: {
    url: '/get-lead-stages-count',
    method: 'POST'
  },
  leadDevelopmentList: { url: '/get-development', method: 'POST' },
  updateLeadDevelopmentStage: { url: '/change-lead-status', method: 'PATCH' },
  // document type
  documentTypeList: { url: '/get-document-type', method: 'POST' },
  addDocumentType: { url: '/document-type', method: 'POST' },
  getDocumentTypeInfo: { url: '/get-document-type-info', method: 'POST' },
  editDocumentType: { url: '/document-type', method: 'PATCH' },
  moduleList: { url: '/get-module', method: 'GET' },
  //skill
  skillList: { url: '/get-skill', method: 'POST' },
  addSkill: { url: '/skill', method: 'POST' },
  getSkillInfo: { url: '/get-skill-info', method: 'POST' },
  editSkill: { url: '/skill', method: 'PATCH' },
  // employee
  employeeList: { url: '/get-employee', method: 'POST' },
  addEmployee: { url: '/employee', method: 'POST' },
  getEmployeeInfo: { url: '/get-employee-info', method: 'POST' },
  editEmployee: { url: '/employee', method: 'PATCH' },
  adjustEmployeeLeaveBalance: {
    url: '/adjust-leave-balance',
    method: 'POST'
  },
  //client
  clientList: { url: '/get-client', method: 'POST' },
  addClient: { url: '/client', method: 'POST' },
  getClientInfo: { url: '/get-client-info', method: 'POST' },
  editClient: { url: '/client', method: 'PATCH' },
  // reference
  referenceList: { url: '/get-reference', method: 'POST' },
  addReference: { url: '/reference', method: 'POST' },
  getReferenceInfo: { url: '/get-reference-info', method: 'POST' },
  editReference: { url: '/reference', method: 'PATCH' },
  updateReferenceStatus: { url: '/change-reference-status', method: 'PATCH' },
  // candidate
  candidateList: { url: '/get-candidate', method: 'POST' },
  getCandidateStatusCount: {
    url: '/get-candidate-stages-count',
    method: 'GET'
  },
  getCandidateStatusPipeline: {
    url: '/get-candidate-stages',
    method: 'GET'
  },
  updateCandidateStatusPipeline: {
    url: '/update-candidate-stages',
    method: 'POST'
  },
  addCandidate: { url: '/candidate', method: 'POST' },
  // for edit page
  getCandidateInfo: { url: '/get-candidate-info', method: 'POST' },
  // for detail page
  getCandidateDetail: { url: '/get-candidate-detail', method: 'POST' },
  editCandidate: { url: '/candidate', method: 'PATCH' },
  importCandidate: { url: '/import-candidate', method: 'POST' },
  // candidate note
  candidateNoteList: { url: '/get-notes', method: 'POST' },
  addCandidateNote: { url: '/note', method: 'POST' },
  editCandidateNote: { url: '/note', method: 'PATCH' },
  getCandidateTimeline: { url: '/get-candidate-timeline', method: 'POST' },
  // update status of candidate
  updateCandidateStatus: { url: '/change-candidate-status', method: 'PATCH' },
  submitCandidateToClient: {
    url: '/candidate-submission-for-job-opening',
    method: 'POST'
  },
  submitCandidateList: {
    url: '/get-candidate-submission',
    method: 'POST'
  },
  getSubmitCandidateTimelineList: {
    url: '/get-candidate-submission-timeline',
    method: 'POST'
  },
  updateSubmitCandidateStatus: {
    url: '/change-candidate-submission-status',
    method: 'PATCH'
  },
  unassociateSubmittedClient: {
    url: '/unassociate-candidate-submission',
    method: 'DELETE'
  },
  // search candidate filter
  saveSourceBoosterSearchFilter: {
    url: '/source-booster-save-search',
    method: 'POST'
  },
  getSourceBoosterSearchFilterList: {
    url: '/get-source-booster-save-search',
    method: 'POST'
  },
  deleteSourceBoosterSearchFilter: {
    url: '/source-booster-save-search',
    method: 'DELETE'
  },
  // candidate interaction
  getCandidateFullInteractionHistory: {
    url: '/get-full-interaction-history',
    method: 'POST'
  },
  getCandidateInteractionHistory: {
    url: '/get-interaction-history',
    method: 'POST'
  },
  addCandidateSourceBoosterInteraction: {
    url: '/source-booster-interaction',
    method: 'POST'
  },
  removeBoomarkInteraction: {
    url: '/remove-bookmark-interaction',
    method: 'POST'
  },
  //........................
  // calendar
  myCalendar: {
    url: '/my-calendar',
    method: 'POST'
  },
  myCalendarList: {
    url: '/my-calendar-list',
    method: 'POST'
  },
  addCalendarTask: {
    url: '/task',
    method: 'POST'
  },
  getCalendarTaskInfo: {
    url: '/get-task-info',
    method: 'POST'
  },
  editCalendarTask: {
    url: '/task',
    method: 'PATCH'
  },
  deleteCalendarTask: {
    url: '/task',
    method: 'DELETE'
  },

  // source Boosters
  diceSearchCandidate: {
    url: 'https://talent-api.dice.com/v2/profiles/search',
    method: 'POST'
  },
  diceCandidateDetail: {
    url: 'https://talent-api.dice.com/v2/profiles/:id',
    method: 'GET'
  },
  generateSourceBoosterToken: {
    url: '/generate-source-booster-token',
    method: 'POST'
  },
  getConfiguredSourceBoosterList: {
    url: '/get-source-booster',
    method: 'POST'
  },
  configureSourceBooster: {
    url: '/source-booster',
    method: 'POST'
  },
  editSourceBooster: {
    url: '/source-booster',
    method: 'PATCH'
  },
  deleteSourceBooster: {
    url: '/source-booster',
    method: 'DELETE'
  },
  // job opening status
  jobOpeningAllStatusList: { url: '/get-job-opening-stages', method: 'GET' },
  updatejobOpeningStatusOrder: {
    url: '/update-job-opening-stages',
    method: 'POST'
  },
  jobOpeningStatusList: { url: '/get-job-opening-status', method: 'POST' },
  addJobOpeningStatus: { url: '/job-opening-status', method: 'POST' },
  editJobOpeningStatus: { url: '/job-opening-status', method: 'PATCH' },
  getJobOpeningStatusInfo: {
    url: '/get-job-opening-status-info',
    method: 'POST'
  },
  //job opening attachment
  jobOpeningAttachment: { url: '/get-job-opening-attachment', method: 'POST' },
  addJobOpeningAttachment: { url: '/job-opening-attachment', method: 'POST' },
  updateJobOpeningAttachment: {
    url: '/job-opening-attachment',
    method: 'PATCH'
  },
  deleteJobOpeningAttachment: {
    url: '/job-opening-attachment',
    method: 'DELETE'
  },
  // leave ........
  // leave status
  leaveStatusList: { url: '/get-leave-status', method: 'POST' },
  addLeaveStatus: { url: '/leave-status', method: 'POST' },
  editLeaveStatus: { url: '/leave-status', method: 'PATCH' },
  updateLeaveStatus: { url: '/change-leave-status', method: 'PATCH' },
  getLeaveStatusInfo: {
    url: '/get-leave-status-info',
    method: 'POST'
  },
  // interview status
  interviewStatusList: { url: '/get-interview-status', method: 'POST' },
  addInterviewStatus: { url: '/interview-status', method: 'POST' },
  editInterviewStatus: { url: '/interview-status', method: 'PATCH' },
  getInterviewStatusInfo: {
    url: '/get-interview-status-info',
    method: 'POST'
  },
  getInterviewStatusPipeline: {
    url: '/get-hiring-pipeline',
    method: 'GET'
  },
  updateInterviewStatusPipeline: {
    url: '/update-hiring-pipeline',
    method: 'POST'
  },
  // job opening attachment category status
  jobOpeningAttachmentCategoryList: {
    url: '/get-job-opening-attachment-category',
    method: 'POST'
  },
  addJobOpeningAttachmentCategory: {
    url: '/job-opening-attachment-category',
    method: 'POST'
  },
  editJobOpeningAttachmentCategory: {
    url: '/job-opening-attachment-category',
    method: 'PATCH'
  },
  getJobOpeningAttachmentCategoryInfo: {
    url: '/get-job-opening-attachment-category-info',
    method: 'POST'
  },
  // todo log list
  jobOpeningCallLogList: {
    url: '/get-log',
    method: 'POST'
  },
  addJobOpeningCallLog: {
    url: '/log',
    method: 'POST'
  },
  editJobOpeningCallLog: {
    url: '/log',
    method: 'PATCH'
  },
  getJobOpeningCallLogInfo: {
    url: '/get-log-info',
    method: 'POST'
  },
  deleteJobOpeningCallLog: {
    url: '/log',
    method: 'DELETE'
  },
  // job opening.........
  generateJobOpeningContentAI: { url: '/generate-job-opening', method: 'POST' },
  jobOpeningList: { url: '/get-job-opening', method: 'POST' },
  addJobOpening: { url: '/job-opening', method: 'POST' },
  editJobOpening: { url: '/job-opening', method: 'PATCH' },
  // for edit page
  getJobOpeningInfo: {
    url: '/get-job-opening-info',
    method: 'POST'
  },
  getJobOpeningStageCount: {
    url: '/get-job-opening-stages-count',
    method: 'GET'
  },
  // for detail page
  getJobOpeningDetail: {
    url: '/get-job-opening-detail',
    method: 'POST'
  },
  getJobOpeningTimelineList: {
    url: '/get-job-opening-timeline',
    method: 'POST'
  },
  getJobOpeningDetailCount: {
    url: '/get-job-opening-detail-count',
    method: 'POST'
  },
  updateJobOpeningStatus: {
    url: '/change-job-opening-status',
    method: 'PATCH'
  },
  // ats workflow
  getAtsWorkflow: { url: '/workflow', method: 'GET' },
  updateAtsWorkflow: { url: '/workflow', method: 'PATCH' },
  // todo logs
  callLogList: { url: '/get-log', method: 'POST' },
  addCallLog: { url: '/log', method: 'POST' },
  getCallLogInfo: { url: '/get-log-info', method: 'POST' },
  editCallLog: { url: '/log', method: 'POST' },
  //candidate status
  candidateStatusList: { url: '/get-candidate-status', method: 'POST' },
  addCandidateStatus: { url: '/candidate-status', method: 'POST' },
  getCandidateStatusInfo: { url: '/get-candidate-status-info', method: 'POST' },
  editCandidateStatus: { url: '/candidate-status', method: 'PATCH' },
  candidateCVParser: { url: '/cv-parser', method: 'POSTFORMDATA' },
  // interview
  interviewList: { url: '/get-interview', method: 'POST' },
  addInterview: { url: '/interview', method: 'POST' },
  getInterviewInfo: { url: '/get-interview-info', method: 'POST' },
  editInterview: { url: '/interview', method: 'PATCH' },
  submitInterviewEvaluation: {
    url: '/submit-interview-evaluation',
    method: 'POST'
  },
  getInterviewTimelineList: {
    url: '/get-interview-timeline',
    method: 'POST'
  },
  //shift
  shiftList: { url: '/get-shift', method: 'POST' },
  addShift: { url: '/shift', method: 'POST' },
  getShiftInfo: { url: '/get-shift-info', method: 'POST' },
  editShift: { url: '/shift', method: 'PATCH' },
  //interviwe level
  interviewLevelList: { url: '/get-interview-level', method: 'POST' },
  getInterviewLevelPipeline: {
    url: '/get-interview-level-stages',
    method: 'GET'
  },
  updateInterviewLevelPipeline: {
    url: '/update-interview-level-stages',
    method: 'POST'
  },
  addInterviewLevel: { url: '/interview-level', method: 'POST' },
  getInterviewLevelInfo: { url: '/get-interview-level-info', method: 'POST' },
  editInterviewLevel: { url: '/interview-level', method: 'PATCH' },
  //employee type
  employeeTypeList: { url: '/get-employee-type', method: 'POST' },
  addEmployeeType: { url: '/employee-type', method: 'POST' },
  getEmployeeTypeInfo: { url: '/get-employee-type-info', method: 'POST' },
  editEmployeeType: { url: '/employee-type', method: 'PATCH' },
  // role
  serviceList: { url: '/get-service', method: 'POST' },
  roleList: { url: '/get-role', method: 'POST' },
  addRole: { url: '/role', method: 'POST' },
  getRoleInfo: { url: '/get-role-info', method: 'POST' },
  editRole: { url: '/role', method: 'PATCH' },
  getRolePermission: { url: '/get-company-access', method: 'POST' },
  switchRole: { url: '/role-selector', method: 'POST' },
  // group
  groupList: { url: '/get-group', method: 'POST' },
  addGroup: { url: '/group', method: 'POST' },
  getGroupInfo: { url: '/get-group-info', method: 'POST' },
  editGroup: { url: '/group', method: 'PATCH' },
  // department
  departmentList: { url: '/get-department', method: 'POST' },
  addDepartment: { url: '/department', method: 'POST' },
  getDepartmentInfo: { url: '/get-department-info', method: 'POST' },
  editDepartment: { url: '/department', method: 'PATCH' },
  // profile
  getProfile: { url: '/get-profile', method: 'GET' },
  updateProfile: { url: '/update-profile', method: 'POST' },
  // timezone
  timezoneList: { url: '/get-timezone', method: 'POST' },
  // user
  userList: { url: '/get-user', method: 'POST' },
  // country code for mobile code field
  mobileCodeList: { url: '/get-mobile-code', method: 'GET' },
  // Holiday
  holidayList: { url: '/get-holiday', method: 'POST' },
  addHoliday: { url: '/holiday', method: 'POST' },
  getHolidayInfo: { url: '/get-holiday-info', method: 'POST' },
  editHoliday: { url: '/holiday', method: 'PATCH' },
  deleteHoliday: { url: '/holiday', method: 'DELETE' },
  // Attendance
  attendanceStatus: { url: '/attendance-status', method: 'GET' },
  attendanceCheckInCheckOut: { url: '/attendance', method: 'POST' },
  attendanceGraph: { url: '/attendance-graph', method: 'POST' },
  attendanceReport: { url: '/attendance-report', method: 'POST' }
};
export default endpoints;
