import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import SettingReducer from './SettingReducer';
import CandidateReducer from './CandidateReducer';
import LeadReducer from './LeadReducer';

const reducers = combineReducers({
  auth: AuthReducer,
  setting: SettingReducer,
  candidateModule: CandidateReducer,
  leadModule: LeadReducer
});

export default reducers;
