import React from 'react';

const EmployeeMenuSvg = ({
  height = 21,
  width = 21,
  viewBox = '-80 0 496 496'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <>
      <svg
        id="OBJECT"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        fill="currentColor"
      >
        <path d="m318.398438 286.398438-88.796876-37.597657c-1.601562-.800781-4-.800781-5.601562 0s-3.199219 2.398438-3.199219 4l-52 210.398438-52.800781-210.398438c-.800781-1.601562-1.601562-3.199219-3.199219-4-1.601562-.800781-3.199219-.800781-5.601562 0l-88 33.597657c-12 4.800781-19.199219 14.402343-19.199219 25.601562v181.601562c0 3.199219 3.199219 6.398438 6.398438 6.398438h159.203124 4 159.199219c3.199219 0 6.398438-2.398438 6.398438-6.398438v-177.601562c1.601562-10.398438-5.597657-20.800781-16.800781-25.601562zm0 0" />
        <path d="m196.800781 267.199219c4-6.398438 3.199219-15.199219-2.402343-20.800781-3.199219-4-8-5.597657-13.597657-5.597657h-24c-4.800781 0-10.402343 2.398438-13.601562 5.597657-4.800781 5.601562-6.398438 13.601562-2.398438 20.800781l6.398438 9.601562c3.199219 5.597657 4.800781 11.199219 4 17.597657l-4 41.601562 12 32.800781c1.601562 4 4.800781 6.398438 9.601562 6.398438s8-2.398438 9.597657-6.398438l12-32.800781-4.796876-40.800781c-.800781-6.398438.796876-12.800781 4-17.597657zm0 0" />
        <path d="m168.800781 222.398438c52.800781 0 96-43.199219 96-96v-30.398438c0-52.800781-43.199219-96-96-96s-96 43.199219-96 96v30.398438c0 52.800781 43.199219 96 96 96zm0 0" />
      </svg>
    </>
  );
};
export default EmployeeMenuSvg;
