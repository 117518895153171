import React from 'react';

const LeaveApplicationSvg = ({
  height = 21,
  width = 21,
  viewBox = '0 0 682.7 682.7'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <>
      <svg
        id="OBJECT"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        fill="currentColor"
      >
        <defs>
          <style>
            {`.cls-leave-1 {
        fill: currentColor;
      }

      .cls-leave-1, .cls-leave-2 {
        stroke-width: 0px;
      }

      .cls-leave-2, .cls-leave-3, .cls-leave-4 {
        fill: none;
      }

      .cls-leave-3 {
        stroke: currentColor;
      }

      .cls-leave-3, .cls-leave-4 {
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 20px;
      }

      .cls-leave-4 {
        stroke: #fff;
      }

      .cls-leave-5 {
        clip-path: url(#clippath);
      }`}
          </style>
          <clipPath id="clippath">
            <rect
              className="cls-leave-2"
              x="6"
              y="0"
              width="682.7"
              height="682.7"
            />
          </clipPath>
        </defs>
        <g className="cls-leave-5">
          <g id="g1998">
            <path
              id="path2000"
              className="cls-leave-1"
              d="M123.9,552.2h516c18.6,0,33.7-15.1,33.7-33.6v-145.8c0-18.6-15.1-33.7-33.7-33.7H202.5c-18.6,0-33.6,15.1-33.6,33.7v134.6l-44.9,44.9Z"
            />
          </g>
          <g>
            <g id="g1966">
              <path
                id="path1968"
                className="cls-leave-4"
                d="M377.6,494.5l39.6-103.8c.8-2,3.6-2,4.4,0l39.2,103.8"
              />
            </g>
            <g id="g1970">
              <path
                id="path1972"
                className="cls-leave-4"
                d="M389.9,468.7h58.7"
              />
            </g>
            <g id="g1974">
              <path
                id="path1976"
                className="cls-leave-4"
                d="M207.2,389.2v105.2c1.2.2,36.9,0,36.9,0"
              />
            </g>
            <g id="g1978">
              <path
                id="path1980"
                className="cls-leave-4"
                d="M643.3,389.7h-44v104.4h44"
              />
            </g>
            <g id="g1982">
              <path
                id="path1984"
                className="cls-leave-4"
                d="M640.1,441.9h-40.7"
              />
            </g>
            <g id="g1986">
              <path
                id="path1988"
                className="cls-leave-4"
                d="M333.4,389.7h-44v104.4h44"
              />
            </g>
            <g id="g1990">
              <path
                id="path1992"
                className="cls-leave-4"
                d="M330.1,441.9h-40.7"
              />
            </g>
            <g id="g1994">
              <path
                id="path1996"
                className="cls-leave-4"
                d="M479.8,389.2l36,103.7c.8,2.1,3.8,2.1,4.5,0l34.4-103.5"
              />
            </g>
          </g>
          <g id="g2002">
            <path
              id="path2004"
              className="cls-leave-3"
              d="M283.3,544.4s7,43.5-30.8,42.8H58.8c-23.6,0-42.8-19.1-42.8-42.8V138.3c0-23.6,19.1-42.8,42.8-42.8h183.8c23.6,0,42.8,19.1,42.8,42.8v160.3"
            />
          </g>
          <g id="g2006">
            <path
              id="path2008"
              className="cls-leave-1"
              d="M145.6,213.8h0c-17.7,0-32.1-14.4-32.1-32.1v-21.4c0-5.9,4.8-10.7,10.7-10.7h42.8c5.9,0,10.7,4.8,10.7,10.7v21.4c0,17.7-14.4,32.1-32.1,32.1Z"
            />
          </g>
          <g id="g2010">
            <path
              id="path2012"
              className="cls-leave-3"
              d="M209.8,331.3v-32.1c0-29.5-23.9-53.4-53.4-53.4h-21.4c-29.5,0-53.4,23.9-53.4,53.4v42.8"
            />
          </g>
          <g id="g2014">
            <path id="path2016" className="cls-leave-3" d="M16,352h138.9" />
          </g>
          <g id="g2018">
            <path id="path2020" className="cls-leave-3" d="M59.4,426.8h85.5" />
          </g>
          <g id="g2022">
            <path id="path2024" className="cls-leave-3" d="M59.4,469.6h85.5" />
          </g>
          <g id="g2026">
            <path id="path2028" className="cls-leave-3" d="M59.4,512.3h53.4" />
          </g>
        </g>
      </svg>
    </>
  );
};
export default LeaveApplicationSvg;
