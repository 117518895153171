import React from 'react';

const SearchCandidateSvg = ({
  height = 21,
  width = 21,
  viewBox = '0 0 512 512'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <defs>
        <style>
          {`
        .cls-1, .cls-2 {
          stroke-width: 0px;
        }
        `}
        </style>
      </defs>
      <g id="Layer_9" data-name="Layer 9">
        <path
          className="cls-1"
          fill="currentColor"
          d="M505.6,429.3l-.9-1.2c-18.8-21.7-41.8-37.6-64.1-53-9.1-6.3-25.3-16.4-36.7-24.5,26.5-37.5,40.8-82.3,40.7-128.3C444.6,99.5,345.1,0,222.3,0S0,99.5,0,222.3s99.5,222.3,222.3,222.3c45.9,0,90.6-14.1,128.1-40.6,8.1,11.4,18.3,27.6,24.5,36.7,15.4,22.3,31.3,45.3,53,64.1l1.2.9c6.9,4.2,14.9,6.4,23,6.4,12.3,0,24.1-4.6,33.1-12.9l13.4-13.4.3-.3c14.3-15.6,17-39.2,6.5-56.1h0ZM222.3,386.6c-90.8,0-164.3-73.6-164.3-164.3,0-90.8,73.6-164.3,164.3-164.3,90.8,0,164.3,73.6,164.3,164.3,0,43.6-17.3,85.4-48.1,116.2-30.7,30.9-72.6,48.2-116.2,48.1Z"
        />
        <circle
          className="cls-2"
          fill="currentColor"
          cx="222.3"
          cy="177.6"
          r="65.2"
        />
        <path
          className="cls-2"
          fill="currentColor"
          d="M329.8,296.6c-1-9.2-5.1-17.7-11.6-24.2-6.6-6.6-15.1-10.7-24.4-11.7-13.5-1.4-47-2.2-67.9-2.2s-61.9.8-75.4,2.2c-9.2,1-17.8,5.2-24.4,11.7-6.5,6.5-10.6,15-11.6,24.2v.3c-.3,3.1-.5,5.8-.6,8.1,3.7,4.8,7.7,9.4,12,13.7,53.2,53.2,139.5,53.2,192.8,0,4.2-4.2,8.2-8.7,11.8-13.5-.1-2.4-.4-5.1-.7-8.3v-.3Z"
        />
      </g>
    </svg>
  );
};
export default SearchCandidateSvg;
