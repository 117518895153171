import http from './sourceBoosterAxiosConfig';
import { errorMessage } from 'helpers/ToastNotificationHelper';
import { getUserAuthToken } from 'helpers/StorageHelper';
import qs from 'qs';
import axios, { AxiosError } from 'axios';
class SourceBoosterAPIService {
  addHeaders(token: string): void {
    http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }

  async submitData(url: string, data: object, token = ''): Promise<any> {
    try {
      if (token) {
        this.addHeaders(token);
      }
      // const config = {
      //   method: 'post',
      //   maxBodyLength: Infinity,
      //   url: url,
      //   // headers: {
      //   //   Authorization:
      //   //     'Basic U2lsdmVyeGlzOmNmYzI1ZWYzLWE0ZjYtNDQ4YS05YmVjLTU2OThjNDBhZWE2NA==',
      //   //   'Content-Type': 'application/json'
      //   // },
      //   data: data
      // };
      const res: any = await http.post(url, data);
      console.log(res);
      if (res || (res.status && res.status == 200)) {
        if (res.data) {
          return res.data;
        } else {
          return res;
        }
      } else {
        return {
          status: false,
          hasError: true,
          message: res.message || 'Something went wrong!'
        };
      }
    } catch (err: any) {
      console.log(err);
      if (err?.response?.status !== 401) {
        return {
          status: false,
          hasError: true,
          message: 'Something went wrong!'
        };
      }
    }
  }

  async getData(url: string, data: object, token = ''): Promise<any> {
    try {
      if (token) {
        this.addHeaders(token);
      }
      const res: any = await http.get(url, data);
      if (res || (res.status && res.status == 200)) {
        if (res.data) {
          return res.data;
        } else {
          return res;
        }
      } else {
        return {
          status: false,
          hasError: true,
          message: res.message || 'Something went wrong!'
        };
      }
    } catch (err: any) {
      console.log(err);
      if (err?.response?.status !== 401) {
        return {
          status: false,
          hasError: true,
          message: 'Something went wrong!'
        };
      }
    }
  }

  async getPublicTokenData(
    url: string,
    data: object,
    token = ''
  ): Promise<any> {
    try {
      if (token) {
        this.addHeaders(token);
      }

      const res = await http.get(url, data);
      return res.data;
    } catch (err: any) {
      console.log(err);
      if (err?.response?.status !== 401) {
        return {
          status: false,
          message: 'Something went wrong!'
        };
      }
    }
  }

  async submitPublicTokenData(
    url: string,
    data: object,
    token = ''
  ): Promise<any> {
    try {
      if (token) {
        this.addHeaders(token);
      }

      const res = await http.post(url, data);
      return res.data;
    } catch (err: any) {
      console.log(err);
      if (err?.response?.status !== 401) {
        return {
          status: false,
          message: 'Something went wrong!'
        };
      }
    }
  }
}

export default new SourceBoosterAPIService();
