export const pluck = (array: [], key: any) => {
  const validArray = filterNonEmptyItems(array);
  if (validArray && validArray.length > 0) {
    return validArray.map((item: any) => item[key]);
  }
  return [];
};
export const arrayUnique = (array: any) => {
  const uniqueArray = [...new Set(array)];
  return uniqueArray;
};
export const findItemById = (array: [], id: any) => {
  return array.find((item: any) => item.id == id);
};
export const findItemBySlug = (array: [], slug: any, key: string = 'slug') => {
  return array.find((item: any) => item[key] == slug);
};
export const filterItems = (array: any, column: string, value: any) => {
  return array.filter((item: any) => item[column] == value);
};
export const filterNonEmptyItems = (array: any) => {
  return array.filter((value: any) => {
    if (value) {
      return value;
    }
  });
};
export const isDifferentJSON = (json1: any, json2: any) => {
  // Get keys from both JSON objects
  const keys1 = Object.keys(json1);
  const keys2 = Object.keys(json2);

  // If the number of keys is different, the objects are not equal
  if (keys1.length !== keys2.length) {
    return true;
  }

  // Iterate over keys and compare values
  for (const key of keys1) {
    // If the keys don't match, objects are not equal
    if (!keys2.includes(key)) {
      return true;
    }

    // If the values for the keys don't match, objects are not equal
    if (JSON.stringify(json1[key]) !== JSON.stringify(json2[key])) {
      return true;
    }
  }

  // If all checks pass, objects are equal
  return false;
};
export const convertToGroupedObject = (arr: [], groupKey: string | '') => {
  const groupedObj: any = {};

  arr.forEach((item: any) => {
    if (item[groupKey]) {
      const itemType = item[groupKey].toLowerCase();
      if (!groupedObj[itemType]) {
        groupedObj[itemType] = [];
      }
      groupedObj[itemType].push(item);
    }
  });

  return groupedObj;
};
