import {
  encryptObject,
  decryptObject,
  generateRandomNumber
} from './CommonHelper';
import store from 'store';
import { getCurrentDateTime } from './DateHelper';
import { SUPER_ADMIN_ROLE_SLUG } from 'config/app.config';
import { pluck } from './ArrayHelper';

export const getDeviceId = (): string => {
  let deviceId = getLocalStorage('deviceId');
  if (deviceId) {
    return deviceId;
  } else {
    deviceId = generateRandomNumber().toString();
    storeLocalStorage('deviceId', deviceId);
    return deviceId;
  }
};

export const getSourceBoosterToken = () => {
  let sourceBoosterSettingInfo: any = localStorage.getItem(
    'sourceBoosterSettingInfo'
  );
  if (sourceBoosterSettingInfo) {
    sourceBoosterSettingInfo = decryptObject(sourceBoosterSettingInfo);
  }
  return sourceBoosterSettingInfo;
};
export const storeSourceBoosterToken = (obj: object) => {
  const existingObject = getSourceBoosterToken();
  // obj = { ...obj, ...{ last_updated_at: getCurrentDateTime() } };
  console.log({ ...existingObject, ...obj });

  const encryptedObj = encryptObject({ ...existingObject, ...obj });
  storeLocalStorage('sourceBoosterSettingInfo', encryptedObj);
};
export const storeLocalStorage = (key: string, value: string): void => {
  localStorage.setItem(key, value);
};

export const getLocalStorage = (key: string): string | null => {
  return localStorage.getItem(key);
};

export const getUserInfo = (): any => {
  const userInfo = localStorage.getItem('userInfo');
  if (userInfo) {
    return decryptObject(userInfo);
  }
  return null;
};

export const storeUserInfo = (obj: any): void => {
  const encryptedObj = encryptObject(obj);
  storeLocalStorage('userInfo', encryptedObj);
};

export const getUserAuthToken = (): string => {
  const userInfo = getUserInfo();
  let authorization = '';
  if (userInfo) {
    authorization = userInfo.token;
  }
  return authorization;
};

// user info
export const storeUserStoreInfo = (obj: any): void => {
  const encryptedObj = encryptObject(obj);
  store.dispatch({
    type: 'UPDATE_CURRENT_STORE',
    payload: obj
  });
  storeLocalStorage('currentStore', encryptedObj);
};
export const getUserStoreInfo = (): any => {
  const storeInfo = getLocalStorage('currentStore');
  if (storeInfo) {
    return decryptObject(storeInfo);
  }
  return null;
};

export const removeLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};

export const removeUserData = (): void => {
  removeLocalStorage('userInfo');
  removeLocalStorage('deviceId');
};
// app settings
export const getAppSettingInfo = () => {
  const settingInfo = localStorage.getItem('settingInfo');
  if (settingInfo) {
    return decryptObject(settingInfo);
  }
  return null;
};
export const storeAppSettingInfo = (obj: any) => {
  obj = { ...obj, ...{ last_updated_at: getCurrentDateTime() } };
  const encryptedObj = encryptObject(obj);
  storeLocalStorage('settingInfo', encryptedObj);
};
export const getUserSelectedService = () => {
  const userInfo = getUserInfo();
  let service = null;
  if (userInfo) {
    if (userInfo.selected_service) {
      service = userInfo.selected_service;
    } else if (userInfo.selected_role.service.length > 0) {
      service = userInfo.selected_role.service[0];
    }
  }
  return service;
};
export const getUserAllService = () => {
  const userInfo = getUserInfo();
  let service = [];
  if (userInfo) {
    if (userInfo.selected_role.service.length > 0) {
      service = userInfo.selected_role.service;
    }
  }
  return service;
};
export const getUserPermissionList = () => {
  const userInfo = getUserInfo();
  // if (userInfo) {
  //   return userInfo.permission;
  // }

  // if (userInfo.selected_role) {
  //   if (userInfo.selected_role.service.length > 0) {
  //     return userInfo.selected_role.service[0].permission;
  //   } else if (userInfo.selected_role.permission) {
  //     return userInfo.selected_role.permission;
  //   }
  // }
  const userService = getUserSelectedService();
  if (userService) {
    return userService.permission;
  } else if (userInfo.selected_role.permission) {
    return userInfo.selected_role.permission;
  }
  return [];
};
export const getUserAllServicePermissionList = () => {
  const userAllService = getUserAllService();
  if (userAllService && userAllService.length > 0) {
    const allPermission = pluck(userAllService, 'permission');
    let mergedPermission: any = [];
    allPermission.forEach((permissionArr: any) => {
      mergedPermission = mergedPermission.concat(permissionArr);
    });
    return mergedPermission;
  }
  return [];
};
export const getActiveRole = () => {
  const activeRole = localStorage.getItem('activeRole');
  if (activeRole) {
    return decryptObject(activeRole);
  }
  return '';
};
export const hasSuperAdminRoleAccess = () => {
  let hasSuperAdminRoleFlag = false;
  const activeRole: any = getActiveRole();
  if (activeRole && activeRole.slug) {
    hasSuperAdminRoleFlag = activeRole.slug == SUPER_ADMIN_ROLE_SLUG;
  }
  return hasSuperAdminRoleFlag;
};
export const getModuleTableSettings = (key?: string) => {
  let tableSettingInfo: any = localStorage.getItem('tableSettingInfo');
  if (tableSettingInfo) {
    tableSettingInfo = decryptObject(tableSettingInfo);
    if (key && tableSettingInfo[key]) {
      return tableSettingInfo[key];
    } else {
      return tableSettingInfo;
    }
  }
  return null;
};
export const storeModuleTableSettings = (key: string, details: any) => {
  const existingObj = getModuleTableSettings();
  const newObject = { ...existingObj, ...{ [key]: details } };
  storeLocalStorage('tableSettingInfo', encryptObject(newObject));
};
