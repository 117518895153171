import { DEFAULT_AWS_IMAGE } from 'config/app.config';
import { getAwsUrl } from 'helpers/AwsHelper';
import { isNullOrEmptyObject } from 'helpers/CommonHelper';

export const userInfoSelector = (state: any) => state.auth.userInfo;
export const userRoleListSelector = (state: any) => {
  const userInfo = userInfoSelector(state);
  if (!isNullOrEmptyObject(userInfo)) {
    return userInfo.user.role || [];
  }
  return [];
};
export const userCurrentRoleSelector = (state: any) =>
  state.auth.userInfo?.selected_role;
export const userCurrentServiceSelector = (state: any) =>
  state.auth.userInfo?.selected_service;
export const userServiceSelector = (state: any) => {
  const userCurrentRole = userCurrentRoleSelector(state);
  if (userCurrentRole && userCurrentRole.service.length > 0) {
    return userCurrentRole.service;
  } else {
    return [];
  }
};
export const userLocationSelector = (state: any) => state.auth.currentLocation;
export const settingsSelector = (state: any) => state.auth.settings;
export const userProfileSelector = (state: any) => state.auth.profileData;
export const isLoggedinSelector = (state: any) =>
  state.auth.userInfo?.authorization;
export const userProfileImageSelector = (state: any) => {
  const userProfileInfo = userProfileSelector(state);
  let profileImage = DEFAULT_AWS_IMAGE.profile;
  if (userProfileInfo && userProfileInfo.profile_pic) {
    profileImage = userProfileInfo.profile_pic;
  }
  return profileImage;
};
export const firebaseTokenSelector = (state: any) => state.auth.firebaseToken;
export const userCurrencySelector = (state: any) => {
  const settings = settingsSelector(state);
  if (isLoggedinSelector(state)) {
    if (userProfileSelector(state)) {
      return userProfileSelector(state).currency.symbol;
    }
  } else if (settings.currency && settings.currency.length > 0) {
    return settings.currency[0].symbol;
  }
  return '₹';
};
export const currencyCodeSelector = (state: any) => {
  const settings = settingsSelector(state);
  if (isLoggedinSelector(state)) {
    return userProfileSelector(state).currency.code;
  } else if (settings.currency && settings.currency.length > 0) {
    return settings.currency[0].code;
  }
  return 'INR';
};
export const countryCodeSelector = (state: any) => {
  const settings = settingsSelector(state);
  // if(isLoggedinSelector(state)){
  //     return userProfileSelector(state).currency.code
  // }
  if (settings.currency && settings.currency.length > 0) {
    return settings.country[0].code;
  }
  return 'IN';
};
export const languageSelector = (state: any) => {
  const settings = settingsSelector(state);
  if (settings.languages[0]) {
    return settings.languages[0].id;
  }
  return '1';
};
export const datePickerFormatSelector = (state: any) => {
  return 'm/d/Y';
};
export const timePickerFormatSelector = (state: any) => {
  return { format: 'H:i', is_24_format: true };
};
export const dateDisplayFormatSelector = (state: any) => {
  return 'MM/DD/YYYY';
};

export const dateTimeDisplayFormatSelector = (state: any) => {
  return 'MM/DD/YYYY HH:mm';
};
export const timeDisplayFormatSelector = (state: any) => {
  return 'HH:mm';
};
export const liveClockTimeDisplayFormatSelector = (state: any) => {
  return 'HH:mm:ss';
};
export const userTimeZoneObjSelector = (state: any) => {
  if (state.auth.userInfo) {
    return state.auth.userInfo.notification_settings.timezone;
  }
  return null;
};
export const userTimeZoneSelector = (state: any) => {
  const timezoneObj = userTimeZoneObjSelector(state);
  if (timezoneObj) {
    return timezoneObj?.timezone;
  }
  // return 'Asia/Kolkata';
  return 'UTC';
};
