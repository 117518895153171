import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { SearchResult as SearchResultType, searchItems } from 'data/search';
import Scrollbar from 'components/base/Scrollbar';
import { ColumnDef } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { faClockRotateLeft, faLink } from '@fortawesome/free-solid-svg-icons';
import { errorMessage } from 'helpers/ToastNotificationHelper';
import { getGlobalSearchData } from 'store/actions/DashboardAction';
import {
  hasUserAccessFunctionality,
  permissionSlugs
} from 'helpers/PermissionHelper';
import { t } from 'i18next';
import {
  capitalizeWord,
  encryptText,
  formatSerialNo,
  getTruncatedString,
  placeParams,
  placeParamsToURL
} from 'helpers/CommonHelper';
import { mainRoutes } from 'config/routing';

const ResultSectionHeader = ({ title }: { title: string }) => {
  return (
    <h6 className="text-1000 fs-9 border-y border-200 py-2 lh-sm mb-0 px-3">
      {title}
    </h6>
  );
};

const searchFields: ColumnDef<SearchResultType>[] = [
  {
    accessorKey: 'label'
  }
];

const GlobalSearchResult = ({
  searchValue = '',
  dropdownRef,
  setSearchInputValue
}: {
  searchValue?: string;
  dropdownRef?: any;
  setSearchInputValue?: any;
}) => {
  const navigate = useNavigate();
  // const results: any = []; //useSearchHook(searchItems, searchFields, searchValue);
  const [results, setResults] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  useEffect(() => {
    const getSearchData = () => {
      const moduleList = [];
      hasUserAccessFunctionality(permissionSlugs.candidate_detail) &&
        moduleList.push('candidate');
      hasUserAccessFunctionality(permissionSlugs.client_list) &&
        moduleList.push('client');
      hasUserAccessFunctionality(permissionSlugs.job_opening_detail) &&
        moduleList.push('job_opening');
      hasUserAccessFunctionality(permissionSlugs.submit_candidate_list) &&
        moduleList.push('candidate_submission');
      hasUserAccessFunctionality(permissionSlugs.interview_list) &&
        moduleList.push('interview');

      const payload = {
        limit: 5,
        module: [
          'candidate',
          'client',
          'job_opening',
          'candidate_submission',
          'interview'
        ],
        query: searchValue
      };
      getGlobalSearchData(payload, (status: boolean, response: any) => {
        setLoading(false);
        if (status) {
          setResults(response.data);
        } else {
          errorMessage(response.message);
        }
      });
    };
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    let newTimeoutId;
    if (searchValue) {
      setLoading(true);
      newTimeoutId = setTimeout(() => {
        getSearchData();
      }, 700);
    } else {
      setResults([]);
      setLoading(false);
    }
    if (newTimeoutId) {
      setTimeoutId(newTimeoutId);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [searchValue]);

  const candidates = useMemo(() => results['candidate'] || [], [results]);
  const clients = useMemo(() => results['client'] || [], [results]);
  const jobOpenings = useMemo(() => results['job_opening'] || [], [results]);
  const totalResult = useMemo(
    () => candidates.length + jobOpenings.length,
    [candidates, jobOpenings]
  );
  useEffect(() => {
    if (dropdownRef.current) {
      if (searchValue) {
        dropdownRef.current.classList.add('navbar-top-search-box');
      } else {
        dropdownRef.current.classList.remove('navbar-top-search-box');
      }
    }
  }, [totalResult]);
  // const quickLinks = useMemo(
  //   () => results.filter((item: any) => item.category === 'quick_links'),
  //   [results]
  // );
  const handlePageNavigation = (type: string, row: any) => {
    let navigateURL = '';
    let stateParams: any;
    if (type == 'job_opening') {
      console.log('1ff.........', row);
      navigateURL = placeParamsToURL(mainRoutes.jobOpeningDetail.path, {
        id: encryptText(row.id)
      });
    } else if (type == 'candidate') {
      navigateURL = placeParamsToURL(mainRoutes.candidateDetail.path, {
        id: encryptText(row.id)
      });
    } else if (type == 'client') {
      navigateURL = placeParamsToURL(mainRoutes.client.path);
      stateParams = {
        search: formatSerialNo('client', row.sr_no)
      };
    }
    // else if (type == 'interview') {
    //   navigateURL = placeParams(mainRoutes.interviewDetail.path, {
    //     id: encryptText(row.id)
    //   });
    //   stateParams = {};
    // }
    if (navigateURL) {
      console.log('1.........', row);

      if (setSearchInputValue) {
        setSearchInputValue('');
      }
      if (stateParams) {
        console.log('2.........');
        navigate(navigateURL, {
          state: { searchState: stateParams }
        });
      } else {
        console.log('3.........');
        navigate(navigateURL);
      }
    }
  };
  if (searchValue) {
    return (
      <Scrollbar autoHeight autoHeightMax={'30rem'}>
        <h6 className="text-1000 fs-10 py-2 mb-0 px-3">
          {!loading && totalResult >= 0 ? (
            <>
              {totalResult} <span className="text-500">Results</span>{' '}
            </>
          ) : (
            'Loading...'
          )}
        </h6>
        {candidates.length > 0 && (
          <>
            <ResultSectionHeader title={t('candidates')} />
            <div className="py-2">
              {candidates.map((row: any, index: number) => (
                <Dropdown.Item
                  /* as={Link}
                  to={placeParams(mainRoutes.candidateDetail.path, {
                    id: encryptText(row.id)
                  })} */
                  onClick={() => handlePageNavigation('candidate', row)}
                  key={index}
                  className="py-2 d-flex gap-2 align-items-center"
                >
                  <div className="">
                    <h6 className="mb-0 text-1000">{`${formatSerialNo('candidate', row.sr_no)} - ${capitalizeWord(row.first_name + ' ' + row.last_name)}`}</h6>
                    {row.email && (
                      <p className="fs-10 mb-0 d-flex text-700">
                        <span className="fw-medium text-600">{row.email}</span>
                      </p>
                    )}
                    {row.mobile_code && row.mobile && (
                      <p className="fs-10 mb-0 d-flex text-700">
                        <span className="fw-medium text-600">{`+${row.mobile_code}${row.mobile}`}</span>
                      </p>
                    )}
                  </div>
                </Dropdown.Item>
              ))}
            </div>
          </>
        )}
        {jobOpenings.length > 0 && (
          <>
            <ResultSectionHeader title={t('job_openings')} />
            <div className="py-2">
              {jobOpenings.map((row: any, index: number) => (
                <Dropdown.Item
                  // as={Link}
                  /* to={placeParams(mainRoutes.jobOpeningDetail.path, {
                    id: encryptText(row.id)
                  })} */
                  onClick={() => handlePageNavigation('job_opening', row)}
                  key={index}
                  className="py-2 d-flex gap-2 align-items-center"
                >
                  <div className="">
                    <h6 className="mb-0 text-1000">{`${formatSerialNo('job_opening', row.sr_no)} - ${getTruncatedString(row.job_title, 25)}`}</h6>
                  </div>
                </Dropdown.Item>
              ))}
            </div>
          </>
        )}
        {clients.length > 0 && (
          <>
            <ResultSectionHeader title={t('clients')} />
            <div className="py-2">
              {clients.map((row: any, index: number) => (
                <Dropdown.Item
                  // as={Link}
                  /* to={placeParams(mainRoutes.jobOpeningDetail.path, {
                    id: encryptText(row.id)
                  })} */
                  onClick={() => handlePageNavigation('client', row)}
                  key={index}
                  className="py-2 d-flex gap-2 align-items-center"
                >
                  <div className="">
                    <h6 className="mb-0 text-1000">{`${formatSerialNo('client', row.sr_no)} - ${getTruncatedString(row.name, 25)}`}</h6>
                    {row.industry && (
                      <p className="fs-10 mb-0 d-flex text-700">
                        <span className="fw-medium text-600">{`${row.industry}`}</span>
                      </p>
                    )}
                  </div>
                </Dropdown.Item>
              ))}
            </div>
          </>
        )}
        {/* {recentlySearchedItems.length > 0 && (
        <>
          <ResultSectionHeader title="Recently Searched" />
          <div className="py-2">
            {recentlySearchedItems.map((item: any) => (
              <Dropdown.Item as={Link} to={item.url} key={item.label}>
                <div className="d-flex align-items-center fw-normal gap-1 text-1000">
                  <FontAwesomeIcon
                    icon={faClockRotateLeft}
                    transform="shrink-2"
                  />
                  {item.label}
                </div>
              </Dropdown.Item>
            ))}
          </div>
        </>
      )} */}
        {/* {products.length > 0 && (
        <>
          <ResultSectionHeader title="Products" />
          <div className="py-2">
            {products.map((item: any) => (
              <Dropdown.Item
                as={Link}
                to={item.url}
                key={item.label}
                className="py-2 d-flex gap-2 align-items-center"
              >
                <div className="file-thumbnail">
                  <img
                    className="fit-cover rounded-3"
                    src={item.image}
                    height={28}
                    width={28}
                    alt={item.label}
                  />
                </div>
                <div className="flex-1">
                  <h6 className="mb-0 text-1000">{item.label}</h6>
                  <p className="fs-10 mb-0 d-flex text-700">
                    <span className="fw-medium text-600">{item.details}</span>
                  </p>
                </div>
              </Dropdown.Item>
            ))}
          </div>
        </>
      )} */}
        {/* {quickLinks.length > 0 && (
        <>
          <ResultSectionHeader title="Quick Links" />
          <div className="py-2">
            {quickLinks.map((item: any) => (
              <Dropdown.Item as={Link} to={item.url} key={item.label}>
                <div className="d-flex align-items-center fw-normal gap-1 text-1000">
                  <FontAwesomeIcon
                    icon={faLink}
                    transform="shrink-2"
                    className="text-900"
                  />
                  {item.label}
                </div>
              </Dropdown.Item>
            ))}
          </div>
        </>
      )} */}
        {/*  {suggestionFiles.length > 0 && (
        <>
          <ResultSectionHeader title="Files" />
          <div className="py-2">
            {suggestionFiles.map((item: any) => (
              <Dropdown.Item as={Link} to={item.url} key={item.label}>
                <div className="d-flex align-items-center fw-normal gap-1 text-1000">
                  <FontAwesomeIcon
                    icon={getFileIcon(item.format || '')}
                    transform="shrink-2"
                    className="text-900"
                  />
                  {item.label}
                </div>
              </Dropdown.Item>
            ))}
          </div>
        </>
      )} */}
        {/* {members.length > 0 && (
        <>
          <ResultSectionHeader title="Members" />
          <div className="py-2">
            {members.map((item: any) => (
              <Dropdown.Item as={Link} to={item.url} key={item.label}>
                <div className="d-flex align-items-center fw-normal gap-1 text-1000">
                  <Avatar
                    src={item.avatar}
                    size="l"
                    status={item.status as Status}
                  />
                  <div className="flex-1">
                    <h6 className="mb-0 text-1000 title">{item.label}</h6>
                    <p className="fs-10 mb-0 d-flex text-700">{item.details}</p>
                  </div>
                </div>
              </Dropdown.Item>
            ))}
          </div>
        </>
      )} */}
        {/*   {relatedSearchedItems.length > 0 && (
        <>
          <ResultSectionHeader title="Related Searches" />
          <div className="py-2">
            {relatedSearchedItems.map((item: any) => (
              <Dropdown.Item as={Link} to={item.url} key={item.label}>
                <div className="d-flex align-items-center fw-normal gap-1 text-1000">
                  <FontAwesomeIcon
                    icon={item.icon as IconProp}
                    transform="shrink-2"
                    className="text-900"
                  />
                  {item.label}
                </div>
              </Dropdown.Item>
            ))}
          </div>
        </>
      )} */}
      </Scrollbar>
    );
  }
  return null;
};

export default GlobalSearchResult;
