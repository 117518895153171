import { DiceIcon } from 'components/_trackivo/common/Icons';
import { t } from 'i18next';
import { capitalize } from './utils';
import { capitalizeWord } from './CommonHelper';
import { HYBRID_SLUG, ON_SITE_SLUG, REMOTE_SLUG } from './DataHelper';

export const DICE_SLUG = 'dice';
export const sourceBoosterTypes = [
  {
    type: DICE_SLUG,
    name: t('dice'),
    icon: DiceIcon,
    message:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book"
  }
];
// DICE............variables...fun.........start......................
// education
export const DICE_EDUCATION_LIST = [
  { slug: 'high school', name: 'High School' },
  { slug: 'bachelors', name: 'Bachelors' },
  { slug: 'associate', name: 'Associate' },
  { slug: 'vocational school', name: 'Vocational School' },
  { slug: 'masters', name: 'Masters' },
  { slug: 'military service', name: 'Military Service' },
  { slug: 'mba', name: 'MBA' }
];
export const getDICEEducationObj = (slug: any, filterKeyName: string) => {
  let selectedSlug = null;
  const filteredData = DICE_EDUCATION_LIST.filter(function (item: any) {
    return item[filterKeyName] == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// SOCIAL_PROFILE
export const DICE_SOCIAL_PROFILE_LIST = [
  { slug: 'github', name: 'Github' },
  { slug: 'stackoverflow', name: 'Stackoverflow' },
  { slug: 'twitter', name: 'Twitter' },
  { slug: 'facebook', name: 'Facebook' },
  { slug: 'linkedin', name: 'Linkedin' },
  { slug: 'meetup', name: 'Meetup' }
];
export const getDICESocialProfileObj = (slug: any, filterKeyName: string) => {
  let selectedSlug = null;
  const filteredData = DICE_SOCIAL_PROFILE_LIST.filter(function (item: any) {
    return item[filterKeyName] == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// travel_preferences
export const DICE_TRAVEL_PREFERENCE_LIST = [25, 50, 75, 100, 0];
// work settings
export const DICE_DESIRED_WORK_SETTING_LIST = ['ONSITE', 'HYBRID', 'REMOTE'];
// compensation
export const DICE_CURRENCY_LIST = [
  { symbol: '£', slug: 'GBP' },
  { symbol: '$', slug: 'USD' },
  { symbol: '$', slug: 'CAD' },
  { symbol: '€', slug: 'EUR' }
];
export const DICE_COMPENSATION_FREQUENCY_LIST = [
  { name: 'Yearly', slug: 'yearly' },
  { name: 'Hourly', slug: 'hourly' }
];
// Work Permit
export const DICE_WORK_PERMIT_LIST = [
  { slug: 'unspecified', name: 'unspecified' },
  { slug: 'us citizenship', name: 'us citizenship' },
  { slug: 'green card', name: 'green card' },
  { slug: 'employment auth document', name: 'employment auth document' },
  { slug: 'have h1', name: 'have h1' },
  { slug: 'need h1', name: 'need h1' },
  { slug: 'canadian citizen', name: 'canadian citizen' },
  { slug: 'tn permit holder', name: 'tn permit holder' }
];
export const getDICEWorkPermitObj = (slug: any, filterKeyName: string) => {
  let selectedSlug = null;
  const filteredData = DICE_WORK_PERMIT_LIST.filter(function (item: any) {
    return item[filterKeyName] == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// Work Permit Location
export const DICE_WORK_PERMIT_LOCATION_LIST = [
  { slug: 'US', name: 'United States of America' },
  { slug: 'UK', name: 'United Kingdom' },
  { slug: 'CA', name: 'Canada' }
];
export const getDICEWorkPermitLocationObj = (
  slug: any,
  filterKeyName: string
) => {
  let selectedSlug = null;
  const filteredData = DICE_WORK_PERMIT_LOCATION_LIST.filter(function (
    item: any
  ) {
    return item[filterKeyName] == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// language
export const DICE_LANGUAGE_LIST = [
  'Italian',
  'Spanish',
  'English',
  'Hindi',
  'Arabic',
  'Japanese'
];
// profile sources
export const DICE_PROFILE_SOURCE_LIST = [
  { slug: 'Resume', name: 'Dice Only' },
  { slug: 'Integrated', name: 'Any (Dice&Social)' }
];
export const getDICEProfileSourceObj = (slug: any, filterKeyName: string) => {
  let selectedSlug = null;
  const filteredData = DICE_PROFILE_SOURCE_LIST.filter(function (item: any) {
    return item[filterKeyName] == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// location
export const DICE_DEFAULT_UOM = 'miles';
export const DICE_LOCATION_UOM_LIST = [
  { slug: 'miles', name: 'Miles (MI)', short_name: 'MI' },
  { slug: 'km', name: 'Kilometers(KM)', short_name: 'KM' }
];
export const getDICELocationUOMObj = (slug: any, filterKeyName: string) => {
  let selectedSlug = null;
  const filteredData = DICE_LOCATION_UOM_LIST.filter(function (item: any) {
    return item[filterKeyName] == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// work place type
export const DICE_WORK_PLACE_TYPE_LIST = [
  { slug: REMOTE_SLUG, name: 'REMOTE' },
  { slug: ON_SITE_SLUG, name: 'ONSITE' },
  { slug: HYBRID_SLUG, name: 'HYBRID' }
];
export const getDICEWorkPlaceTypeObj = (slug: any, filterKeyName: string) => {
  let selectedSlug = null;
  const filteredData = DICE_WORK_PLACE_TYPE_LIST.filter(function (item: any) {
    return item[filterKeyName] == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const DICE_EMPLOYMENT_TYPE = [
  { slug: 'full-time', name: 'Full-time' },
  { slug: 'part-time', name: 'Part-time' },
  { slug: 'contract-corp-to-corp', name: 'Contract - Corp-to-Corp' },
  { slug: 'contract-independent', name: 'Contract - Independent' },
  { slug: 'contract-w2', name: 'Contract - W2' },
  {
    slug: 'contract-to-hire-corp-to-corp',
    name: 'Contract to Hire - Corp-to-Corp'
  },
  {
    slug: 'contract-to-hire-independent',
    name: 'Contract to Hire - Independent'
  },
  { slug: 'contract-to-hire-w2', name: 'Contract to Hire - W2' },
  { slug: 'internship', name: 'Internship' }
];
export const getDICEEmploymentTypeObj = (slug: any, filterKeyName: string) => {
  let selectedSlug = null;
  const filteredData = DICE_EMPLOYMENT_TYPE.filter(function (item: any) {
    return item[filterKeyName] == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// DICE............variables...fun.........end......................
// export const getStringPrefix = (name: string) => {
//   const nameArr = name.split(' ');
//   let prefix = '';
//   if (nameArr.length > 0) {
//     prefix = name[0];
//     if (nameArr.length > 1) {
//       prefix = nameArr[0][0] + nameArr[nameArr.length - 1][0];
//     }
//   }
//   return capitalize(prefix);
// };
export const getStringPrefix = (name: string): string => {
  // Split the name by spaces
  const nameArr = name.split(' ').filter(Boolean);

  // Initialize the prefix
  let prefix = '';

  // Check if there's at least one word
  if (nameArr.length > 0) {
    prefix = nameArr[0][0]; // First letter of the first word

    // If there's more than one word, handle the last word
    if (nameArr.length > 1) {
      // Find the last word that doesn't start with a special character
      let lastWordIndex = nameArr.length - 1;
      while (
        lastWordIndex > 0 &&
        /[^a-zA-Z0-9]/.test(nameArr[lastWordIndex][0])
      ) {
        lastWordIndex--;
      }

      // Update the prefix to include the first letter of the last valid word
      if (lastWordIndex > 0) {
        // Check if there's a valid last word
        prefix += nameArr[lastWordIndex][0];
      } else {
        // If no valid last word, default to first letter of the first word again
        prefix += nameArr[0][0];
      }
    }
  }

  // Return the capitalized prefix
  return capitalize(prefix);
};
export const getFullName = (firstName: string, lastName: string) => {
  if (firstName && lastName) {
    return capitalizeWord(firstName + ' ' + lastName);
  } else {
    return capitalizeWord(firstName);
  }
};
export const downloadResume = (resume: any, action = 'download') => {
  const fileType = resume.contentType;
  const fileName = resume.filename;
  // Convert the Base64 string to binary data
  const byteCharacters = atob(resume.resumeData);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob from the binary data
  const blob = new Blob([byteArray], { type: fileType });
  const URL = window.URL.createObjectURL(blob);
  if (action == 'download') {
    // Create a download link
    const link = document.createElement('a');
    link.href = URL;
    link.download = fileName;

    // Trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  } else {
    return URL;
  }
};
interface CountryDialingCodes {
  [key: string]: string;
}
export const MOBILE_CODE_OBJ: CountryDialingCodes = {
  AF: '93',
  AL: '355',
  DZ: '213',
  AS: '1684',
  AD: '376',
  AO: '244',
  AI: '1264',
  AQ: '672',
  AG: '1268',
  AR: '54',
  AM: '374',
  AW: '297',
  AU: '61',
  AT: '43',
  AZ: '994',
  BS: '1242',
  BH: '973',
  BD: '880',
  BB: '1246',
  BY: '375',
  BE: '32',
  BZ: '501',
  BJ: '229',
  BM: '1441',
  BT: '975',
  BO: '591',
  BA: '387',
  BW: '267',
  BR: '55',
  IO: '246',
  BN: '673',
  BG: '359',
  BF: '226',
  BI: '257',
  CV: '238',
  KH: '855',
  CM: '237',
  CA: '1',
  KY: '1345',
  CF: '236',
  TD: '235',
  CL: '56',
  CN: '86',
  CX: '61',
  CC: '61',
  CO: '57',
  KM: '269',
  CD: '243',
  CG: '242',
  CK: '682',
  CR: '506',
  HR: '385',
  CU: '53',
  CY: '357',
  CZ: '420',
  DK: '45',
  DJ: '253',
  DM: '1767',
  DO: '1809',
  EC: '593',
  EG: '20',
  SV: '503',
  GQ: '240',
  ER: '291',
  EE: '372',
  SZ: '268',
  ET: '251',
  FK: '500',
  FO: '298',
  FJ: '679',
  FI: '358',
  FR: '33',
  GF: '594',
  PF: '689',
  TF: '262',
  GA: '241',
  GM: '220',
  GE: '995',
  DE: '49',
  GH: '233',
  GI: '350',
  GR: '30',
  GL: '299',
  GD: '1473',
  GP: '590',
  GU: '1671',
  GT: '502',
  GG: '44',
  GN: '224',
  GW: '245',
  GY: '592',
  HT: '509',
  HM: '672',
  VA: '379',
  HN: '504',
  HK: '852',
  HU: '36',
  IS: '354',
  IN: '91',
  ID: '62',
  IR: '98',
  IQ: '964',
  IE: '353',
  IM: '44',
  IL: '972',
  IT: '39',
  JM: '1876',
  JP: '81',
  JE: '44',
  JO: '962',
  KZ: '7',
  KE: '254',
  KI: '686',
  KP: '850',
  KR: '82',
  KW: '965',
  KG: '996',
  LA: '856',
  LV: '371',
  LB: '961',
  LS: '266',
  LR: '231',
  LY: '218',
  LI: '423',
  LT: '370',
  LU: '352',
  MO: '853',
  MG: '261',
  MW: '265',
  MY: '60',
  MV: '960',
  ML: '223',
  MT: '356',
  MH: '692',
  MQ: '596',
  MR: '222',
  MU: '230',
  YT: '262',
  MX: '52',
  FM: '691',
  MD: '373',
  MC: '377',
  MN: '976',
  ME: '382',
  MS: '1664',
  MA: '212',
  MZ: '258',
  MM: '95',
  NA: '264',
  NR: '674',
  NP: '977',
  NL: '31',
  NC: '687',
  NZ: '64',
  NI: '505',
  NE: '227',
  NG: '234',
  NU: '683',
  NF: '672',
  MP: '1670',
  NO: '47',
  OM: '968',
  PK: '92',
  PW: '680',
  PS: '970',
  PA: '507',
  PG: '675',
  PY: '595',
  PE: '51',
  PH: '63',
  PN: '870',
  PL: '48',
  PT: '351',
  PR: '1787',
  QA: '974',
  MK: '389',
  RO: '40',
  RU: '7',
  RW: '250',
  RE: '262',
  BL: '590',
  SH: '290',
  KN: '1869',
  LC: '1758',
  MF: '590',
  PM: '508',
  VC: '1784',
  WS: '685',
  SM: '378',
  ST: '239',
  SA: '966',
  SN: '221',
  RS: '381',
  SC: '248',
  SL: '232',
  SG: '65',
  SX: '1721',
  SK: '421',
  SI: '386',
  SB: '677',
  SO: '252',
  ZA: '27',
  GS: '500',
  SS: '211',
  ES: '34',
  LK: '94',
  SD: '249',
  SR: '597',
  SJ: '47',
  SE: '46',
  CH: '41',
  SY: '963',
  TW: '886',
  TJ: '992',
  TZ: '255',
  TH: '66',
  TL: '670',
  TG: '228',
  TK: '690',
  TO: '676',
  TT: '1868',
  TN: '216',
  TR: '90',
  TM: '993',
  TC: '1649',
  TV: '688',
  UG: '256',
  UA: '380',
  AE: '971',
  GB: '44',
  UM: '1',
  US: '1',
  UY: '598',
  UZ: '998',
  VU: '678',
  VE: '58',
  VN: '84',
  VG: '1284',
  VI: '1340',
  WF: '681',
  EH: '212',
  YE: '967',
  ZM: '260',
  ZW: '263',
  AX: '358'
};
