const initialstate = {
  leadTableColumnValidation: {
    minListColumn: 2,
    maxListColumn: 8,
    minFilterColumn: 0,
    maxFilterColumn: 8
  },
  leadTableColumnList: [
    {
      name: 'sr_no',
      label_key: 'sr_no',
      allow_list: true,
      allow_filter: false,
      order: 1
    },
    {
      name: 'name',
      label_key: 'name',
      allow_list: true,
      allow_filter: false,
      order: 2
    },
    {
      name: 'email',
      label_key: 'email',
      allow_list: true,
      allow_filter: true,
      order: 3
    },
    {
      name: 'mobile',
      label_key: 'mobile',
      allow_list: true,
      allow_filter: false,
      order: 4
    },
    {
      name: 'joining_availability',
      label_key: 'availability',
      allow_list: true,
      allow_filter: false,
      order: 5
    },
    {
      name: 'candidate_status',
      label_key: 'candidate_status',
      allow_list: true,
      allow_filter: false,
      order: 6
    }
    // {
    //   name: 'added_by',
    //   label_key: 'created_by',
    //   allow_list: true,
    //   allow_filter: false,
    //   order: 7
    // },
    // {
    //   name: 'created_at',
    //   label_key: 'timestamp',
    //   allow_list: true,
    //   allow_filter: false,
    //   order: 8
    // }
  ],
  leadDevelopmentTableColumnValidation: {
    minListColumn: 2,
    maxListColumn: 8,
    minFilterColumn: 0,
    maxFilterColumn: 8
  },
  leadDevelopmentTableColumnList: [
    {
      name: 'sr_no',
      label_key: 'sr_no',
      allow_list: true,
      allow_filter: false,
      order: 1
    },
    {
      name: 'name',
      label_key: 'name',
      allow_list: true,
      allow_filter: false,
      order: 2
    },
    {
      name: 'email',
      label_key: 'email',
      allow_list: true,
      allow_filter: true,
      order: 3
    },
    {
      name: 'mobile',
      label_key: 'mobile',
      allow_list: true,
      allow_filter: false,
      order: 4
    },
    {
      name: 'joining_availability',
      label_key: 'availability',
      allow_list: true,
      allow_filter: false,
      order: 5
    },
    {
      name: 'candidate_status',
      label_key: 'candidate_status',
      allow_list: true,
      allow_filter: false,
      order: 6
    }
  ]
};
interface actionProp {
  type: string;
  payload: object;
}
const LeadReducer = (state = initialstate, action: actionProp) => {
  switch (action.type) {
    case 'UPDATE_LEAD_TABLE_COLUMN_LIST':
      return {
        ...state,
        leadTableColumnList: action.payload
      };
    case 'UPDATE_LEAD_DEVELOPMENT_TABLE_COLUMN_LIST':
      return {
        ...state,
        leadDevelopmentTableColumnList: action.payload
      };
    default:
      return state;
  }
};

export default LeadReducer;
