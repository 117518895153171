import React from 'react';

const DashboardSvg = ({
  height = 21,
  width = 21,
  viewBox = '0 0 512 512'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <>
      {/* <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width={width}
        height={height}
        viewBox={viewBox}
      >
        <defs></defs>
        <path
          fill="currentColor"
          strokeWidth={'0px'}
          d="M197.3,0H37.3C16.7,0,0,16.7,0,37.3v96c0,20.6,16.7,37.3,37.3,37.3h160c20.6,0,37.3-16.7,37.3-37.3V37.3c0-20.6-16.7-37.3-37.3-37.3Z"
        />
        <path
          fill="currentColor"
          strokeWidth={'0px'}
          d="M197.3,213.3H37.3c-20.6,0-37.3,16.7-37.3,37.3v224c0,20.6,16.7,37.3,37.3,37.3h160c20.6,0,37.3-16.7,37.3-37.3v-224c0-20.6-16.7-37.3-37.3-37.3Z"
        />
        <path
          fill="currentColor"
          strokeWidth={'0px'}
          d="M474.7,341.3h-160c-20.6,0-37.3,16.7-37.3,37.3v96c0,20.6,16.7,37.3,37.3,37.3h160c20.6,0,37.3-16.7,37.3-37.3v-96c0-20.6-16.7-37.3-37.3-37.3Z"
        />
        <path
          fill="currentColor"
          strokeWidth={'0px'}
          d="M474.7,0h-160c-20.6,0-37.3,16.7-37.3,37.3v224c0,20.6,16.7,37.3,37.3,37.3h160c20.6,0,37.3-16.7,37.3-37.3V37.3c0-20.6-16.7-37.3-37.3-37.3Z"
        />
      </svg> */}
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        height={height}
        width={width}
        viewBox={viewBox}
        /* style={{ enableBackground: 'new 0 0 512 512' }} */
        xmlSpace="preserve"
        fill="currentColor"
      >
        <g>
          <g>
            <path d="M64.671,146.502C27.711,188.049,3.457,241.62,0,300.62h60.76c3.259-42.488,20.112-81.161,46.32-111.709L64.671,146.502z" />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M249.305,332.188L249.305,332.188L249.305,332.188c-5.113,2.581-8.305,7.726-8.305,13.432
			c0.015,12.598,17.783,19.438,26.191,9.99l52.427-58.608L249.305,332.188z"
            />
          </g>
        </g>
        <g>
          <g>
            <path d="M85.882,125.291l42.409,42.409c30.548-26.208,70.221-43.061,112.709-46.32v-60C182,64.836,127.43,88.331,85.882,125.291z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M271,61.38v60c42.488,3.259,82.161,20.112,112.709,46.32l42.409-42.409C384.57,88.331,330,64.836,271,61.38z" />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M447.329,146.502l-42.409,42.409c26.208,30.547,43.061,69.221,46.32,111.709H512
			C508.543,241.62,484.289,188.049,447.329,146.502z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M450.731,330.62c-1.307,17.011-4.142,33.83-9.835,49.98c-2.752,7.775-11.239,11.918-19.131,9.17
			c-7.822-2.754-11.924-11.323-9.17-19.131c6.24-17.71,9.404-36.211,9.404-55.02c0-45.487-18.501-86.733-48.375-116.611
			c-0.002-0.002-0.007-0.004-0.007-0.004s-0.005-0.009-0.007-0.011C343.733,169.12,301.487,150.62,256,150.62
			s-87.733,18.501-117.611,48.375c-0.002,0.002-0.004,0.007-0.004,0.007s-0.009,0.005-0.011,0.007
			C108.501,228.886,90,270.133,90,315.62c0,18.809,3.164,37.31,9.404,55.02c2.754,7.808-1.348,16.377-9.17,19.131
			c-7.749,2.739-16.362-1.362-19.131-9.17c-5.693-16.15-8.527-32.968-9.835-49.98H0c2.146,36.632,11.7,67.637,25.107,95.464
			c7.178,14.897,22.939,24.536,40.151,24.536h381.468c17.227,0,32.988-9.639,40.181-24.551
			c13.239-27.556,23.031-60.256,25.093-95.449H450.731z M395.398,258.403L289.545,375.62c-27.25,30.48-78.545,11.411-78.545-30
			c0-17.124,9.525-32.536,24.838-40.23c0.02-0.009,0.029-0.029,0.049-0.038l141.625-70.371c0.049-0.024,0.101-0.016,0.15-0.04
			C392.17,227.869,406.451,245.693,395.398,258.403z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    </>
  );
};
export default DashboardSvg;
