import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { userInfoSelector } from 'components/_trackivo/selectors/authSelectors';
import { getUserAuthToken } from 'helpers/StorageHelper';
import { mainRoutes as routes, loginRoutes } from 'config/routing';
import { RootState } from 'store';
const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const user = getUserAuthToken();
  if (!user) {
    // not logged in so redirect to login page with the return url
    return <Navigate to={loginRoutes.signin.path} replace />;
  } else {
    return children;
  }
};
const mapStateToProps = (state: RootState) => {
  return {
    userInfo: userInfoSelector(state)
  };
};
export default connect(mapStateToProps, {})(ProtectedRoute);
