import React from 'react';

const CalendarSvg = ({
  height = 21,
  width = 21,
  viewBox = '0 0 32 32'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <>
      {/* <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <path
        fill="currentColor"
        strokeWidth={'0px'}
        d="M445.7,30.1h-45.2v45.2c0,9-7.5,15.1-15.1,15.1s-15.1-6-15.1-15.1V30.1h-240.9v45.2c0,9-7.5,15.1-15.1,15.1s-15.1-6-15.1-15.1V30.1h-45.2c-22.6,0-39.2,19.6-39.2,45.2v54.2h481.9v-54.2c0-25.6-27.1-45.2-51.2-45.2ZM15.1,161.1v275.6c0,27.1,16.6,45.2,40.7,45.2h391.5c24.1,0,51.2-19.6,51.2-45.2V161.1H15.1ZM149.1,414.1h-36.1c-6,0-12-4.5-12-12v-37.6c0-6,4.5-12,12-12h37.6c6,0,12,4.5,12,12v37.6c-1.5,7.5-6,12-13.6,12h0ZM149.1,278.6h-36.1c-6,0-12-4.5-12-12v-37.6c0-6,4.5-12,12-12h37.6c6,0,12,4.5,12,12v37.6c-1.5,7.5-6,12-13.6,12h0ZM269.6,414.1h-37.6c-6,0-12-4.5-12-12v-37.6c0-6,4.5-12,12-12h37.6c6,0,12,4.5,12,12v37.6c0,7.5-4.5,12-12,12ZM269.6,278.6h-37.6c-6,0-12-4.5-12-12v-37.6c0-6,4.5-12,12-12h37.6c6,0,12,4.5,12,12v37.6c0,7.5-4.5,12-12,12ZM390,414.1h-37.6c-6,0-12-4.5-12-12v-37.6c0-6,4.5-12,12-12h37.6c6,0,12,4.5,12,12v37.6c0,7.5-4.5,12-12,12ZM390,278.6h-37.6c-6,0-12-4.5-12-12v-37.6c0-6,4.5-12,12-12h37.6c6,0,12,4.5,12,12v37.6c0,7.5-4.5,12-12,12Z"
      />
    </svg> */}

      <svg
        id="OBJECT"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        fill="currentColor"
      >
        <path
          fill="currentColor"
          d="m24.5 16c1.25 0 2.43.28 3.5.76v-6.76h-27v17c0 .55.45 1 1 1h14.76c-.49-1.07-.76-2.25-.76-3.5 0-4.69 3.81-8.5 8.5-8.5zm-2-3.5h.5c.55 0 1 .45 1 1s-.45 1-1 1h-.5c-.55 0-1-.45-1-1s.45-1 1-1zm-4.62 0h.5c.55 0 1 .45 1 1s-.45 1-1 1h-.5c-.55 0-1-.45-1-1s.45-1 1-1zm-13.38 11h-.5c-.55 0-1-.45-1-1s.45-1 1-1h.5c.55 0 1 .45 1 1s-.45 1-1 1zm0-4.5h-.5c-.55 0-1-.45-1-1s.45-1 1-1h.5c.55 0 1 .45 1 1s-.45 1-1 1zm0-4.5h-.5c-.55 0-1-.45-1-1s.45-1 1-1h.5c.55 0 1 .45 1 1s-.45 1-1 1zm4.62 9h-.5c-.55 0-1-.45-1-1s.45-1 1-1h.5c.55 0 1 .45 1 1s-.45 1-1 1zm0-4.5h-.5c-.55 0-1-.45-1-1s.45-1 1-1h.5c.55 0 1 .45 1 1s-.45 1-1 1zm0-4.5h-.5c-.55 0-1-.45-1-1s.45-1 1-1h.5c.55 0 1 .45 1 1s-.45 1-1 1zm4.62 9h-.5c-.55 0-1-.45-1-1s.45-1 1-1h.5c.55 0 1 .45 1 1s-.45 1-1 1zm0-4.5h-.5c-.55 0-1-.45-1-1s.45-1 1-1h.5c.55 0 1 .45 1 1s-.45 1-1 1zm0-4.5h-.5c-.55 0-1-.45-1-1s.45-1 1-1h.5c.55 0 1 .45 1 1s-.45 1-1 1z"
        />
        <path
          fill="currentColor"
          d="m28 5c0-.55-.45-1-1-1h-1v-2c0-.55-.45-1-1-1s-1 .45-1 1v2h-5v-2c0-.55-.45-1-1-1s-1 .45-1 1v2h-5v-2c0-.55-.45-1-1-1s-1 .45-1 1v2h-5v-2c0-.55-.45-1-1-1s-1 .45-1 1v2h-1c-.55 0-1 .45-1 1v3h27z"
        />
        <path
          fill="currentColor"
          d="m24.5 18c-3.58 0-6.5 2.92-6.5 6.5s2.92 6.5 6.5 6.5 6.5-2.92 6.5-6.5-2.92-6.5-6.5-6.5zm2.72 9.19c-.2.2-.45.29-.71.29s-.51-.1-.71-.29l-2-2c-.19-.19-.29-.44-.29-.71v-3c0-.55.45-1 1-1s1 .45 1 1v2.59l1.71 1.71c.39.39.39 1.02 0 1.41z"
        />
      </svg>
    </>
  );
};
export default CalendarSvg;
