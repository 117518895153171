import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { Collapse, Nav } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { Route } from 'sitemap';
import { capitalize } from 'helpers/utils';
import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { useNavbarVerticalCollapse } from './NavbarVerticalCollapseProvider';
import Badge from 'components/base/Badge';
import { useAppContext } from 'providers/AppProvider';
import { hasUserAccessFunctionality } from 'helpers/PermissionHelper';
import { checkActiveRoute, placeParamsToURL } from 'helpers/CommonHelper';
import { connect } from 'react-redux';
import { userCurrentServiceSelector } from 'components/_trackivo/selectors/authSelectors';
import { RootState } from 'store';
import { mainRoutes } from 'config/routing';

interface NavbarVerticalMenuProps {
  routes: Route[];
  level: number;
  activeServiceSlug?: string;
}

interface NavItemProps {
  route: Route;
  level: number;
  activeServiceSlug?: any;
}

const NavItem = ({ route, level, activeServiceSlug }: NavItemProps) => {
  const {
    config: { isNavbarVerticalCollapsed }
  } = useAppContext();
  const { setOpenItems, openItems } = useNavbarVerticalCollapse();
  // check active routes by removing service slug prefix
  const location = useLocation();
  const activeServiceSlugName = activeServiceSlug ? activeServiceSlug.slug : '';
  const currentPath = location.pathname;
  const navLinkroute: any = route.path;
  // const currentPath = location.pathname.replace(
  //   `/${activeServiceSlugName}/`,
  //   ''
  // );
  // const navLinkroute: any = route.path
  //   ? route.path.replace(`/${activeServiceSlugName}/`, ''): '';
  const isActiveRoute = checkActiveRoute(
    currentPath,
    [navLinkroute],
    route.matchExactRoute ? route.matchExactRoute : false
  );
  // if (
  //   navLinkroute == '/hrms/attendance-report' ||
  //   navLinkroute == '/hrms/attendance'
  // ) {
  //   console.log(
  //     currentPath,
  //     '.............',
  //     navLinkroute,
  //     '//////............',
  //     isActiveRoute
  //   );
  // }
  const highlightActiveRoute = (isActive: boolean, navPath: any) => {
    if (navPath == placeParamsToURL(mainRoutes.dashboard.path)) {
      return (
        isActive && currentPath == placeParamsToURL(mainRoutes.dashboard.path)
      );
    } else {
      return (
        isActive && currentPath != placeParamsToURL(mainRoutes.dashboard.path)
      );
    }
    return isActive;
  };
  return (
    <Nav.Item as="li">
      <NavLink
        to={route.path ? placeParamsToURL(route.path) : '#!'}
        className={({ isActive }) =>
          classNames('nav-link', {
            'label-1': level === 1,
            active: highlightActiveRoute(
              isActive,
              route.path ? placeParamsToURL(route.path) : ''
            )
          })
        }
        /* end
        className={classNames('nav-link', {
          'label-1': level === 1,
          active: isActiveRoute && route.path !== '#!'
        })} */
        onClick={() => level === 1 && setOpenItems(openItems.map(() => ''))}
      >
        <div
          className={classNames('d-flex align-items-center', {
            'text-300': !route.active
          })}
        >
          {route.icon || route.svgIcon ? (
            <>
              <span
                className={classNames('nav-link-icon', {
                  new: route.new || route.hasNew
                })}
              >
                {route.icon ? (
                  <>
                    {route.iconSet === 'font-awesome' ? (
                      <FontAwesomeIcon
                        icon={route.icon as IconProp}
                        transform={{ size: 16 }}
                      />
                    ) : (
                      <FeatherIcon icon={route.icon} size={16} />
                    )}
                  </>
                ) : (
                  <>
                    <span className="inactive-icon">{route.svgIcon}</span>
                    {route.activeSvgIcon && (
                      <span className="active-icon">{route.activeSvgIcon}</span>
                    )}
                  </>
                )}
              </span>
              <span className="nav-link-text-wrapper">
                {/* <span className="nav-link-text">{capitalize(route.name)}</span> */}
                <span className="nav-link-text">{route.label}</span>
                {route.new && !isNavbarVerticalCollapsed && (
                  <Badge variant="phoenix" bg="info" className="ms-2">
                    New
                  </Badge>
                )}
              </span>
            </>
          ) : (
            <>
              <span className="nav-link-text">{capitalize(route.label)}</span>
              {route.new && (
                <Badge variant="phoenix" bg="info" className="ms-2">
                  New
                </Badge>
              )}
            </>
          )}
        </div>
      </NavLink>
    </Nav.Item>
  );
};

const CollapsableNavItem = ({ route, level }: NavItemProps) => {
  const { pathname } = useLocation();
  const { setOpenItems, openItems } = useNavbarVerticalCollapse();
  const {
    config: { isNavbarVerticalCollapsed }
  } = useAppContext();

  const openCollapse = (childrens: Route[] = []) => {
    const checkLink = (children: Route) => {
      if (`${children.path}` === pathname) {
        return true;
      }
      return children.pages && children.pages.some(checkLink);
    };
    return childrens.some(checkLink);
  };

  const updateOpenItems = (name: string) => {
    const updatedOpenItems = [...openItems];
    updatedOpenItems[level] = name;
    updatedOpenItems.forEach((item, index) => {
      if (index > level) {
        updatedOpenItems[index] = '';
      }
    });
    setOpenItems(updatedOpenItems);
  };

  useEffect(() => {
    if (openCollapse(route.pages)) {
      updateOpenItems(route.name);
    }
  }, []);

  return (
    <>
      <Nav.Link
        onClick={() => {
          if (route.name === openItems[level]) {
            updateOpenItems('');
          } else {
            updateOpenItems(route.name);
          }
        }}
        className={classNames('dropdown-indicator', {
          'label-1': level === 1,
          collapsed: openItems[level] !== route.name,
          'text-300': !route.active
        })}
        aria-expanded={openItems[level] === route.name}
      >
        <div className="d-flex align-items-center">
          <div className="dropdown-indicator-icon">
            <FontAwesomeIcon
              icon={faCaretRight}
              className={classNames({
                'text-300': !route.active
              })}
            />
          </div>
          {level === 1 && (
            <span
              className={classNames('nav-link-icon', {
                new: route.new || route.hasNew
              })}
            >
              {/* <FeatherIcon icon={route.icon} size={16} /> */}
              {route.icon ? (
                <>
                  <FeatherIcon icon={route.icon} size={16} />
                </>
              ) : (
                <>
                  <span className="inactive-icon">{route.svgIcon}</span>
                  {route.activeSvgIcon && (
                    <span className="active-icon">{route.activeSvgIcon}</span>
                  )}
                </>
              )}
            </span>
          )}
          <span
            className={classNames('nav-link-text', {
              new: route.hasNew
            })}
          >
            {capitalize(route.name)}
            {(!isNavbarVerticalCollapsed || level !== 1) && route.new && (
              <Badge variant="phoenix" bg="info" className="ms-2">
                New
              </Badge>
            )}
          </span>
        </div>
      </Nav.Link>
      <div
        className={classNames('parent-wrapper', {
          'label-1': level === 1
        })}
      >
        <Collapse in={openItems[level] === route.name} className="nav parent">
          <div>
            {level === 1 && (
              <div className="collapsed-nav-item-title d-none">
                {capitalize(route.name)}
                {isNavbarVerticalCollapsed && route.new && (
                  <Badge variant="phoenix" bg="info" className="ms-2">
                    New
                  </Badge>
                )}
              </div>
            )}
            <NavbarVerticalMenu routes={route.pages || []} level={level + 1} />
          </div>
        </Collapse>
      </div>
    </>
  );
};

const NavbarVerticalMenu = ({
  routes,
  level,
  activeServiceSlug
}: NavbarVerticalMenuProps) => {
  return (
    <>
      {routes.map(route => (
        <div key={route.name}>
          {hasUserAccessFunctionality(route.permission) && (
            <>
              {level === 1 ? (
                <div className="nav-item-wrapper">
                  {route.pages ? (
                    <CollapsableNavItem route={route} level={level} />
                  ) : (
                    <NavItem
                      route={route}
                      level={level}
                      activeServiceSlug={activeServiceSlug}
                    />
                  )}
                </div>
              ) : (
                <>
                  {route.pages ? (
                    <CollapsableNavItem route={route} level={level} />
                  ) : (
                    <NavItem
                      route={route}
                      level={level}
                      activeServiceSlug={activeServiceSlug}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      ))}
    </>
  );
};
const mapStateToProps = (state: RootState) => {
  return {
    activeServiceSlug: userCurrentServiceSelector(state)
  };
};
export default connect(mapStateToProps, {})(NavbarVerticalMenu);
// export default NavbarVerticalMenu;
