import React from 'react';

const MeetingSvg = ({
  height = 20,
  width = 20,
  viewBox = '0 0 512 512'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <circle cx="256" cy="51.2" r="51.2" />
        </g>
      </g>
      <g>
        <g>
          <circle cx="51.2" cy="102.4" r="51.2" />
        </g>
      </g>
      <g>
        <g>
          <path d="M281.6,128h-51.2c-28.16,0-51.2,23.04-51.2,51.2v51.2h153.6v-51.2C332.8,151.04,309.76,128,281.6,128z" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M153.6,332.8h-51.2V230.4c0-28.16-23.04-51.2-51.2-51.2C23.04,179.2,0,202.24,0,230.4v128c0,43.52,33.28,76.8,76.8,76.8
			H128V512h102.4V409.6C230.4,366.08,197.12,332.8,153.6,332.8z"
          />
        </g>
      </g>
      <g>
        <g>
          <circle cx="460.8" cy="102.4" r="51.2" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M460.8,179.2c-28.16,0-51.2,23.04-51.2,51.2v102.4h-51.2c-43.52,0-76.8,33.28-76.8,76.8V512H384v-76.8h51.2
			c43.52,0,76.8-33.28,76.8-76.8v-128C512,202.24,488.96,179.2,460.8,179.2z"
          />
        </g>
      </g>
      <g>
        <g>
          <rect x="128" y="256" width="256" height="51.2" />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
export default MeetingSvg;
