export default {
  app_name: 'Trackivo',
  confirm_title: 'Are you sure?',
  confirm_title_key_msg: 'Are you sure you want to {{key}}?',
  delete_confirm_msg: 'Are you sure you want to delete?',
  yes: 'Yes',
  no: 'No',
  something_went_wrong: 'Something went wrong',
  reload: 'Reload',
  page_load_error_msg: 'An error occurred. Please reload page and try again',
  logout_success_msg: 'You have logged out successfully',
  min_column_allow_list_error_msg:
    'At least {{ count }} columns must be set to allow list',
  max_column_allow_list_error_msg:
    'Maximum {{ count }} columns can be set to allow list',
  min_column_filter_list_error_msg:
    'At least {{ count }} columns must be set to filter list',
  max_column_filter_list_error_msg:
    'Maximum {{ count }} columns can be set to filter list',
  submit: 'Submit',
  login: 'Login',
  signin: 'Sign In',
  signin_page_title: 'Welcome!',
  signin_page_description:
    'Please sign in to your account or create a new one to continue.',
  signout: 'Sign Out',
  go_back: 'Go Back',
  page_not_found: 'Page Not Found',
  'Loading...': 'Loading...',
  edit: 'Edit',
  delete: 'Delete',
  list: 'List',
  filter: 'Filter',
  reset: 'Reset',
  apply: 'Apply',
  resend: 'Resend',
  confirm: 'Confirm',
  cancel: 'Cancel',
  proceed: 'Proceed',
  personal_email_address: 'Personal Email Address',
  email_address: 'Email Address',
  alternative_email_address: 'Alternative Email Address',
  alt_email: 'Alternative Email Address',
  blood_group: 'Blood Group',
  gender: 'Gender',
  birth_date: 'Birthdate',
  mobile_number: 'Mobile Number',
  alternative_mobile_number: 'Alternative Mobile Number',
  alt_mobile: 'Alternative Mobile Number',
  alt_mobile_code: 'Alternative Mobile Code',
  phone_number: 'Phone Number',
  mobile_code: 'Mobile Code',
  password: 'Password',
  confirm_password: 'Confirm Password',
  new_password: 'New Password',
  add_success: 'Added Successfully',
  update_success: 'Updated Successfully',
  delete_success: 'Deleted Successfully',
  checkin_success: 'Checkin Successfully',
  checkout_success: 'Checkout Successfully',
  submit_evaluation_successfull_message: 'Evaluation Submitted Successfully',
  add_user_success: 'User added successfully',
  update_user_success: 'User updated successfully',
  password_reset_success: 'Password reset successfully',
  update_profile_success: 'Profile details updated successfully',
  notify_otp_success: 'OTP sent successfully',
  otp_verify_success: 'OTP is verified',
  required_error_msg: '{{field_name}} is required',
  added_successfull_message: '{{key}} Added Successfully',
  deleted_successfull_message: '{{key}} Deleted Successfully',
  imported_successfull_message: '{{key}} Imported Successfully',
  remove_successfull_message: '{{key}} Removed Successfully',
  edit_successfull_message: '{{key}} Updated Successfully',
  greater_equal_error_msg:
    '{{field_name1}} should be greater than or equal to the {{field_name2}}',
  less_equal_error_msg:
    '{{field_name1}} should be less than or equal to the {{field_name2}}',
  invalid_error_msg: '{{field_name}} is invalid',
  url: 'URL',
  forgot_password: 'Forgot Password',
  'forgot_password?': 'Forgot Password ?',
  forgot_password_instruction_msg:
    "Enter the email address associated with your account below, and we'll send you a link to reset your password.",
  back_to_login: 'Back to Login',
  logout_success: 'You have logged out successfully',
  confirm_logout_msg: 'Are you sure you want to sign out?',
  confirm_disconnect_msg: 'Are you sure you want to disconnect {{key}}?',
  confirm_remove_bookmark_msg: 'Are you sure you want remove bookmark?',
  confirm_delete_attachment_msg: 'Are you sure you want delete attachment?',
  confirm_delete_record_msg: 'Are you sure you want delete {{ key }}?',
  confirm_import_candidate_msg: 'Are you sure you want import this candidate?',
  confirm_unassociate_candidate_msg:
    'Are you sure you want unassociate candidate?',
  unassociate_candidate_successfull_message:
    'Candidate unassociated successfully',
  logout: 'Logout',
  my_profile: 'My profile',
  verify_phone_number: 'Verify Phone Number',
  verify_mobile_msg:
    'Enter the OTP that we sent to <strong>{{mobile}}</strong>',
  password_not_match: 'Password does not match',
  otp_interval: '{{seconds}} sec',
  otp: 'OTP',
  verify_email: 'Verify Email',
  verify_email_instruction_msg: 'Enter OTP',
  reset_password: 'Create New Password',
  reset_password_instruction_msg:
    'Please create a new password to reset your account credentials.',
  create_password_instruction_msg:
    'Please create a new account password to sign into your account.',
  add: 'Add',
  send: 'Send',
  mobile: 'Mobile',
  dashboard: 'Dashboard',
  general: 'General',
  must_attend: 'Must Attend',
  report: 'Report',
  birthday: 'Birthday',
  meeting: 'Meeting',
  business: 'Business',
  field_master: 'Field Master',
  manage_field: 'Manage Field',
  manage_order: 'Manage Order',
  verify: 'Verify',
  verify_instruction_title: 'Enter the verification code',
  MFA_verify_instruction_title:
    'Enter the MFA code to continue and be redirected.',
  verify_email_msg:
    'An email containing a 6-digit verification code has been sent to the email address - {{email}}',
  profile_page_title: 'Profile',
  profile_page_description: 'Update your profile information',
  home: 'Home',
  profile: 'Profile',
  company_page_title: 'Company',
  company_page_description: 'Manage Company Details',
  company: 'Company',
  add_company: 'Add Company',
  edit_company: 'Edit Company',
  department_page_title: 'Department',
  department_page_description: 'Manage Department Details',
  department: 'Department',
  add_department: 'Add Department',
  edit_department: 'Edit Department',
  role_page_title: 'Role',
  role_page_description: 'Manage Role Details',
  role: 'Role',
  add_role: 'Add Role',
  edit_role: 'Edit Role',
  //group
  group_page_title: 'Group',
  group_page_description: 'Manage Group Details',
  group: 'Group',
  add_group: 'Add Group',
  edit_group: 'Edit Group',
  select_employee: 'Select Employee',
  select_group: 'Select Group',
  no_employee_selected: 'No Employee selected',
  no_group_selected: 'No Group selected',
  search_field_name: 'Search {{field_name}}',
  user: 'User',
  users: 'Users',
  branch_page_title: 'Branch',
  branch_page_description: 'Manage Branch Details',
  add_branch: 'Add Branch',
  edit_branch: 'Edit Branch',
  branch: 'Branch',
  update: 'Update',
  create: 'Create',
  general_information: 'General Information',
  basic_information: 'Basic Information',
  settings: 'Settings',
  notification_settings: 'Notification Settings',
  email_notifications: 'Email Notifications',
  sms_notifications: 'Sms Notifications',
  push_notifications: 'Push Notifications',
  push: 'Push',
  security_settings: 'Security Settings',
  timezone_settings: 'Timezone Settings',
  timezone: 'Timezone',
  two_factor_authentication: 'Two Factor Authentication',
  transactional: 'Transactional',
  promotional: 'Promotional',
  first_name: 'First Name',
  last_name: 'Last Name',
  select: 'Select',
  company_name: 'Company Name',
  user_info: 'User Info.',
  company_info: 'Company Info.',
  industry: 'Industry',
  company_information: 'Company Information',
  company_admin_information: 'Company Admin Information',
  address_information: 'Address Information',
  no_data_exist: 'No Data Exist',
  search: 'Search',
  name: 'Name',
  status: 'Status',
  head: 'Head',
  address: 'Address',
  country: 'Country',
  state: 'State',
  city: 'City',
  currency: 'Currency',
  pincode: 'Pincode',
  zipcode: 'Postal / Zip code',
  permanent_add1: 'Permanent Address 1',
  permanent_add2: 'Permanent Address 2',
  permanent_pincode: 'Permanent Address Pincode',
  permanent_country: 'Permanent Address Country',
  permanent_state: 'Permanent Address State',
  permanent_city: 'Permanent Address City',
  current_add1: 'Current Address 1',
  current_add2: 'Current Address 2',
  current_pincode: 'Current Address Pincode',
  current_country: 'Current Address Country',
  current_state: 'Current Address State',
  current_city: 'Current Address City',
  address_line_1: 'Address Line 1',
  address_line_2: 'Address Line 2',
  permanent_address: 'Permanent Address',
  permanent_address_instruction: 'Enter permanent address as gov id proof',
  billing_address_instruction: 'Enter billing address',
  billing_address: 'Billing Address',
  shipping_address: 'Shipping Address',
  current_address: 'Current Address',
  same_as_permanent: 'Same As Permanent',
  same_as_billing: 'Same As Billing',
  employee: 'Employee',
  employee_page_title: 'Employee',
  employee_page_description: 'Manage Employee Details',
  add_employee: 'Add Employee',
  edit_employee: 'Edit Employee',
  personal: 'Personal',
  basic: 'Basic',
  contact: 'Contact',
  professional: 'Professional',
  education: 'Education',
  educational: 'Educational',
  experience: 'Experience',
  total_experience: 'Total Experience',
  total_years_of_experience: 'Total Years of Experience',
  guardian_name: 'Guardian Name',
  guardian_details: 'Guardian Details',
  emergency_contact_number: 'Emergency Contact No.',
  relationship_with_guardian: 'Relationship With Guardian',
  current_job_title: 'Current Job Title',
  desired_job_title: 'Desired Job Title',
  current_ctc: 'Current CTC',
  expected_ctc: 'Expected CTC',
  shift_time: 'Shift Time',
  experience_in_years: 'Experience in Years',
  user_role: 'User Role',
  current_employer: 'Current Employer (Branch)',
  current_salary: 'Current CTC',
  expected_salary: 'Expected CTC',
  current_salary_currency: 'Current CTC Currency',
  current_salary_type: 'Current CTC Type',
  expected_salary_type: 'Expected CTC Type',
  expected_salary_currency: 'Expected CTC Currency',
  desired_work_location: 'Desired Work Location',
  current_work_location: 'Current Work Location',
  skill_set: 'Skill Set',
  leave_type: 'Leave Type',
  current_employee: 'Current Employee',
  institute: 'Institute',
  institute_school: 'Institute/School',
  degree: 'Degree',
  location: 'Location',
  distance: 'Distance',
  UOM: 'UOM',
  description: 'Description',
  current_pursuing: 'Current Pursuing',
  summary: 'Summery',
  duration_from: 'Duration: From',
  job_title: 'Job Title',
  joining_date: 'Joining Date',
  add_leave_type: 'Add Leave Type',
  leave_type_page_title: 'Leave Type',
  leave_type_page_description: 'Manage Leave type Details',
  edit_leave_type: 'Edit Leave Type',
  job_title_page_title: 'Job Title',
  job_title_page_description: 'Manage Job Title Details',
  add_job_title: 'Add Job Title',
  edit_job_title: 'Edit Job Title',
  pay_type: 'Pay Type',
  pay_type_page_title: ' Pay Type',
  pay_type_page_description: 'Manage Pay type Details',
  add_pay_type: 'Add Pay Type',
  edit_pay_type: 'Edit Pay Type',
  skill: 'Skill',
  skills: 'Skills',
  skill_page_title: 'Skill',
  skill_page_description: 'Manage Skill Details',
  add_skill: 'Add Skill',
  edit_skill: 'Edit Skill',
  previous: 'Previous',
  next: 'Next',
  shift: 'Shift',
  shift_page_title: 'Shift',
  shift_page_description: 'Manage Shift Details',
  add_shift: 'Add Shift',
  edit_shift: 'Edit Shift',
  shift_start_time: 'Shift Start Time',
  shift_end_time: 'Shift End Time',
  start_time: 'Start Time',
  end_time: 'End Time',
  start_date: 'Start Date',
  end_date: 'End Date',
  employee_type: 'Employee Type',
  employee_type_page_title: 'Employee Type',
  employee_type_page_description: 'Manage Employee Type Details',
  edit_employee_type: 'Edit Employee Type',
  add_employee_type: 'Add Employee Type',
  add_employee_type_message: 'Employee Type Added Successfully',
  update_employee_type_message: 'Employee Type Update Successfully',
  interview: 'Interview',
  add_interview: 'Add Interview',
  edit_interview: 'Edit Interview',
  interview_page_title: 'Interview',
  interview_page_description: 'Manage Interview Details',
  telephonic_interview: 'Telephonic Interview',
  video_interview: 'Video Interview',
  formal_interview: 'Formal Interview',
  candidate_name: 'Candidate Name',
  candidate_contact_no: 'Candidate Contact No',
  client_name: 'Client Name',
  interview_owner: 'Interview Owner',
  interview_date: 'Interview Date',
  interview_time: 'Interview Time',
  interview_duration: 'Interview Duration',
  remarks_comment: 'Remarks/Comment',
  interviewers: 'Interviewers',
  candidate: 'Candidate',
  add_candidate: 'Add Candidate',
  edit_candidate: 'Edit Candidate',
  candidate_page_title: 'Candidate',
  candidate_page_description: 'Manage Candidate Details',
  candidate_detail: 'Candidate Detail',
  candidate_detail_page_title: 'Candidate Detail',
  candidate_detail_page_description: 'Manage Candidate Details',
  search_candidate: 'Search Candidate',
  saved_searched: 'Save Searched',
  interaction_history: 'Interaction History',
  create_candidate: 'Create Candidate',
  select_role_title: 'Select Your Role',
  switch_role: 'Switch Role',
  add_manually: 'Add Manually',
  highest_qualification_held: 'Highest Qualification Held',
  upload_resume: 'Upload Resume',
  upload_card: 'Upload Card',
  account: 'Account',
  candidate_status: 'Candidate Status',
  candidate_stage: 'Candidate Stage',
  candidate_status_page_title: 'Candidate Stage',
  candidate_status_page_description: 'Manage Candidate Stage Details',
  add_candidate_status: 'Add Candidate Status',
  edit_candidate_status: 'Edit Candidate Status',
  email_opt_out: 'Email Opt Out',
  email_opt: 'Email Opt Out',
  client: 'Client',
  add_client: 'Add Client',
  edit_client: 'Edit Client',
  client_page_title: 'Client',
  client_page_description: 'Manage Client Details',
  attachment: 'Attachment',
  industry_name: 'Industry Name',
  website: 'Website',
  parent_client: 'Parent Client',
  interview_level: 'Interview Level',
  interview_level_page_title: 'Interview Level',
  interview_level_page_description: 'Manage Client Details',
  add_interview_level: 'Add Interview Level',
  client_info: 'Client Info',
  account_manager: 'Account Manager',
  timestamp: 'Timestamp',
  contact_person: 'Contact Person',
  contact_person_mobile: 'Contact Person Mobile',
  provider: 'Provider',
  meeting_link: 'Meeting Link',
  interviewer_contact_no: 'Interviewer Contact No.',
  google_meet: 'Google Meet',
  zoom: 'Zoom',
  minutes: 'Minutes',
  created_by: 'Created By',
  by: 'by',
  edit_interview_level: 'Edit Interview Level',
  name_validation_error: 'Enter Only Character',
  time: 'Time',
  type: 'Type',
  ATS: 'ATS',
  CRM: 'CRM',
  visa_type: 'Visa Type',
  visa_type_page_title: 'Visa Type',
  visa_type_page_description: 'Manage Visa Details',
  add_visa_type: 'Add Visa Type',
  edit_visa_type: 'Edit Visa Type',
  document_type: 'Document Type',
  document_type_page_title: 'Document Type',
  document_type_page_description: 'Manage Document Details',
  add_document_type: 'Add Document Type',
  edit_document_type: 'Edit Document Type',
  module: 'Module',
  manage_status: 'Manage Status',
  submit_to_client: 'Submit To Client',
  submit_candidate: 'Submit Candidate',
  submitted_candidate: 'Submitted Candidate',
  submitted_candidate_page_title: 'Submitted Candidate',
  submitted_candidate_page_description: 'Submitted Candidate Details',
  submit_candidate_success: 'Candidate submitted successfully',
  job: 'Job',
  invalid_resume_file: 'Invalid file type. Please upload a Doc or PDF file.',
  resume_drag_instrcution_msg: "Drag 'n' drop a pdf or doc file",
  invalid_card_file:
    'Invalid file type. Please upload a Doc,PDF or Image file.',
  card_drag_instrcution_msg: "Drag 'n' drop a pdf, doc or image file",
  or: 'OR',
  source_setting: 'Source Setting',
  source_setting_page_title: 'Source Setting',
  source_setting_page_description: 'Manage Source Setting Details',
  configure: 'Configure',
  dice: 'Dice',
  export_as: 'Export as {{key}}',
  excel: 'Excel',
  pdf: 'PDF',
  print: 'Print',
  disconnect: 'Disconnect',
  add_configuration: 'Add Configuration',
  edit_configuration: 'Edit Configuration',
  username_email: 'Username/Email',
  client_id: 'Client Id',
  client_secret: 'Client Secret',
  source_booster: 'Source Booster',
  keyword_or_boolean_search: 'Keyword or Boolean Search',
  location_placeholder: 'Location (city, state, zip, time zone)',
  work_exp: 'Work Exp.',
  not_disclosed: 'Not Disclosed',
  years_in_exp: '{{year}} years Exp',
  not_willing_to_relocate: 'Not willing to relocate',
  willing_to_relocate: 'Willing To Relocate',
  preferred_locations: 'Preferred Locations',
  anywhere: 'Anywhere',
  upto_key_perc: 'Upto {{perc}}%',
  exclude_third_party: 'Exclude 3rd Party',
  exclude_founder: 'Exclude Founder',
  current_locations: 'Current Locations',
  relocate: 'Relocate',
  not_salary_disclosed: 'No desired salary disclosed',
  more: 'More',
  less: 'Less',
  predefined_tech: 'Predefined Tech',
  updated: 'Updated',
  yrs: 'Yrs',
  months: 'Months',
  add_bookmark: 'Add Bookmark',
  remove_bookmark: 'Remove Bookmark',
  bookmark: 'Bookmark',
  bookmarked: 'Bookmarked',
  back_to_top: 'Back to Top',
  work_history: 'Work History',
  work_preferences: 'Work Preferences',
  resume: 'Resume',
  action: 'Action',
  candidate_activity: 'Candidate Activity',
  employer_activity: 'Employer Activity',
  save: 'Save',
  notes: 'Notes',
  add_notes: 'Add Notes',
  phone: 'Phone',
  email: 'Email',
  preferred: 'Preferred',
  preferred_location: 'Preferred Location',
  travel_preferences: 'Travel Preferences',
  preference_no_travel: 'Preference No travel',
  desired_hourly_rate: 'Desired Hourly Rate',
  desired_salary: 'Desired Salary',
  security_clearance: 'Security Clearance',
  third_party: 'Third Party',
  desired_work_setting: 'Desired Work Setting',
  up_to_percentage: 'Up to {{ perc }}%',
  compensation: 'Compensation',
  work_permit: 'Work Permit',
  authorized_countries: 'Authorized Countries',
  employement_type: 'Employment Type',
  education_history: 'Education History',
  no_end_date: 'No end date',
  no_resume_exist: 'No Resume Exist',
  no_preferrences: 'No Preferrences',
  prefers_no_travel: 'Prefers No Travel',
  unspecified: 'Unspecified',
  profile_source: 'Profile Source',
  click_here_to_action: 'Click here to {{ action }}',
  copy: 'Copy',
  click_here: 'Click here',
  view_timeline: 'View Timeline',
  candidate_timeline: 'Candidate Timeline',
  candidate_submission_timeline: 'Candidate Submission Timeline',
  interview_timeline: 'Interview Timeline',
  download: 'Download',
  view_in_fullscreen: 'View in Fullscreen',
  note: 'Note',
  has_notes: 'Has Notes',
  viewed: 'Viewed',
  downloaded: 'Downloaded',
  download_resume: 'Download Resume',
  social: 'Social',
  social_profile: 'Social Profile',
  languages: 'Languages',
  job_opening_status: 'Job Opening Status',
  edit_job_opening_status: 'Edit Job Opening Status',
  add_job_opening_status: 'Add Job Opening Status',
  job_opening_status_page_title: 'Job Opening Status',
  job_opening_status_page_description: 'Manage Job Opening Status Details',
  'on-site': 'On-Site',
  remote: 'Remote',
  hybrid: 'Hybrid',
  work_place_type: 'Work Place Type',
  current_work_place_type: 'Current Work Place Type',
  desired_work_place_type: 'Desired Work Place Type',
  job_opening: 'Job Opening',
  job_openings: 'Job Openings',
  job_opening_page_title: 'Job Opening',
  job_opening_page_description: 'Manage Job Opening Details',
  job_opening_timeline: 'Job Opening Timeline',
  title: 'Title',
  post_a_job: 'Post a Job',
  write_with_AI: 'Write with AI',
  write_on_my_own: 'Write on my own',
  add_job_opening: 'Add Job Opening',
  edit_job_opening: 'Edit Job Opening',
  date: 'Date',
  remarks: 'Remarks',
  calendar: 'Calendar',
  calendar_page_title: 'Calendar',
  calendar_page_description: 'Manage Calendar Details',
  sync_now: 'Sync Now',
  add_new_task: 'Add new task',
  interview_type: 'Interview Type',
  date_range: 'Date Range',
  date_range_input_placeholder: 'Select Date Range',
  details: 'Details',
  target_date: 'Target Date',
  source_booster_remaining_view_msg:
    '{{ viewsRemaining }} views are remaining out of {{ totalViews }}',
  event: 'Event',
  task: 'Task',
  label: 'Label',
  start_at: 'Start At',
  end_at: 'End At',
  all_day_event: 'All Day Event',
  guests: 'Guests',
  add_reminder: 'Add Reminder',
  reminder: 'Reminder',
  duration: 'Duration',
  duration_type: 'Duration Type',
  notification: 'Notification',
  import_candidate: 'Import Candidiate',
  guest_numbers: '{{total}} Guests',
  organizer: 'Organizer',
  before: 'Before',
  job_opening_detail: 'Job Opening Detail',
  hiring_pipeline: 'Hiring Pipeline',
  show_details: 'Show Details',
  hide_details: 'Hide Details',
  interviews: 'Interviews',
  client_submission: 'Client Submission',
  join_with_provider: 'Join With {{provider}}',
  join_by_phone: 'Join By Phone',
  interview_status: 'Hiring Pipeline',
  edit_interview_status: 'Edit Interview Status',
  add_interview_status: 'Add Interview Status',
  interview_status_page_title: 'Hiring Pipeline',
  interview_status_page_description: 'Manage Hiring Pipeline Details',
  attachment_category: 'Attachment Category',
  edit_attachment_category: 'Edit Attachment Category',
  add_attachment_category: 'Add Attachment Category',
  attachment_category_page_title: 'Attachment Category',
  attachment_category_page_description: 'Manage Attachment Category Details',
  applicant_info: 'Applicant Info',
  job_position: 'Job Position',
  applicant_name: 'Applicant Name',
  applicant_email: 'Applicant Email',
  today: 'Today',
  month: 'Month',
  week: 'Week',
  day: 'Day',
  days: 'Days',
  year: 'Year',
  'loading...': 'Loading...',
  close: 'Close',
  add_attachment: 'Add Attachment',
  unassociate_candidate: 'Unassociate Client',
  view_details: 'View Details',
  view_key_details: 'View {{ key }} Details',
  view: 'View',
  attachments: 'Attachment',
  category: 'Category',
  size: 'Size',
  date_created: 'Date Created',
  attached_by: 'Attached By',
  file_name: 'File Name',
  'to-do': 'TO-DO',
  from: 'From',
  to: 'To',
  due_date: 'Due Date',
  subject: 'Subject',
  activity_type: 'Activity Type',
  repeat: 'Repeat',
  daily: 'Daily',
  weekly: 'Weekly',
  hourly: 'Hourly',
  short_hour_text: 'Hrs',
  yearly: 'Yearly',
  yr: 'yr',
  monthly: 'Monthly',
  'bi-weekly': 'Bi-weekly',
  'semi-monthly': 'Semi-monthly',
  immediate: 'Immediate',
  'within-15-days': 'Within 15 Days',
  'within-1-month': 'Within 1 Month',
  'within-2-months': 'within 2 Months',
  'within-3-months': 'within 3 Months',
  'within-6-months': 'within 6 Months',
  quarterly: 'Quarterly',
  annually: 'Annually',
  pending: 'Pending',
  success: 'Success',
  kb: 'KB',
  joining_availability: 'Joining Availability',
  availability: 'Availability',
  edit_category: 'Edit Category',
  log_call: 'Log Call',
  call_log: 'Call Log',
  call_log_page_title: 'Call Log',
  call_log_page_description: 'Manage Call Log Details',
  add_call_log: 'Add Call Log',
  edit_call_log: 'Edit Call Log',
  new_task_event: 'New Task/Event',
  leave_status: 'Leave Status',
  edit_leave_status: 'Edit Leave Status',
  add_leave_status: 'Add Leave Status',
  leave_status_page_title: 'Leave Status',
  leave_status_page_description: 'Manage Leave Status Details',
  leave_tracker: 'Leave Tracker',
  edit_leave: 'Edit Leave',
  add_leave: 'Add Leave',
  leave: 'Leave',
  leave_list: 'Leave List',
  leave_tracker_page_title: 'Leave Tracker',
  leave_tracker_page_description: 'Manage Leave Details',
  log: 'Log',
  added_by: 'Added By',
  attendance: 'Attendance',
  half_day: 'Half Day',
  half_day_type: 'Half Day Type',
  first: 'First',
  second: 'Second',
  reason: 'Reason',
  from_date: 'From Date',
  to_date: 'To Date',
  num_count_days: '{{count}} Day(s)',
  manage_holiday: 'Manage Holiday',
  manage_holiday_page_title: 'Manage Holiday',
  manage_holiday_page_description: 'Manage Holiday Details',
  holiday_page_title: 'Holiday',
  holiday_page_description: 'Manage Holiday Details',
  holiday: 'Holiday',
  holiday_name: 'Holiday Name',
  add_holiday: 'Add Holiday',
  edit_holiday: 'Edit Holiday',
  festival_name: 'Festival Name',
  sr_no: 'Sr No.',
  attendance_page_title: 'Attendance',
  attendance_page_description: 'Manage Attendance Details',
  attendance_report_page_title: 'Attendance Report',
  attendance_report_page_description: 'Manage Attendance Report',
  applications: 'Applications',
  leave_application: 'Leave Application',
  leave_application_page_title: 'Leave Application',
  leave_application_page_description: 'Manage Leave Application Details',
  time_tracker_page_title: 'Leave Application',
  time_tracker_page_description: 'Manage Leave Application Details',
  request_leave: 'Request Leave',
  check_out: 'Check Out',
  check_in: 'Check In',
  active_candidate: 'Active Candidate',
  active_candidate_page_title: 'Active Candidate',
  active_candidate_page_description: 'Mange Active Candidate',
  active_candidate_detail: 'Active Candidate Detail',
  active_candidate_detail_page_title: 'Active Candidate Detail',
  active_candidate_detail_page_description: 'Active Candidate Details',
  reference: 'Reference',
  reference_page_title: 'Reference',
  reference_page_description: 'Manage Reference',
  add_reference: 'Add Reference',
  edit_reference: 'Edit Reference',
  job_duration: 'Job Duration',
  job_duration_type: 'Job Duration Type',
  color_code: 'Color Code',
  advance_filter: 'Advance Filter',
  all: 'All',
  customize_pipeline: 'Customize Pipeline',
  candidate_stages: 'Candidate Stages',
  manage_stages: 'Manage Stages',
  stages: 'Stages',
  customize_stages: 'Customize Stages',
  add_stage: 'Add Stage',
  edit_stage: 'Edit Stage',
  stage: 'Stage',
  remove: 'Remove',
  parent: 'Parent',
  expand: 'Expand',
  collapse: 'Collapse',
  add_note: 'Add Note',
  edit_note: 'Edit Note',
  select_reference: 'Select Reference',
  search_reference: 'Search Reference',
  current_job_type: 'Current Job Type',
  preferred_job_type: 'Preferred Job Type',
  work_abroad: 'Work Abroad',
  updated_from: 'updated from',
  Updated_from: 'Updated from',
  Updated_to: 'Updated to',
  changed_status: 'Changed Status',
  candidate_updated: 'Candidate Updated',
  candidate_created: 'Candidate Created',
  submission_created: 'Submission Created',
  job_opening_updated: 'Job Opening Updated',
  job_opening_created: 'Job Opening Created',
  interview_updated: 'Interview Updated',
  interview_created: 'Interview Created',
  interview_rescheduled: 'Rescheduled Interview',
  added_note: 'Added Note',
  imported_from: 'Imported from',
  currently_working: 'Currently Working',
  present: 'Present',
  blank_value: 'Blank Value',
  male: 'Male',
  female: 'Female',
  false: 'False',
  true: 'True',
  linkedin_url: 'Linkedin URL',
  facebook_url: 'Facebook URL',
  twitter_url: 'Twitter URL',
  configure_service: 'Configure Service',
  handling_allocated_self_data: 'Handling Allocated / Self Data',
  permission: 'Permission',
  service: 'Service',
  configured_successfull_message: '{{key}} Configured Successfully',
  copied_success: 'Copied Successfully',
  check_all: 'Check All',
  uncheck_all: 'Uncheck All',
  submit_evaluation: 'Submit Evaluation',
  rating: 'Rating',
  review_information: 'Review Information',
  interview_status_text: 'Interview Status',
  application_status: 'Application Status',
  change_application_status_msg_title: "Also Change Application's Status?",
  change_application_status_msg_instruction: 'Marking this application as',
  reviewed_by: 'Reviewed By',
  reviewed_on: 'Reviewed On',
  ats_workflow: 'ATS Workflow',
  ats_workflow_page_title: 'ATS Workflow',
  ats_workflow_page_description: 'Manage ATS Workflow',
  welcome_back: 'Welcome Back',
  dashboard_page_welcome_description:
    'Enjoy potential Candidate tracking, Management & Forecast',
  is_verified: 'Is Verified',
  verified_date: 'Verified Date',
  verified_on: 'Verified On',
  interview_detail: 'Interview Detail',
  quick_history: 'Quick History',
  last_num_days: 'Last {{ days }} Days',
  last_year: 'Last Year',
  custom: 'Custom',
  clear: 'Clear',
  candidates: 'Candidates',
  application_over_time: 'Applications Over Time',
  candidate_history: 'Candidate History',
  rate_meter: 'Rate Meter',
  current_company: 'Current Company',
  last_active_dice: 'Last active on Dice',
  has_phone: 'Has Phone',
  likely_to_switch: 'Likely to Switch',
  contact_method: 'Contact Method',
  last_active_on_key: 'Last active on {{key}}',
  companies: 'Companies',
  any: 'Any',
  configure_MFA: 'Configure MFA',
  remove_MFA: 'Remove MFA',
  MFA_enable_success_msg: 'MFA Enabled Successfully',
  MFA_remove_success_msg: 'MFA Removed Successfully',
  resume_last_updated: 'Resume Last Updated',
  last_updated: 'Last Updated',
  has_security_clearance: 'Has Security Clearance',
  clear_all: 'Clear All',
  save_and_search: 'Save & Search',
  run_search: 'Run Search',
  delete_saved_search: 'Delete Saved Search',
  additional_filter: 'Additional Filter',
  clients: 'Clients',
  table_settings: 'Table Settings',
  column_name: 'Column Name',
  allow_list: 'Allow List',
  allow_filter: 'Allow Filter',
  hide: 'Hide',
  show: 'Show',
  is_public_holiday: 'Is Public Holiday',
  is_optional_holiday: 'Is Optional Holiday',
  optional_holidays: 'Optional Holidays',
  view_map: 'View Map',
  checkIn: 'Check-in',
  checkOut: 'Check-out',
  attendance_comparision_chart: 'Attendance Comparision Chart',
  attendance_report: 'Attendance Report',
  attendance_list: 'Attendance List',
  break_duration: 'Break Duration',
  late_by: 'Late by',
  late_coming: 'Late Coming',
  early_going: 'Early Going',
  no_of_leave_per_year: 'Annual No. Of Leave',
  checkinout_status: 'Check In/Out Status',
  adjust_leave_balance: 'Adjust Leave Balance',
  total_leaves: 'Total Leaves',
  total: 'Total',
  used_leaves: 'Used Leaves',
  remaining_leaves: 'Remaining Leaves',
  weekly_off: 'Weekly Off',
  used_leave: 'Used Leave',
  leave_year: 'Leave Year',
  available: 'Available',
  used: 'Used',
  remaining: 'Remaining',
  service_category: 'Service Category',
  service_category_page_title: 'Service Category',
  service_category_page_description: 'Manage Service Category Details',
  add_service_category: 'Add Service Category',
  edit_service_category: 'Edit Service Category',
  company_service: 'Service',
  company_service_page_title: 'Service',
  company_service_page_description: 'Manage Service Details',
  add_company_service: 'Add Service',
  edit_company_service: 'Edit Service',
  lead_stage: 'Lead Stage',
  lead_stage_page_title: 'Lead Stage',
  lead_stage_page_description: 'Manage Lead Stage Details',
  add_lead_stage: 'Add Lead Stage',
  edit_lead_stage: 'Edit Lead Stage',
  account_page_title: 'Account',
  account_page_description: 'Manage Account Details',
  add_account: 'Add Account',
  edit_account: 'Edit Account',
  source: 'Source',
  source_url: 'Source URL',
  account_no: 'Account No.',
  ticker_symbol: 'Ticker Symbol',
  account_type: 'Account Type',
  ownership: 'Ownership',
  annual_revenue: 'Annual Revenue',
  sic_code: 'SIC Code',
  linkedIn: 'LinkedIn',
  zoomInfo: 'ZoomInfo',
  other: 'Other',
  private: 'Private',
  public: 'Public',
  subsidiary: 'Subsidiary',
  lead: 'Lead',
  lead_page_title: 'Lead',
  lead_page_description: 'Manage Lead Details',
  add_lead: 'Add Lead',
  edit_lead: 'Edit Lead',
  lead_development: 'Development',
  lead_development_page_title: 'Development',
  lead_development_page_description: 'Manage Development Details',
  lead_detail: 'Lead Detail',
  lead_detail_page_title: 'Lead Detail',
  lead_detail_page_description: 'Manage Lead Details',
  lead_timeline: 'Lead timeline',
  lead_updated: 'Lead Updated',
  lead_created: 'Lead Created',
  meeting_page_title: 'Meeting',
  meeting_page_description: 'Manage Meeting Details',
  related_to: 'Related To',
  add_event: 'Add Event',
  additional_fields: 'Additional Fields',
  task_page_title: 'Task',
  task_page_description: 'Manage Task Details',
  priority: 'Priority',
  high: 'High',
  low: 'Low',
  normal: 'Normal',
  not_started: 'Not Started',
  in_progress: 'In Progress',
  completed: 'Completed'
};
