import React from 'react';

const LeadSvg = ({
  height = 21,
  width = 21,
  viewBox = '0 0 66 66'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="currentColor"
    >
      <g>
        <g>
          <circle cx="14.5" cy="46.3" r="6" />
        </g>
        <g>
          <path d="m17.8 38.6c-.4-.4-.4-1 0-1.4.2-.2.4-.3.7-.3s.5.1.7.3.3.5.3.7c0 .3-.1.5-.3.7s-.5.3-.7.3c-.3 0-.5-.1-.7-.3z" />
        </g>
        <g>
          <path d="m17.6 64.5c0 .4.1.7.2 1h-14.2c-.5 0-1-.4-1-1v-2.6c0-5 4.1-9.1 9.1-9.1.4 0 .8.2.9.5l1.9 3.8 1.9-3.8c.2-.3.5-.5.9-.5 1.2 0 2.3.2 3.3.6-1.9 2.1-3.1 4.9-3.1 8v3.1z" />
        </g>
        <g>
          <path d="m20.6 34.8c.3 0 .5.1.7.3s.3.5.3.7c0 .3-.1.5-.3.7s-.4.3-.7.3-.5-.1-.7-.3c-.4-.4-.4-1 0-1.4.2-.2.5-.3.7-.3z" />
        </g>
        <g>
          <path d="m22.8 32.7c.3 0 .5.1.7.3s.3.4.3.7-.1.5-.3.7-.5.3-.7.3c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4.1-.2.4-.3.7-.3z" />
        </g>
        <g>
          <path d="m24.9 30.6c.3 0 .5.1.7.3s.3.4.3.7-.1.5-.3.7-.5.3-.7.3c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4.2-.2.4-.3.7-.3z" />
        </g>
        <g>
          <path d="m39.1 44.3c0 3.6-2.9 6.5-6.5 6.5s-6.5-2.9-6.5-6.5 2.9-6.5 6.5-6.5 6.5 3 6.5 6.5z" />
        </g>
        <g>
          <path d="m40.4 30.6c.3 0 .5.1.7.3s.3.5.3.7c0 .3-.1.5-.3.7s-.5.3-.7.3c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4.2-.2.4-.3.7-.3z" />
        </g>
        <g>
          <path d="m43.2 33c.2.2.3.5.3.7 0 .3-.1.5-.3.7s-.5.3-.7.3c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4.2-.2.5-.3.7-.3.3 0 .5.1.7.3z" />
        </g>
        <g>
          <path d="m45.4 35.1c.2.2.3.5.3.7 0 .3-.1.5-.3.7s-.5.3-.7.3c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4.2-.2.5-.3.7-.3s.5.1.7.3z" />
        </g>
        <g>
          <path d="m45.7 61.4v3.1c0 .6-.4 1-1 1h-24.1c-.6 0-1-.4-1-1v-3.1c0-5.4 4.4-9.8 9.9-9.8.4 0 .8.2 1 .5l2.2 4.3 2.2-4.3c.2-.3.5-.5.9-.5 5.5 0 9.9 4.4 9.9 9.8z" />
        </g>
        <g>
          <path d="m46.8 36.9c.3 0 .5.1.7.3s.3.5.3.7c0 .3-.1.5-.3.7s-.4.3-.7.3-.5-.1-.7-.3c-.4-.4-.4-1 0-1.4.2-.2.4-.3.7-.3z" />
        </g>
        <g>
          <path d="m18 19c-.6-5.1.9-10.1 4.1-13.7 2.8-3.1 6.5-4.8 10.5-4.8s7.8 1.7 10.5 4.8c3.2 3.6 4.7 8.6 4.1 13.7-.3 2.9-1.4 6.4-3 9.6-.2.3-.5.6-.9.6h-4.2c-.3 0-.7-.2-.9-.5s-.2-.7 0-1c1.7-3 3-6.6 3.3-9.3.4-3.4-.6-6.9-2.7-9.3-1.7-1.9-3.9-2.9-6.3-2.9s-4.6 1-6.3 2.9c-2.1 2.4-3.1 5.8-2.7 9.3.3 2.7 1.6 6.4 3.3 9.3.2.3.2.7 0 1s-.5.5-.9.5h-4.2c-.4 0-.7-.2-.9-.6-1.4-3.2-2.5-6.7-2.8-9.6z" />
        </g>
        <g>
          <circle cx="51.5" cy="46.3" r="6" />
        </g>
        <g>
          <path d="m63.4 61.9v2.6c0 .6-.4 1-1 1h-14.9c.1-.3.2-.6.2-1v-3.1c0-3-1.1-5.7-2.9-7.7 1.2-.6 2.5-.9 3.8-.9.4 0 .8.2.9.5l1.9 3.8 1.9-3.8c.2-.3.5-.5.9-.5h.1c5 0 9.1 4.1 9.1 9.1z" />
        </g>
      </g>
    </svg>
  );
};
export default LeadSvg;
