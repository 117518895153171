import React from 'react';

const AccountSvg = ({
  height = 21,
  width = 21,
  viewBox = '0 0 512 512'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="currentColor"
    >
      <g>
        <g id="Layer_1">
          <path d="M182,239.4c0,9.7-7.9,17.6-17.6,17.6s-17.6-7.9-17.6-17.6v-17.6c0-9.7,7.9-17.6,17.6-17.6s17.6,7.9,17.6,17.6v17.6Z" />
          <path d="M234.7,204.3c-9.7,0-17.6,7.9-17.6,17.6v17.6c0,9.7,7.9,17.6,17.6,17.6s17.6-7.9,17.6-17.6v-17.6c0-9.7-7.9-17.6-17.6-17.6Z" />
          <path d="M182,300.9c0-9.7-7.9-17.6-17.6-17.6s-17.6,7.9-17.6,17.6v17.6c0,9.7,7.9,17.6,17.6,17.6s17.6-7.9,17.6-17.6v-17.6Z" />
          <path d="M252.2,318.4v-17.6c0-9.7-7.9-17.6-17.6-17.6s-17.6,7.9-17.6,17.6v17.6c0,9.7,7.9,17.6,17.6,17.6s17.6-7.9,17.6-17.6Z" />
          <path d="M164.4,362.3c-9.7,0-17.6,7.9-17.6,17.6v17.6c0,9.7,7.9,17.6,17.6,17.6s17.6-7.9,17.6-17.6v-17.6c0-9.7-7.9-17.6-17.6-17.6Z" />
          <path d="M182,142.8c0-9.7-7.9-17.6-17.6-17.6s-17.6,7.9-17.6,17.6v17.6c0,9.7,7.9,17.6,17.6,17.6s17.6-7.9,17.6-17.6v-17.6Z" />
          <path d="M252.2,142.8c0-9.7-7.9-17.6-17.6-17.6s-17.6,7.9-17.6,17.6v17.6c0,9.7,7.9,17.6,17.6,17.6s17.6-7.9,17.6-17.6v-17.6Z" />
          <path d="M287.3,142.8v17.6c0,9.7,7.9,17.6,17.6,17.6s17.6-7.9,17.6-17.6v-17.6c0-9.7-7.9-17.6-17.6-17.6s-17.6,7.9-17.6,17.6Z" />
          <path d="M242.9,448.4H112.6V113h17.6c9.7,0,17.6-7.9,17.6-17.6v-26.3h174.7v26.3c0,9.7,7.9,17.6,17.6,17.6h17.6v57.3c0,9.7,7.9,17.6,17.6,17.6s17.6-7.9,17.6-17.6v-74.8c0-9.7-7.9-17.6-17.6-17.6h-17.6v-26.3c0-9.7-7.9-17.6-17.6-17.6h-209.9c-9.7,0-17.6,7.9-17.6,17.6v26.3h-17.6c-9.7,0-17.6,7.9-17.6,17.6v370.5c0,9.7,7.9,17.6,17.6,17.6h147.8c9.7,0,17.6-7.9,17.6-17.6s-7.9-17.6-17.6-17.6Z" />
          <circle cx="375.5" cy="264.9" r="55.8" />
          <path d="M390.7,332.7c-28-2.3-52.4,0-73.4,17.4-35.2,28.2-28.4,66.1-28.4,112.5s10,22.4,22.4,22.4c135.3,0,140.7,4.4,148.7-13.4,2.6-6,1.9-4.1,1.9-61.6s-39.5-77.3-71.3-77.3h0Z" />
        </g>
      </g>
    </svg>
  );
};
export default AccountSvg;
