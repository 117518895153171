import React from 'react';

const TaskSvg = ({
  height = 20,
  width = 20,
  viewBox = '0 0 512 512'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m437.852 43.198c-8.492-8.51-19.786-13.198-31.802-13.198h-19.945v20c0 22.056-17.924 40-39.956 40h-179.8c-22.032 0-39.956-17.944-39.956-40v-20h-20.011c-24.758 0-44.922 20.164-44.95 44.95l-.432 392c-.014 12.029 4.656 23.341 13.147 31.852 8.493 8.511 19.787 13.198 31.803 13.198h299.667c24.758 0 44.923-20.164 44.95-44.95l.433-392c.014-12.029-4.656-23.341-13.148-31.852zm-176.758 136.802h106.453c8.275 0 14.983 6.716 14.983 15s-6.708 15-14.983 15h-106.453c-8.275 0-14.983-6.716-14.983-15s6.708-15 14.983-15zm0 100h106.453c8.275 0 14.983 6.716 14.983 15s-6.708 15-14.983 15h-106.453c-8.275 0-14.983-6.716-14.983-15s6.708-15 14.983-15zm0 100h106.881c8.275 0 14.983 6.716 14.983 15s-6.708 15-14.983 15h-106.881c-8.275 0-14.983-6.716-14.983-15s6.708-15 14.983-15zm-127.663-199.408c5.852-5.858 15.338-5.858 21.19 0l8.147 8.157 34.554-34.592c5.852-5.857 15.338-5.859 21.189 0 5.851 5.857 5.851 15.355 0 21.213l-45.149 45.198c-2.81 2.813-6.621 4.394-10.595 4.394s-7.785-1.58-10.595-4.394l-18.742-18.764c-5.851-5.857-5.851-15.355.001-21.212zm0 107.637c5.852-5.858 15.338-5.858 21.19 0l8.147 8.156 34.554-34.592c5.851-5.857 15.338-5.857 21.189 0s5.851 15.355 0 21.213l-45.149 45.199c-2.809 2.813-6.621 4.394-10.595 4.394s-7.785-1.58-10.595-4.394l-18.742-18.764c-5.851-5.857-5.851-15.354.001-21.212zm0 100c5.852-5.858 15.338-5.858 21.19 0l8.147 8.156 34.554-34.592c5.851-5.857 15.338-5.857 21.189 0s5.851 15.355 0 21.213l-45.149 45.199c-2.809 2.813-6.621 4.394-10.595 4.394s-7.785-1.58-10.595-4.394l-18.742-18.764c-5.851-5.857-5.851-15.354.001-21.212z" />
      <path d="m156.361 50c0 5.523 4.472 10 9.989 10h179.8c5.517 0 9.989-4.477 9.989-10v-40c0-5.523-4.472-10-9.989-10h-179.8c-5.517 0-9.989 4.477-9.989 10z" />
    </svg>
  );
};
export default TaskSvg;
