const initialstate = {
  userInfo: null,
  profileData: null,
  settings: {},
  firebaseToken: ''
};
interface actionProp {
  type: string;
  payload: object;
}
const AuthReducer = (state = initialstate, action: actionProp) => {
  switch (action.type) {
    case 'UPDATE_USER_DETAILS':
      return {
        ...state,
        userInfo: action.payload
      };
    case 'UPDATE_PROFILE_DATA':
      return {
        ...state,
        profileData: action.payload
      };
    case 'UPDATE_SETTINGS':
      return {
        ...state,
        settings: action.payload
      };
    case 'UPDATE_FIREBASE_TOKEN':
      return {
        ...state,
        firebaseToken: action.payload
      };
    default:
      return state;
  }
};

export default AuthReducer;
