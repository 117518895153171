import axios from 'axios';
import { API_URL } from 'config/app.config';
import { errorMessage } from 'helpers/ToastNotificationHelper';
const instance = axios.create({
  //   baseURL: API_URL,
  headers: {
    'Content-type': 'application/json'
  }
});
/**
 * Catch the unAuthorized Request
 */
instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // errorMessage('Source Booster: Unauthorized');
      return {
        status: false,
        hasError: true,
        message: 'Source Booster: Unauthorized'
      };
      return false;
    } else {
      let message = error.response.message || 'Something went wrong!';
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message = error.response.data.message;
      }
      return {
        status: false,
        hasError: true,
        message: message
      };
    }
  }
);
export default instance;
