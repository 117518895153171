import moment from 'moment';
import store from 'store';
import {
  dateDisplayFormatSelector,
  dateTimeDisplayFormatSelector,
  settingsSelector,
  timeDisplayFormatSelector,
  userTimeZoneSelector
} from 'components/_trackivo/selectors/authSelectors';
import momentTimezone from 'moment-timezone';
export const UTC_TIMEZONE_SLUG = 'UTC';
export const SERVER_FORMATED_DATE_TIME = 'YYYY-MM-DD HH:mm:ss';
export const CALENDAR_LIST_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const getMaxAllowedJoiningDate = () => {
  //  max allowed joining date
  return getCurrentDateForDatePicker();
};
export const getCurrentDateForDatePicker = () => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Set time to midnight
  return currentDate;
};
export const getMaxAllowedMonthYear = (dateFormat = 'YYYY-MM') => {
  // for month year picker
  const date = moment().format(dateFormat);
  return date;
};
export const getMaxAllowedBirthDate = (dateFormat = 'YYYY-MM-DD') => {
  // for date picker
  const date = moment().subtract(12, 'years').format(dateFormat);
  return new Date(date);
};
export const getCurrentYear = () => {
  return moment().year();
};
export const getCurrentDateTime = () => {
  return moment();
};
// utc related function ........start........
export const getServerFormattedUTCDate = (date: any) => {
  // to pass FORMATED DATE in API calls
  const dateFormat = SERVER_FORMATED_DATE_TIME.split(' ')[0];
  // console.log(moment(date).utc().format(dateFormat));

  return moment(date).utc().format(dateFormat);
};
export const getServerFormattedUTCTime = (dateTime: Date): string => {
  // to pass FORMATED TIME in API calls
  return moment(dateTime).utc().format(SERVER_FORMATED_DATE_TIME.split(' ')[1]);
};
export const getServerFormattedUTCDateTime = (dateTime: any) => {
  // to pass FORMATED DATE in API calls
  const dateFormat = SERVER_FORMATED_DATE_TIME;
  return moment(new Date(dateTime)).utc().format(dateFormat);
};
// utc related function ........end........
// timezone wise date and time format .......start......
export const getFormattedDateTimezonewise = (date: any, timezone = '') => {
  let dateFormat = '';
  if (timezone == '') {
    timezone = userTimeZoneSelector(store.getState());
  }
  // to display DATE in a page
  if (dateFormat == '') {
    if (dateDisplayFormatSelector(store.getState())) {
      dateFormat = dateDisplayFormatSelector(store.getState());
      // dateFormat = dateFormat.split(' ')[0];
    } else {
      dateFormat = 'DD/MM/YYYY';
    }
  }
  if (timezone != UTC_TIMEZONE_SLUG) {
    return momentTimezone.utc(date).tz(timezone).format(dateFormat);
  } else {
    return moment(date).format(dateFormat);
  }
};
export const getFormattedDateTimeTimezonewise = (
  dateTime: any,
  timezone = '',
  timeFormat = ''
) => {
  // to display TIME in a page
  // let timeFormat = '';
  if (timezone == '') {
    timezone = userTimeZoneSelector(store.getState());
  }
  if (timeFormat == '') {
    if (dateTimeDisplayFormatSelector(store.getState())) {
      timeFormat = dateTimeDisplayFormatSelector(store.getState());
    } else {
      timeFormat = 'DD/MM/YYYY h:mm A';
    }
  }
  // return moment(dateTime).format(timeFormat);
  if (timezone != UTC_TIMEZONE_SLUG) {
    return momentTimezone.utc(dateTime).tz(timezone).format(timeFormat);
  } else {
    return moment(dateTime).format(timeFormat);
  }
};
export const getFormattedTimeTimezonewise = (dateTime: any, timezone = '') => {
  // to display TIME in a page
  let timeFormat = '';
  if (timezone == '') {
    timezone = userTimeZoneSelector(store.getState());
  }
  if (timeFormat == '') {
    if (timeDisplayFormatSelector(store.getState())) {
      timeFormat = timeDisplayFormatSelector(store.getState());
    } else {
      timeFormat = 'h:mm A';
    }
  }
  // return moment(dateTime).format(timeFormat);
  if (timezone != UTC_TIMEZONE_SLUG) {
    return momentTimezone.utc(dateTime).tz(timezone).format(timeFormat);
  } else {
    return moment(dateTime).format(timeFormat);
  }
};
// timezone wise date and time format .......end......###################
export const getServerFormattedDateTime = (date: any) => {
  // to pass FORMATED DATE in API calls
  const dateFormat = SERVER_FORMATED_DATE_TIME;
  return moment(new Date(date)).format(dateFormat);
};
export const getServerFormattedDate = (date: any) => {
  // to pass FORMATED DATE in API calls
  const dateFormat = SERVER_FORMATED_DATE_TIME.split(' ')[0];
  return moment(date).format(dateFormat);
};
export const getServerFormattedTime = (dateTime: Date): string => {
  // to pass FORMATED TIME in API calls
  // return moment(dateTime).utc().format('HH:mm:ss');
  return moment(dateTime).format(SERVER_FORMATED_DATE_TIME.split(' ')[1]);
};
export const getDayNameOfWeek = (date: any, format = 'dddd') => {
  return moment(date).format(format);
};
export const getFormattedDate = (date: any, dateFormat = '') => {
  // let dateFormat = '';
  // to display DATE in a page
  if (dateFormat == '') {
    if (dateDisplayFormatSelector(store.getState())) {
      dateFormat = dateDisplayFormatSelector(store.getState());
      // dateFormat = dateFormat.split(' ')[0];
    } else {
      dateFormat = 'DD/MM/YYYY';
    }
  }
  return moment(date).format(dateFormat);
};
export const getFormattedDateTime = (dateTime: any, timeFormat = '') => {
  // to display TIME in a page
  if (timeFormat == '') {
    if (dateTimeDisplayFormatSelector(store.getState())) {
      timeFormat = dateTimeDisplayFormatSelector(store.getState());
    } else {
      timeFormat = 'DD/MM/YYYY h:mm A';
    }
  }
  return moment(dateTime).format(timeFormat);
};
export const getFormattedTime = (dateTime: any) => {
  // to display TIME in a page
  let timeFormat = '';
  if (timeFormat == '') {
    if (timeDisplayFormatSelector(store.getState())) {
      timeFormat = timeDisplayFormatSelector(store.getState());
    } else {
      timeFormat = 'h:mm A';
    }
  }
  return moment(dateTime).format(timeFormat);
};
export const dateDifferenceMinutes = (date1: any, date2: any) => {
  // date1 should be larger than date2
  date1 = moment(date1);
  date2 = moment(date2);
  const duration = moment.duration(date1.diff(date2));
  const minutes = duration.asMinutes();
  return Math.round(minutes);
};
export const dateDifferenceDays = (date1: any, date2: any) => {
  // date1 should be larger than date2
  date1 = moment(date1);
  date2 = moment(date2);
  const duration = moment.duration(date1.diff(date2));
  const minutes = duration.asDays();
  return Math.round(minutes);
};
export const dateDifferenceCalculation = (datetime: any) => {
  const providedDate = new Date(datetime);

  // Define the current date
  const currentDate = new Date();

  // Find the time difference in milliseconds
  const timeDifference = currentDate.getTime() - providedDate.getTime();

  // Convert milliseconds to minutes
  const minutesDifference = Math.floor(timeDifference / 60000);

  // Convert minutes to hours
  const hoursDifference = Math.floor(minutesDifference / 60);

  // Convert minutes to days
  const daysDifference = Math.floor(minutesDifference / (60 * 24));

  // Convert days to months (rough estimate)
  const monthsDifference = Math.round(daysDifference / 30);

  // Convert days to years (rough estimate)
  const yearsDifference = Math.floor(daysDifference / 365);
  if (minutesDifference <= 1 || minutesDifference < 60) {
    // minutes
    if (minutesDifference < 1) {
      return 'Just now';
    } else if (minutesDifference == 1) {
      return 'A minute ago';
    } else {
      return minutesDifference + ' minutes ago';
    }
  } else if (hoursDifference >= 1 && hoursDifference <= 24) {
    // hours
    if (hoursDifference <= 1) {
      return 'An Hour ago';
    } else {
      return hoursDifference + ' Hours ago';
    }
  } else if (daysDifference >= 1 && daysDifference <= 31) {
    return daysDifference + ' days ago';
  } else if (monthsDifference <= 12) {
    return monthsDifference + ' months ago';
  } else {
    return yearsDifference + ' years ago';
  }
};
export const getStringMonthYear = (month: number, year: number) => {
  const endDate = new Date(year, month - 1); // May 2024
  const endDateString = endDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long'
  });
  return endDateString;
};
export const getMonthYearDifference = (
  startMonth: any,
  startYear: any,
  endMonth: any,
  endYear: any
) => {
  // Calculate the difference in years and months
  let yearDiff = endYear - startYear;
  let monthDiff = endMonth - startMonth;

  // Adjust the year difference if the month difference is negative
  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }

  return { years: yearDiff, months: monthDiff };
};
export const getWeekDayNameList = () => {
  return [
    { slug: 'Sunday', name: 'Sunday' },
    { slug: 'Monday', name: 'Monday' },
    { slug: 'Tuesday', name: 'Tuesday' },
    { slug: 'Wednesday', name: 'Wednesday' },
    { slug: 'Thursday', name: 'Thursday' },
    { slug: 'Friday', name: 'Friday' },
    { slug: 'Saturday', name: 'Saturday' }
  ];
};
export const getWeekDayNameObjBySlug = (slug: string) => {
  let selectedSlug = null;
  const filteredData = getWeekDayNameList().filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const getLast10Years = () => {
  const currentYear = moment().year();
  const years = [];

  for (let year = currentYear; year >= currentYear - 9; year--) {
    years.push(year);
  }
  return years;
};
// filter functions
export const getDateRange = (rangeType: any) => {
  const daterange: any = {
    start_date: moment(),
    end_date: moment()
  };
  if (rangeType == 'last_30_days') {
    moment().subtract(60, 'days');
    daterange.start_date = moment().subtract(30, 'days');
    daterange.end_date = moment();
  } else if (rangeType == 'last_60_days') {
    daterange.start_date = moment().subtract(60, 'days');
    daterange.end_date = moment();
  } else if (rangeType == 'last_90_days') {
    daterange.start_date = moment().subtract(90, 'days');
    daterange.end_date = moment();
  } else if (rangeType == 'last_year') {
    daterange.start_date = moment().subtract(1, 'years').startOf('year');
    daterange.end_date = moment().subtract(1, 'years').endOf('year');
  }
  daterange.start_date = new Date(daterange.start_date);
  daterange.end_date = new Date(daterange.end_date);
  return daterange;
};
export const getFilterBy = (date1: any, date2 = moment()) => {
  let filterBy = '';
  const daysDifference = dateDifferenceDays(date1, date2);
  if (daysDifference >= 730) {
    // 365 days/year * 2 years = 730 days
    filterBy = 'year';
  } else if (daysDifference > 31) {
    filterBy = 'month';
  } else {
    filterBy = 'day';
  }
  return filterBy;
};
export const convertToHHMMSS = (totalHours: any) => {
  const milliseconds = totalHours * 60 * 60 * 1000;
  const hours = Math.floor(milliseconds / (60 * 60 * 1000));
  const minutes = Math.floor((milliseconds % (60 * 60 * 1000)) / (60 * 1000));
  const seconds = Math.floor((milliseconds % (60 * 1000)) / 1000);
  const ms = milliseconds % 1000;
  // return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${String(ms).padStart(3, '0')}`;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};
export const calculateDurationInHours = (checkIn: any, timezone: any) => {
  const checkInDate = moment.tz(checkIn, timezone);
  const currentDate = moment.tz(timezone);
  const duration = moment.duration(currentDate.diff(checkInDate));

  const hours = duration.asHours();

  return hours;
};
export const getFirstDayOfMonth = (date = moment()) => {
  return moment(date).startOf('month').toDate(); //.format('YYYY-MM-DD');
};

export const getLastDayOfMonth = (date = moment()) => {
  return moment(date).endOf('month').toDate(); //.format('YYYY-MM-DD');
};

export const getDateRangeList = (startDate: any, endDate: any) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const dates: string[] = [];
  while (start.isBefore(end) || start.isSame(end)) {
    dates.push(start.format('YYYY-MM-DD'));
    start.add(1, 'day');
  }
  return dates;
};
