import React from 'react';

const ShiftSvg = ({
  height = 21,
  width = 21,
  viewBox = '0 0 491.5 491.5'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <>
      <svg
        id="OBJECT"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        fill="currentColor"
      >
        <defs>
          <style>
            {`.cls-1 {
        fill-rule: evenodd;
      }
        `}
          </style>
        </defs>

        <g>
          <g
            id="Layer_1"
            imageRendering="optimizeQuality"
            shapeRendering="geometricPrecision"
            textRendering="geometricPrecision"
          >
            <path
              id="_215484376"
              fillRule="evenodd"
              d="M235.6,188.1v43.1c3-1.8,6.4-2.8,10.1-2.8s7.2,1,10.1,2.8v-43.1c0-1.3,1-2.3,2.3-2.3h1c.9,0,1.7-.5,2.1-1.3.4-.8.4-1.7-.2-2.4l-13.5-17.5c-.5-.6-1.1-1-1.9-1s-1.4.4-1.9,1l-13.5,17.5c-.5.7-.6,1.6-.2,2.4.4.8,1.2,1.3,2.1,1.3h1c1.3,0,2.3,1,2.3,2.3h0Z"
            />
            <path
              id="_215483968"
              fillRule="evenodd"
              d="M245.8,226c-3.7,0-7.2,1-10.1,2.8-5.8,3.4-9.6,9.8-9.6,17,0,10.9,8.8,19.7,19.8,19.7s13.5-3.9,17-9.6c1.8-3,2.8-6.4,2.8-10.1s-1-7.2-2.8-10.1c-1.7-2.8-4-5.1-6.8-6.8-3-1.8-6.4-2.8-10.1-2.8h0Z"
            />
            <path
              id="_215483776"
              fillRule="evenodd"
              d="M292.1,235.6h-31.8c1.8,3,2.8,6.4,2.8,10.1s-1,7.2-2.8,10.1h31.8c1.3,0,2.3,1,2.3,2.3v1c0,.9.5,1.7,1.3,2.1.8.4,1.7.4,2.4-.2l17.5-13.5c.6-.5,1-1.1,1-1.9s-.4-1.4-1-1.9l-17.5-13.5c-.7-.5-1.6-.6-2.4-.2-.8.4-1.3,1.2-1.3,2.1v1c0,1.3-1,2.3-2.3,2.3h0Z"
            />
            <g>
              <path d="M187.6,128.5l-30.9-13.1c-.6-.3-1.4-.3-1.9,0s-1.1.8-1.1,1.4l-18.1,73.1-18.3-73.1c-.3-.6-.6-1.1-1.1-1.4-.6-.3-1.1-.3-1.9,0l-30.6,11.7c-4.2,1.7-6.7,5-6.7,8.9v63.1c0,1.1,1.1,2.2,2.2,2.2h112c1.1,0,2.2-.8,2.2-2.2v-61.7c.6-3.6-1.9-7.2-5.8-8.9Z" />
              <path d="M145.4,121.8c1.4-2.2,1.1-5.3-.8-7.2-1.1-1.4-2.8-1.9-4.7-1.9h-8.3c-1.7,0-3.6.8-4.7,1.9-1.7,1.9-2.2,4.7-.8,7.2l2.2,3.3c1.1,1.9,1.7,3.9,1.4,6.1l-1.4,14.5,4.2,11.4c.6,1.4,1.7,2.2,3.3,2.2s2.8-.8,3.3-2.2l4.2-11.4-1.7-14.2c-.3-2.2.3-4.4,1.4-6.1l2.5-3.6Z" />
              <path d="M135.6,106.2c18.3,0,33.4-15,33.4-33.4v-10.6c0-18.3-15-33.4-33.4-33.4s-33.4,15-33.4,33.4v10.6c0,18.3,15,33.4,33.4,33.4Z" />
            </g>
            <g>
              <path d="M425.4,352.5l-30.9-13.1c-.6-.3-1.4-.3-1.9,0s-1.1.8-1.1,1.4l-18.1,73.1-18.3-73.1c-.3-.6-.6-1.1-1.1-1.4-.6-.3-1.1-.3-1.9,0l-30.6,11.7c-4.2,1.7-6.7,5-6.7,8.9v63.1c0,1.1,1.1,2.2,2.2,2.2h112c1.1,0,2.2-.8,2.2-2.2v-61.7c.6-3.6-1.9-7.2-5.8-8.9Z" />
              <path d="M383.2,345.8c1.4-2.2,1.1-5.3-.8-7.2-1.1-1.4-2.8-1.9-4.7-1.9h-8.3c-1.7,0-3.6.8-4.7,1.9-1.7,1.9-2.2,4.7-.8,7.2l2.2,3.3c1.1,1.9,1.7,3.9,1.4,6.1l-1.4,14.5,4.2,11.4c.6,1.4,1.7,2.2,3.3,2.2s2.8-.8,3.3-2.2l4.2-11.4-1.7-14.2c-.3-2.2.3-4.4,1.4-6.1l2.5-3.6Z" />
              <path d="M373.4,330.2c18.3,0,33.4-15,33.4-33.4v-10.6c0-18.3-15-33.4-33.4-33.4s-33.4,15-33.4,33.4v10.6c0,18.3,15,33.4,33.4,33.4Z" />
            </g>
            <g>
              <path d="M280.6,424.2h-20.2c-101.1,0-183.3-82.2-183.3-183.3h21.6c0,82.1,61.4,150,140.7,160.4l-9.5-14.2,17.9-12,13.3,20,19.4,29.1Z" />
              <path d="M435.2,225h-21.6c0-82.1-61.4-150-140.7-160.4l9.5,14.2-17.9,12-13.3-20-19.4-29.1h20.2c101.1,0,183.3,82.2,183.3,183.3Z" />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};
export default ShiftSvg;
