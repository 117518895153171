import React from 'react';

const GroupSvg = ({
  height = 21,
  width = 21,
  viewBox = '0 0 64 64'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <>
      <svg
        id="OBJECT"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        fill="currentColor"
      >
        <g>
          <g>
            <path d="m29.497 20.52c.497.757 1.208 1.782 2.237 1.782h.529c.97 0 1.725-.993 2.214-1.722.6-.896.988-1.934 1.123-3.004.169-1.338.191-2.893-.621-4.04-.679-.959-1.802-1.543-2.978-1.543-1.192 0-2.295.605-2.975 1.577-.827 1.181-.788 2.631-.623 4.005.125 1.039.513 2.061 1.094 2.945z" />
            <path d="m30.692 30.624s.489-3.929.49-3.936c.084-.675-.953-1.215-.547-1.916.224-.388.433-1.017.97-1.017h.791c.529 0 .747.631.97 1.017.409.709-.631 1.24-.547 1.916 0 0 .49 3.939.49 3.939l1.981-7.161c2.69 1.242 4.558 3.967 4.558 7.129v3.993c0 1.746-1.16 2.928-2.211 4.172v17.591c0 2.606-3.874 2.978-4.216.377l-1.421-12.527s-1.421 12.514-1.422 12.527c-.289 2.549-4.216 2.271-4.216-.274 0 0 0-17.693 0-17.694-1.065-1.261-2.211-2.41-2.211-4.172v-3.728c0-3.187 1.842-6.075 4.567-7.377z" />
          </g>
          <g>
            <g>
              <path d="m55.18 12.092c.385.586.935 1.379 1.732 1.379h.409c.75 0 1.335-.768 1.713-1.333.465-.693.765-1.497.869-2.325.131-1.036.148-2.239-.481-3.126-.525-.742-1.395-1.194-2.304-1.194-.923 0-1.776.468-2.302 1.221-.64.914-.61 2.036-.482 3.099.096.804.397 1.595.846 2.279z" />
              <path d="m63.19 19.884v3.09c0 1.36-.9 2.27-1.71 3.23v13.62c0 2.01-3 2.3-3.26.29l-1.1-9.7s-1.1 9.69-1.1 9.7c-.23 1.97-3.27 1.76-3.27-.21v-5.01c.95-1.12 2-2.53 2-4.55v-3.71c0-2.93-1.39-5.65-3.65-7.39.28-2.12 1.62-3.97 3.48-4.86l1.52 5.53s.38-3.04.38-3.05c.07-.52-.73-.94-.42-1.48.17-.3.33-.79.75-.79h.61c.41 0 .58.49.75.79.32.55-.48.96-.42 1.48l.38 3.05 1.53-5.54c2.09.96 3.53 3.07 3.53 5.51z" />
            </g>
            <g>
              <path d="m4.946 12.092c.385.586.935 1.379 1.732 1.379h.409c.75 0 1.335-.768 1.713-1.333.465-.693.765-1.497.869-2.325.131-1.035.148-2.238-.481-3.126-.525-.742-1.394-1.194-2.304-1.194-.923 0-1.776.468-2.302 1.221-.64.914-.61 2.036-.482 3.099.096.804.397 1.595.846 2.279z" />
              <path d="m9.25 26.875v3.47c0 2.04 1.05 3.44 2 4.55v4.93c0 2.01-3 2.3-3.27.29l-1.1-9.7s-1.1 9.69-1.1 9.7c-.22 1.97-3.26 1.76-3.26-.21v-13.7c-.82-.97-1.71-1.86-1.71-3.23v-2.88c0-2.47 1.42-4.7 3.53-5.71l1.53 5.53s.38-3.04.38-3.05c.06-.52-.74-.94-.42-1.48.17-.3.33-.79.75-.79h.61c.41 0 .58.49.75.79.32.55-.49.96-.42 1.48l.38 3.05 1.53-5.54c1.9.88 3.28 2.72 3.49 4.9-2.28 1.789-3.67 4.59-3.67 7.6z" />
            </g>
          </g>
          <g>
            <path d="m16.216 17.263c.462.704 1.123 1.656 2.08 1.656h.491c.901 0 1.604-.923 2.058-1.601.558-.833.919-1.798 1.044-2.793.157-1.244.177-2.689-.577-3.755-.631-.892-1.675-1.434-2.768-1.434-1.108 0-2.133.563-2.766 1.466-.768 1.098-.733 2.445-.579 3.723.117.966.478 1.917 1.017 2.738z" />
            <path d="m21.797 34.585c0 2.122 1.014 3.6 1.989 4.787v11.201c0 2.421-3.6 2.767-3.922.346l-1.321-11.642s-1.321 11.634-1.321 11.642c-.267 2.374-3.922 2.115-3.922-.252v-16.444c-.99-1.179-2.052-2.24-2.052-3.883v-3.467c0-2.963 1.714-5.644 4.245-6.855l1.832 6.634s.456-3.647.456-3.655c.079-.629-.88-1.132-.503-1.784.204-.362.401-.943.896-.943h.739c.487 0 .692.59.904.943.377.66-.59 1.155-.511 1.784l.456 3.663 1.839-6.658c1.462.676 2.657 1.816 3.404 3.239-2.004 1.949-3.207 4.701-3.207 7.617v3.727z" />
          </g>
          <g>
            <path d="m43.129 17.263c.462.704 1.123 1.656 2.08 1.656h.491c.901 0 1.604-.923 2.058-1.601.558-.833.919-1.798 1.044-2.793.157-1.244.177-2.689-.577-3.755-.631-.892-1.675-1.434-2.768-1.434-1.108 0-2.133.563-2.766 1.466-.768 1.098-.733 2.445-.579 3.723.116.966.478 1.917 1.017 2.738z" />
            <path d="m52.752 26.63v3.71c0 1.627-1.077 2.72-2.052 3.883v16.35c0 2.421-3.608 2.767-3.922.346l-1.321-11.642s-1.321 11.634-1.321 11.642c-.275 2.374-3.922 2.115-3.922-.252v-11.295c.983-1.195 1.989-2.68 1.989-4.787v-3.993c0-2.814-1.171-5.463-3.136-7.35.755-1.399 1.918-2.539 3.341-3.223l1.832 6.634s.456-3.647.456-3.655c.079-.629-.888-1.132-.511-1.784.212-.362.409-.943.904-.943h.739c.487 0 .692.59.896.943.385.66-.582 1.155-.503 1.784l.456 3.663 1.839-6.658c2.499 1.156 4.236 3.687 4.236 6.627z" />
          </g>
        </g>
      </svg>
    </>
  );
};
export default GroupSvg;
