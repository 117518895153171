import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { mainRoutes, loginRoutes, userRoutes } from 'config/routing';
import AppLayout from 'layouts/MainLayout';
import Error404 from 'components/_trackivo/common/ErrorPages/Error404';
import { PreloaderComponent } from 'components/_trackivo/common/Preloader';
import SigninLayout from 'layouts/AuthCardLayout';
import FullScreenLayout from 'layouts/FullScreenLayout';

interface NestedRoute {
  path: string;
  element: React.ReactElement;
}

interface MainRoute {
  path: string;
  element: React.ReactElement;
  routes?: Record<string, NestedRoute>;
}

export default function RouteWrapper() {
  const routeKeys = Object.keys(mainRoutes);
  const loginRouteKeys = Object.keys(loginRoutes);
  const userRouteKeys = Object.keys(userRoutes);
  return (
    <Suspense fallback={<PreloaderComponent />}>
      <Routes>
        <Route element={<AppLayout />}>
          {routeKeys.map((routeKey, i) => {
            const mainRoute = mainRoutes[routeKey] as MainRoute | undefined;

            if (!mainRoute) {
              return null;
            }

            return (
              <Route key={i} path={mainRoute.path} element={mainRoute.element}>
                {mainRoute.routes &&
                  Object.keys(mainRoute.routes).map(
                    (nestedRouteKey, nestedIndex) => {
                      const nestedRoute = mainRoute.routes![nestedRouteKey] as
                        | NestedRoute
                        | undefined;

                      if (!nestedRoute) {
                        return null;
                      }

                      return (
                        <Route
                          key={nestedIndex}
                          path={nestedRoute.path}
                          element={nestedRoute.element}
                        />
                      );
                    }
                  )}
              </Route>
            );
          })}
        </Route>
        <Route>
          {loginRouteKeys.map((routeKey, i) => {
            const loginRoute = loginRoutes[routeKey];

            if (!loginRoute) {
              return null;
            }

            return (
              <Route
                key={i}
                path={loginRoute.path}
                element={loginRoute.element}
              />
            );
          })}
        </Route>
        <Route element={<FullScreenLayout />}>
          {userRouteKeys.map((routeKey, i) => {
            const userRoute = userRoutes[routeKey];

            if (!userRoute) {
              return null;
            }

            return (
              <Route
                key={i}
                path={userRoute.path}
                element={userRoute.element}
              />
            );
          })}
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
}
