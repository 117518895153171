import React from 'react';

const CandidateSvg = ({
  height = 21,
  width = 21,
  viewBox = '0 0 512 512'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <g id="Layer_2" data-name="Layer 2">
        <path
          fill="currentColor"
          className="cls-2"
          d="M406.5,241c2.1-53.9-17.1-109-59.4-151.3-41.3-41.3-86.7-51-89.4-52.2h0c-105.6-29.2-178.4,35.4-179.8,36C-18.7,149.3-26.2,291.6,59.5,377.3c8.9,8.9,18.4,16.7,28.3,23.6v-4.2c0-45.6,26.6-85.2,65.1-103.9-16.8-14.3-27.5-35.5-27.5-59.3,0-42.9,34.9-77.8,77.8-77.8s77.8,34.9,77.8,77.8-10.7,45-27.5,59.3c11.9,5.8,22.7,13.6,31.9,22.9,22.3-44.2,68.1-74.6,121-74.7Z"
        />
        <path
          fill="currentColor"
          className="cls-1"
          d="M273.9,348.8c-15.4-22.6-41.3-37.5-70.6-37.5-47.1,0-85.3,38.3-85.3,85.3v21.5c51.1,23.6,109.7,24.6,161,4.1-5.1-14.3-7.9-29.6-7.9-45.6s1-18.8,2.9-27.8h0Z"
        />
        <circle
          fill="currentColor"
          className="cls-1"
          cx="203.3"
          cy="233.5"
          r="47.7"
        />
        <path
          fill="currentColor"
          className="cls-2"
          d="M406.6,271.2c-58.2,0-105.4,47.2-105.4,105.4s47.2,105.4,105.4,105.4,105.4-47.2,105.4-105.4-47.2-105.4-105.4-105.4ZM395.9,419.2l-42.6-42.6,21.3-21.3,21.3,21.3,42.6-42.6,21.3,21.3-63.9,63.9Z"
        />
      </g>
    </svg>
  );
};
export default CandidateSvg;
