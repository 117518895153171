import SettingsPanel from 'components/settings-panel/SettingsPanel';
import SettingsToggle from 'components/settings-panel/SettingsToggle';
import useToggleStyle from 'hooks/useToggleStyle';
import { useAppContext } from 'providers/AppProvider';
import { useSettingsPanelContext } from 'providers/SettingsPanelProvider';
import {
  JSXElementConstructor,
  ReactElement,
  useEffect,
  useLayoutEffect
} from 'react';
import {
  NavigationType,
  Outlet,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import RouteWrapper from 'routes/RouteWrapper';
import { HelmetProvider } from 'react-helmet-async';
import ErrorBoundary from 'components/_trackivo/common/ErrorBoundary/ErrorBoundary';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { activePageInfoSelector } from 'components/_trackivo/selectors/settingSelectors';
import { connect } from 'react-redux';
import { RootState } from 'store';
import { saveCache } from 'helpers/AwsHelper';
// import { getAwsUrl } from 'helpers/AwsHelper';
interface AppProps {
  pageInfo?: object;
}
const App = ({ pageInfo }: AppProps) => {
  const { isStylesheetLoaded } = useToggleStyle();
  const { pathname } = useLocation();

  const {
    settingsPanelConfig: { showSettingPanelButton },
    setSettingsPanelConfig
  } = useSettingsPanelContext();

  const {
    config: { theme, isRTL }
  } = useAppContext();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // useEffect(() => {
  //   window.addEventListener('beforeunload', saveCache);
  //   return () => {
  //     window.removeEventListener('beforeunload', saveCache);
  //     saveCache();
  //   };
  // }, []);
  // useEffect(() => {
  //   const image = getAwsUrl('public/upload/profile/user.jpg');
  //   console.log(image);
  // }, []);

  useEffect(() => {
    setSettingsPanelConfig({
      openSettingPanel: false
    });
  }, [isRTL]);
  return (
    <>
      {!isStylesheetLoaded ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: theme === 'dark' ? '#000' : '#fff'
          }}
        />
      ) : (
        <>
          {/* <Outlet /> */}
          <HelmetProvider>
            <ErrorBoundary>
              <NotificationContainer />
              <RouteWrapper />
              {showSettingPanelButton && (
                <>
                  <SettingsToggle />
                  <SettingsPanel />
                </>
              )}
            </ErrorBoundary>
          </HelmetProvider>
        </>
      )}
    </>
  );
};

// export default App;
const mapStateToProps = (state: RootState) => {
  return {
    pageInfo: activePageInfoSelector(state)
  };
};
export default connect(mapStateToProps, {})(App);
