import React from 'react';

const FormalInterviewSvg = ({
  height,
  width,
  viewBox
}: {
  height: any;
  width: any;
  viewBox: any;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M42.5879 48.2422H57.5404C57.023 47.6368 56.382 47.1492 55.6604 46.8123C54.9388 46.4754 54.1534 46.297 53.357 46.2891H46.7711C45.9748 46.297 45.1894 46.4754 44.4679 46.8123C43.7463 47.1492 43.1052 47.6368 42.5879 48.2422Z" />
      <path d="M40.2344 23.451V30.4688H59.7656V23.451C59.7656 20.0242 56.8842 16.9922 53.6656 16.9922H52.7561L52.123 19.4605L53.3383 26.2473C53.3733 26.4423 53.3481 26.6434 53.2661 26.8237C53.1841 27.0041 53.0492 27.1553 52.8793 27.2572L50.5018 28.683C50.3499 28.774 50.1761 28.822 49.999 28.8217C49.8219 28.8214 49.6483 28.7729 49.4967 28.6814L47.1201 27.2527C46.9502 27.1499 46.8154 26.998 46.7336 26.8169C46.6518 26.6359 46.6268 26.4344 46.6619 26.2389L47.8797 19.4754L47.2439 16.9922H46.3344C43.1158 16.9922 40.2344 20.0242 40.2344 23.451Z" />
      <path d="M29.6428 8.46113C29.6428 5.80488 27.5238 3.64258 24.9193 3.64258C22.3148 3.64258 20.1953 5.8043 20.1953 8.46113C20.1953 11.118 22.3143 13.2799 24.9189 13.2799C27.5236 13.2799 29.6428 11.1182 29.6428 8.46113Z" />
      <path d="M50.0641 43.3318C51.3917 43.3303 52.6644 42.8023 53.6032 41.8635C54.5419 40.9248 55.07 39.652 55.0715 38.3244C55.0715 35.5066 52.7783 33.126 50.0641 33.126C47.3498 33.126 45.0566 35.5066 45.0566 38.3244C45.0581 39.652 45.5862 40.9248 46.5249 41.8635C47.4637 42.8023 48.7365 43.3303 50.0641 43.3318Z" />
      <path d="M15.2344 23.451V30.4688H34.5703V23.451C34.5703 20.0242 31.8035 16.9922 28.5848 16.9922H27.6758L27.0428 19.4605L28.258 26.2473C28.293 26.4423 28.2678 26.6434 28.1859 26.8237C28.1039 27.0041 27.969 27.1553 27.799 27.2572L25.4215 28.683C25.2696 28.774 25.0958 28.822 24.9187 28.8217C24.7417 28.8214 24.568 28.7729 24.4164 28.6814L22.0398 27.2527C21.8699 27.1499 21.735 26.998 21.6532 26.817C21.5714 26.636 21.5465 26.4344 21.5816 26.2389L22.7992 19.4756L22.1633 16.9922H21.2537C18.0352 16.9922 15.2344 20.0242 15.2344 23.451Z" />
      <path d="M25.6584 16.9922H24.1801L24.5517 18.5547H25.2859L25.6584 16.9922Z" />
      <path d="M24.9191 26.707L26.2162 25.9291L25.2271 20.4162L24.6164 20.416L23.6228 25.9285L24.9191 26.707Z" />
      <path d="M65.4297 23.451V30.4688H84.7656V23.451C84.7656 20.0242 81.9648 16.9922 78.7463 16.9922H77.8367L77.2037 19.4605L78.4189 26.2473C78.4539 26.4423 78.4288 26.6434 78.3468 26.8237C78.2648 27.0041 78.1299 27.1553 77.96 27.2572L75.5824 28.683C75.4305 28.774 75.2567 28.822 75.0797 28.8217C74.9026 28.8214 74.7289 28.7729 74.5773 28.6814L72.2008 27.2527C72.0308 27.1499 71.8961 26.998 71.8143 26.8169C71.7324 26.6359 71.7075 26.4344 71.7426 26.2389L72.9604 19.4754L72.3242 16.9922H71.4148C68.1965 16.9922 65.4297 20.0242 65.4297 23.451Z" />
      <path d="M75.0807 26.707L76.3775 25.9291L75.3885 20.4162L74.7777 20.416L73.7842 25.9285L75.0807 26.707Z" />
      <path d="M70.3572 8.46113C70.3572 11.1174 72.4761 13.2799 75.0806 13.2799C77.6851 13.2799 79.8046 11.1182 79.8046 8.46113C79.8046 5.8041 77.6857 3.64258 75.081 3.64258C72.4763 3.64258 70.3572 5.8043 70.3572 8.46113Z" />
      <path d="M75.8198 16.9922H74.3414L74.7133 18.5547H75.4473L75.8198 16.9922Z" />
      <path d="M54.7236 8.46113C54.7236 5.80488 52.6045 3.64258 50 3.64258C47.3955 3.64258 45.2764 5.8043 45.2764 8.46113C45.2764 11.118 47.3955 13.2799 50 13.2799C52.6045 13.2799 54.7236 11.1182 54.7236 8.46113Z" />
      <path d="M10.3516 56.4453H14.0625V79.1016H10.3516V56.4453Z" />
      <path d="M50 26.707L51.2969 25.9291L50.3078 20.4162L49.697 20.416L48.7035 25.9285L50 26.707Z" />
      <path d="M50.7391 16.9922H49.2607L49.6326 18.5547H50.3666L50.7391 16.9922Z" />
      <path d="M85.9375 56.4453H89.8438V79.1016H85.9375V56.4453Z" />
      <path d="M59.0957 76.7578C59.4263 76.744 59.7395 76.6057 59.9725 76.3707C60.2054 76.1356 60.3408 75.8212 60.3516 75.4904V72.2656H54.1016V76.7578H59.0957Z" />
      <path d="M40.4883 76.7578H46.0938V72.2656H39.2578V75.4904C39.263 75.8186 39.3932 76.1325 39.6218 76.368C39.8505 76.6035 40.1604 76.7429 40.4883 76.7578Z" />
      <path d="M61.9646 50.1953H38.2307C37.5035 50.1953 36.9141 50.7848 36.9141 51.5119V68.9959C36.9141 69.723 37.5035 70.3125 38.2307 70.3125H61.9646C62.6918 70.3125 63.2812 69.723 63.2812 68.9959V51.5119C63.2812 50.7848 62.6918 50.1953 61.9646 50.1953Z" />
      <path d="M64.9342 50.1953H96.6308L89.164 32.4219H53.9134C55.7744 33.7891 57.0246 35.9043 57.0246 38.3318C57.0239 39.5218 56.7176 40.6917 56.135 41.7293C55.5525 42.7669 54.7132 43.6376 53.6976 44.2578C54.9831 44.3203 56.2292 44.7218 57.3093 45.4216C58.3895 46.1214 59.2652 47.0945 59.8476 48.2422H61.9484C62.5828 48.2392 63.204 48.4227 63.7349 48.77C64.2657 49.1172 64.6828 49.6129 64.9342 50.1953Z" />
      <path d="M65.2344 52.1484H97.0703V54.4922H65.2344V52.1484Z" />
      <path d="M69.3136 63.6719H65.2343V64.8437H66.9921V78.557C66.9925 78.7099 66.9617 78.8613 66.9018 79.002C66.8419 79.1426 66.7541 79.2696 66.6436 79.3753C66.5331 79.4811 66.4023 79.5632 66.2592 79.6169C66.116 79.6705 65.9634 79.6946 65.8107 79.6875H63.2421C63.1725 79.4087 63.0116 79.1611 62.7851 78.9842C62.5587 78.8073 62.2795 78.7111 61.9921 78.7109H38.2041C37.8621 78.7112 37.5342 78.8472 37.2925 79.0891C37.0508 79.331 36.915 79.659 36.915 80.001V79.6875H34.1892C34.0365 79.6946 33.8839 79.6705 33.7407 79.6169C33.5976 79.5632 33.4668 79.4811 33.3563 79.3753C33.2459 79.2696 33.158 79.1426 33.0981 79.002C33.0382 78.8613 33.0074 78.7099 33.0078 78.557V64.8437H34.9609V63.6719H30.6863C30.5309 63.6719 30.3818 63.7336 30.272 63.8435C30.1621 63.9534 30.1003 64.1024 30.1003 64.2578C30.1003 64.4132 30.1621 64.5622 30.272 64.6721C30.3818 64.782 30.5309 64.8437 30.6863 64.8437H31.0546V78.557C31.0546 78.9663 31.136 79.3715 31.2941 79.749C31.4523 80.1264 31.684 80.4687 31.9758 80.7557C32.2675 81.0427 32.6135 81.2688 32.9935 81.4207C33.3735 81.5726 33.78 81.6474 34.1892 81.6406H36.914V81.5234C36.914 81.8654 37.0498 82.1934 37.2915 82.4353C37.5332 82.6772 37.8611 82.8132 38.2031 82.8135H61.9921C62.3138 82.8135 62.6238 82.6934 62.8614 82.4765C63.099 82.2597 63.2469 81.9619 63.2761 81.6416H65.8117C66.2209 81.6482 66.6273 81.5733 67.0073 81.4213C67.3873 81.2692 67.7332 81.0431 68.0248 80.756C68.3165 80.4689 68.5481 80.1266 68.7061 79.749C68.8642 79.3715 68.9454 78.9663 68.9453 78.557V64.8437H69.3136C69.469 64.8437 69.6181 64.782 69.7279 64.6721C69.8378 64.5622 69.8996 64.4132 69.8996 64.2578C69.8996 64.1024 69.8378 63.9534 69.7279 63.8435C69.6181 63.7336 69.469 63.6719 69.3136 63.6719Z" />
      <path d="M48.0469 72.2656H52.1484V76.7578H48.0469V72.2656Z" />
      <path d="M2.92969 52.1484H34.9609V54.4922H2.92969V52.1484Z" />
      <path d="M38.1797 48.2422H40.2803C40.863 47.095 41.7389 46.1224 42.8192 45.4233C43.8994 44.7242 45.1454 44.3234 46.4307 44.2617C45.4149 43.6408 44.5756 42.7695 43.993 41.7313C43.4105 40.6931 43.1042 39.5227 43.1035 38.3322C43.1035 35.9047 44.3535 33.7891 46.2148 32.4219H10.8359L3.36914 50.1953H35.1939C35.4453 49.6129 35.8624 49.1173 36.3933 48.77C36.9241 48.4227 37.5453 48.2392 38.1797 48.2422ZM14.8762 37.5H13.3918C13.1328 37.5 12.8844 37.3971 12.7013 37.214C12.5181 37.0308 12.4152 36.7824 12.4152 36.5234C12.4152 36.2644 12.5181 36.016 12.7013 35.8329C12.8844 35.6498 13.1328 35.5469 13.3918 35.5469H14.8762C15.1352 35.5469 15.3836 35.6498 15.5667 35.8329C15.7498 36.016 15.8527 36.2644 15.8527 36.5234C15.8527 36.7824 15.7498 37.0308 15.5667 37.214C15.3836 37.3971 15.1352 37.5 14.8762 37.5ZM17.8209 36.5234C17.8209 36.2644 17.9238 36.016 18.1069 35.8329C18.2901 35.6498 18.5385 35.5469 18.7975 35.5469H35.8887C36.1477 35.5469 36.3961 35.6498 36.5792 35.8329C36.7623 36.016 36.8652 36.2644 36.8652 36.5234C36.8652 36.7824 36.7623 37.0308 36.5792 37.214C36.3961 37.3971 36.1477 37.5 35.8887 37.5H18.7975C18.6692 37.5 18.5422 37.4747 18.4237 37.4257C18.3053 37.3766 18.1976 37.3047 18.1069 37.214C18.0162 37.1233 17.9443 37.0156 17.8952 36.8972C17.8462 36.7787 17.8209 36.6517 17.8209 36.5234Z" />
      <path d="M54.6506 93.3594H45.5434C45.1747 93.3593 44.8097 93.4329 44.4698 93.5758C44.1299 93.7186 43.8219 93.9279 43.5639 94.1913C43.3059 94.4547 43.1031 94.767 42.9674 95.1098C42.8317 95.4526 42.7657 95.8191 42.7734 96.1877V96.2891H57.4219V96.1877C57.4294 95.819 57.3633 95.4525 57.2274 95.1097C57.0915 94.7669 56.8886 94.4546 56.6305 94.1912C56.3724 93.9278 56.0644 93.7186 55.7244 93.5758C55.3844 93.4329 55.0193 93.3593 54.6506 93.3594Z" />
      <path d="M48.6328 84.7656H51.5625V91.4062H48.6328V84.7656Z" />
    </svg>
  );
};
FormalInterviewSvg.defaultProps = {
  height: 22,
  width: 22,
  viewBox: '0 0 100 100'
};
export default FormalInterviewSvg;
