import AWS from 'aws-sdk';
import { LRUCache } from 'lru-cache';
import {
  AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY,
  AWS_REGION,
  AWS_TEMP_URL_VALIDITY_HOUR,
  AWS_BUCKET,
  AWS_PROFILE_FOLDER,
  AWS_COMPANY_FOLDER,
  AWS_JOB_OPENING_ATTACHMENT_FOLDER,
  AWS_SOURCE_BOOSTER_FOLDER
} from 'config/app.config';
import { decryptText, encryptText } from './CommonHelper';
import { DICE_SLUG } from './SourceBoosterHelper';

AWS.config.update({
  accessKeyId: decryptText(AWS_ACCESS_KEY_ID),
  secretAccessKey: decryptText(AWS_SECRET_ACCESS_KEY),
  region: AWS_REGION
});

const rootPath: string = 'public/upload';
const s3: AWS.S3 = new AWS.S3();
const myBucket: any = AWS_BUCKET;
const signedUrlExpireSeconds: number = 3600 * AWS_TEMP_URL_VALIDITY_HOUR;
const cache: LRUCache<string, string> = new LRUCache({
  ttl: 3600 * (AWS_TEMP_URL_VALIDITY_HOUR - 1), // Maximum age of items (in milliseconds)
  ttlAutopurge: true
});

export const loadCache: () => void = () => {
  const data: string | null = localStorage.getItem('imageCache');
  if (data) {
    cache.load(JSON.parse(data));
  }
};

export const saveCache: () => void = () => {
  localStorage.setItem('imageCache', JSON.stringify(cache.dump()));
};

export const getAwsUrl: (keyFile: string) => string = keyFile => {
  const cachedUrl: string | undefined = cache.get(keyFile);
  if (cachedUrl) {
    // retrieved from cache
    return cachedUrl;
  }
  const url: string = s3.getSignedUrl('getObject', {
    Bucket: myBucket,
    Key: keyFile,
    Expires: signedUrlExpireSeconds
  });
  // setting cache
  cache.set(keyFile, url);
  return url;
};

export const uploadAwsFile: (
  type: string,
  file: File
) => Promise<string> = async (type, file) => {
  let folder: string = 'images';
  switch (type) {
    case 'profile':
      folder = AWS_PROFILE_FOLDER;
      break;
    case 'company':
      folder = AWS_COMPANY_FOLDER;
      break;
    case 'job_opening_attachment':
      folder = AWS_JOB_OPENING_ATTACHMENT_FOLDER;
      break;
    default:
      folder = 'images';
  }
  const folderName: string = rootPath + '/' + folder;
  let fileName: string = file.name;
  const fileExtension = fileName.split('.').pop(); // Get the file extension
  const timestamp = new Date().getTime();
  fileName = `${type}_${timestamp}.${fileExtension}`;
  const keyName: string = `${folderName}/${fileName}`;
  const params: AWS.S3.PutObjectRequest = {
    Bucket: myBucket,
    Key: keyName,
    Body: file
  };
  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      if (err) {
        console.error(err);
        reject(err);
        return;
      }
      resolve(data.Key);
    });
  });
};
// upload json file using json obj...............
export const uploadJsonObjToAws: (
  type: string,
  fileData: any
) => Promise<string> = async (type, fileData) => {
  let folder: string = 'images';
  switch (type) {
    case DICE_SLUG:
      folder = AWS_SOURCE_BOOSTER_FOLDER + '/' + DICE_SLUG.toLowerCase();
      break;
  }
  const folderName: string = rootPath + '/' + folder;
  // file name and extension
  let fileName: string = fileData.name;
  const fileExtension = 'json'; // Get the file extension
  fileName = `${fileName}.${fileExtension}`;
  const keyName: string = `${folderName}/${fileName}`;
  // json
  const jsonString = JSON.stringify(fileData.jsonObj);
  // params
  const params: AWS.S3.PutObjectRequest = {
    Bucket: myBucket,
    Key: keyName,
    Body: jsonString,
    ContentType: 'application/json' // Set the content type to JSON
  };
  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      if (err) {
        console.error(err);
        reject(err);
        return;
      }
      resolve(data.Key);
    });
  });
};
// Function to check if a file exists in S3
export const checkIfAwsFileExists = async (
  type: string,
  fileName: string
): Promise<boolean> => {
  let folder: string = 'images';
  switch (type) {
    case DICE_SLUG:
      folder = AWS_SOURCE_BOOSTER_FOLDER + '/' + DICE_SLUG.toLowerCase();
      break;
  }
  const folderName: string = rootPath + '/' + folder;
  const keyName: string = `${folderName}/${fileName}`;
  const params = {
    Bucket: myBucket,
    Key: keyName
  };
  try {
    await s3.headObject(params).promise();
    return true;
  } catch (error: any) {
    if (error.code && error.code === 'NotFound') {
      return false;
    }
    throw error;
  }
};

// Function to read a JSON file from Aws File
export const readJsonFromAwsFile = async (
  type: string,
  fileName: string
): Promise<any> => {
  let folder: string = 'images';
  switch (type) {
    case DICE_SLUG:
      folder = AWS_SOURCE_BOOSTER_FOLDER + '/' + DICE_SLUG.toLowerCase();
      break;
  }
  const folderName: string = rootPath + '/' + folder;
  const keyName: string = `${folderName}/${fileName}`;
  const params = {
    Bucket: myBucket,
    Key: keyName
  };
  const data = await s3.getObject(params).promise();
  if (data.Body) {
    const jsonString = data.Body.toString('utf-8');
    return JSON.parse(jsonString);
  }
  return null;
};
// delete file from aws................
export const deleteAwsFile: (file: File) => Promise<boolean> = async file => {
  const keyName: string = `${file}`;
  const params = {
    Bucket: myBucket,
    Key: keyName
  };
  return new Promise((resolve, reject) => {
    s3.deleteObject(params, (err, data) => {
      if (err) {
        console.error(err);
        reject(err);
        return;
      }
      resolve(true);
    });
  });
};
