import React from 'react';
// import Spinner from 'assets/img/svg/preloader.svg';
import './loader.css';
import { Spinner } from 'react-bootstrap';
// import { Spin } from "antd";

export function PreloaderComponent() {
  return (
    <section
      style={{
        height: '100vh',
        width: '100%',
        padding: 'calc(250px + 1.5625vw) 0',
        textAlign: 'center'
      }}
    >
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </section>
  );
}
interface PreloaderProps {
  loading: boolean;
  extraClass?: string;
}
export function Preloader({ loading, extraClass }: PreloaderProps) {
  // for the entire page, to indicate loading on refresh
  if (loading) {
    return (
      <div className={`content-loader ${extraClass ? extraClass : ''}`}>
        <Spinner animation="border" className="spin-loader" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else {
    return null;
  }
}

interface PreloaderLoadMoreProps {
  extraClass?: string;
  height?: string;
}

export function PreloaderLoadMore({ extraClass }: PreloaderLoadMoreProps) {
  return (
    <div
      className={`d-flex justify-content-center align-items-center ${
        extraClass ? extraClass : ''
      }`}
    >
      <Spinner animation="border" className="spin-loader" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}
