import store from 'store';
import api from 'config/api';
import { getUserInfo, storeUserInfo } from 'helpers/StorageHelper';
import { manageAPICall } from './CommonCrudAction';
import { userInfoSelector } from 'components/_trackivo/selectors/authSelectors';
import { successMessage, errorMessage } from 'helpers/ToastNotificationHelper';
import { removeUserData } from 'helpers/StorageHelper';
import apiService from 'services/api.service';
import { t } from 'i18next';

export const loginUser = async (data: object, callback: any) => {
  manageAPICall(api.login.url, api.login.method, data, callback);
};
export const forgotPasswordCall = async (data: object, callback: any) => {
  manageAPICall(
    api.forgotPassword.url,
    api.forgotPassword.method,
    data,
    callback
  );
};
export const resendOTPAPI = async (data: any, callback: any) => {
  manageAPICall(api.resendOTP.url, api.resendOTP.method, data, callback);
};
export const generateMFA = async (data: any, callback: any) => {
  manageAPICall(api.generateMFA.url, api.generateMFA.method, data, callback);
};
export const enableMFA = async (data: any, callback: any) => {
  manageAPICall(api.enableMFA.url, api.enableMFA.method, data, callback);
};
export const removeMFA = async (data: any, callback: any) => {
  manageAPICall(api.removeMFA.url, api.removeMFA.method, data, callback);
};
export const verifyOTPAPI = async (data: any, callback: any) => {
  // const response = await apiService.submitPublicTokenData(
  //   api.verifyOTP.url,
  //   data,
  //   data?.authToken
  // );
  // if (response) {
  //   callback(response.status, response);
  // }
  manageAPICall(api.verifyOTP.url, api.verifyOTP.method, data, callback);
};
// export const updateProfile = async (data: object, callback: any) => {
//   manageAPICall(
//     api.updateProfileDetails.url,
//     api.updateProfileDetails.method,
//     data,
//     callback
//   );
// };
// export const getProfileDetails = async (data: object, callback: any) => {
//   manageAPICall(
//     api.getProfileDetails.url,
//     api.getProfileDetails.method,
//     data,
//     callback
//   );
// };
export const resetPasswordAPI = async (data: object, callback: any) => {
  manageAPICall(
    api.resetPassword.url,
    api.resetPassword.method,
    data,
    callback
  );
};
export const createPasswordAPI = async (data: object, callback: any) => {
  manageAPICall(
    api.createPassword.url,
    api.createPassword.method,
    data,
    callback
  );
};
export const logoutUser = async (callback: any) => {
  // var userInfo = userInfoSelector(store.getState());
  // var deviceId = userInfo?.device?.device_id;
  // if (deviceId) {
  // var data = {
  //   device_id: deviceId,
  // };
  manageAPICall(
    api.logout.url,
    api.logout.method,
    {},
    (status: boolean, response: any) => {
      if (response) {
        if (response.status) {
          successMessage(t('logout_success_msg'));
          removeUserData();
          store.dispatch({
            type: 'UPDATE_USER_DETAILS',
            payload: {}
          });
          callback();
        } else {
          errorMessage(response.message);
        }
      }
    }
  );
};
export const refreshToken = async (callback: any) => {
  const userInfo = userInfoSelector(store.getState());
  const data = {
    refresh_token: userInfo.refresh_token
  };
  manageAPICall(
    api.refreshToken.url,
    api.refreshToken.method,
    data,
    (status: boolean, response: any) => {
      // console.log(response);
      if (response) {
        if (status) {
          const userInfo = getUserInfo();

          if (typeof userInfo === 'string') {
            // Handle the case where userInfo is a string (if needed)
            console.error('Unexpected type: userInfo is a string.');
          } else {
            // Handle the case where userInfo is an object
            const obj: { token?: string } = userInfo || {};

            if (obj.token) {
              obj.token = response.data?.token;
            }

            storeUserInfo(obj);
          }
          callback();
        } else {
          console.log('error');
        }
      }
    }
  );
};
export const getSettings = async (callback: any) => {
  manageAPICall(api.settings.url, api.settings.method, {}, callback);
};
export const getProfileDetails = async (data: any, callback: any) => {
  manageAPICall(api.getProfile.url, api.getProfile.method, data, callback);
};

export const updateProfileData = async (data: any, callback: any) => {
  manageAPICall(
    api.updateProfile.url,
    api.updateProfile.method,
    data,
    callback
  );
};
