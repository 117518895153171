const initialstate = {
  appSettings: {},
  activePageInfo: {
    title: ''
  }
};
interface actionProp {
  type: string;
  payload: object;
}
const SettingReducer = (state = initialstate, action: actionProp) => {
  switch (action.type) {
    case 'UPDATE_ACTIVE_PAGE_DETAILS':
      return {
        ...state,
        activePageInfo: action.payload
      };
    case 'UPDATE_SETTINGS':
      return {
        ...state,
        appSettings: action.payload
      };
    default:
      return state;
  }
};

export default SettingReducer;
