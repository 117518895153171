import Avatar from 'components/base/Avatar';
import { Dropdown, Modal, Nav } from 'react-bootstrap';
import avatar57 from 'assets/img/team/40x40/57.webp';
import ProfileDropdownMenu from './ProfileDropdownMenu';
import NineDotMenu from './NineDotMenu';
import { useAppContext } from 'providers/AppProvider';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import NotificationDropdownMenu from './NotificationDropdownMenu';
import ThemeToggler from 'components/common/ThemeToggler';
import { useEffect, useRef, useState } from 'react';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import SearchResult from 'components/common/SearchResult';
import classNames from 'classnames';
import { getAwsUrl } from 'helpers/AwsHelper';
import { RootState } from 'store';
import { connect } from 'react-redux';
import {
  userProfileImageSelector,
  userProfileSelector,
  userServiceSelector
} from 'components/_trackivo/selectors/authSelectors';
import { DEFAULT_AWS_PROFILE_IMAGE } from 'config/app.config';
import GlobalSearchBox from 'components/_trackivo/common/GlobalSearch/GlobalSearchBox';
import GlobalSearchResult from 'components/_trackivo/common/GlobalSearch/GlobalSearchResult';

const NavItems = ({
  profileImage,
  userServieList
}: {
  profileImage?: any;
  userServieList: any;
}) => {
  const {
    config: { navbarPosition }
  } = useAppContext();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to control dropdown open/close
  const handleNavClick = () => {
    setDropdownOpen(false); // Close the dropdown on nav item click
  };
  useEffect(() => {
    // Check for outside click to close dropdown
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  const dropdownRef = useRef<HTMLDivElement>(null); // Reference for outside click
  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      <Nav.Item>
        <ThemeToggler className="px-2" />
      </Nav.Item>
      <Nav.Item
        className={classNames({
          'd-lg-none':
            navbarPosition === 'vertical' || navbarPosition === 'dual'
        })}
      >
        <Nav.Link onClick={() => setOpenSearchModal(!openSearchModal)}>
          <FeatherIcon icon="search" size={19} style={{ marginBottom: 2 }} />
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Dropdown autoClose="outside" className="h-100">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link h-100"
            variant=""
          >
            <FeatherIcon icon="bell" size={20} />
          </Dropdown.Toggle>
          <NotificationDropdownMenu />
        </Dropdown>
      </Nav.Item>
      {userServieList && userServieList.length > 1 && (
        <Nav.Item>
          <Dropdown autoClose="outside" className="h-100">
            <Dropdown.Toggle
              as={Link}
              to="#!"
              className="dropdown-caret-none nav-link h-100"
              variant=""
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
                <circle cx="2" cy="8" r="2" fill="currentColor"></circle>
                <circle cx="2" cy="14" r="2" fill="currentColor"></circle>
                <circle cx="8" cy="8" r="2" fill="currentColor"></circle>
                <circle cx="8" cy="14" r="2" fill="currentColor"></circle>
                <circle cx="14" cy="8" r="2" fill="currentColor"></circle>
                <circle cx="14" cy="14" r="2" fill="currentColor"></circle>
                <circle cx="8" cy="2" r="2" fill="currentColor"></circle>
                <circle cx="14" cy="2" r="2" fill="currentColor"></circle>
              </svg>
            </Dropdown.Toggle>
            <NineDotMenu />
          </Dropdown>
        </Nav.Item>
      )}
      <Nav.Item ref={dropdownRef}>
        <Dropdown
          /* autoClose="outside"  */
          className="h-100"
          show={dropdownOpen}
        >
          <Dropdown.Toggle
            as={Link}
            onClick={() => setDropdownOpen(!dropdownOpen)}
            className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
            variant=""
          >
            {profileImage && <Avatar src={getAwsUrl(profileImage)} size="l" />}
          </Dropdown.Toggle>
          <ProfileDropdownMenu handleNavClick={handleNavClick} />
        </Dropdown>
      </Nav.Item>

      <Modal
        show={openSearchModal}
        onHide={() => setOpenSearchModal(false)}
        className="search-box-modal mt-15"
      >
        <Modal.Body className="p-0 bg-transparent">
          <GlobalSearchBox
            className="navbar-top-search-box"
            inputClassName="rounded-pill"
            size="lg"
            style={{ width: 'auto' }}
          >
            <GlobalSearchResult />
          </GlobalSearchBox>
        </Modal.Body>
      </Modal>
    </div>
  );
};

// export default NavItems;
const mapStateToProps = (state: RootState) => {
  return {
    profileImage: userProfileImageSelector(state),
    userServieList: userServiceSelector(state)
  };
};
export default connect(mapStateToProps, {})(NavItems);
