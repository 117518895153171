import AppProvider from 'providers/AppProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import BreakpointsProvider from 'providers/BreakpointsProvider';
import SettingsPanelProvider from 'providers/SettingsPanelProvider';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import store from 'store';
// import { router } from 'Routes';
import ChatWidgetProvider from 'providers/ChatWidgetProvider';
import './i18n/config';
import RouteWrapper from 'routes/RouteWrapper';
import App from 'App';
import { Provider } from 'react-redux';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppProvider>
        <SettingsPanelProvider>
          <ChatWidgetProvider>
            <BreakpointsProvider>
              {/* <RouterProvider router={router} /> */}
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </BreakpointsProvider>
          </ChatWidgetProvider>
        </SettingsPanelProvider>
      </AppProvider>
    </Provider>
  </React.StrictMode>
);
