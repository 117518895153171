const initialstate = {
  // listableColunns: [
  //   'sr_no',
  //   'name',
  //   'email',
  //   'mobile',
  //   'joining_availability',
  //   'candidate_status',
  //   'added_by',
  //   'created_at'
  // ],
  //   tableColumnList: [
  //     'sr_no',
  //     'name',
  //     'email',
  //     'mobile',
  //     'joining_availability',
  //     'candidate_status',
  //     'added_by',
  //     'created_at'
  //   ],
  tableColumnValidation: {
    minListColumn: 2,
    maxListColumn: 8,
    minFilterColumn: 0,
    maxFilterColumn: 8
  },
  tableColumnList: [
    {
      name: 'sr_no',
      label_key: 'sr_no',
      allow_list: true,
      allow_filter: false,
      order: 1
    },
    {
      name: 'name',
      label_key: 'name',
      allow_list: true,
      allow_filter: false,
      order: 2
    },
    {
      name: 'email',
      label_key: 'email',
      allow_list: true,
      allow_filter: true,
      order: 3
    },
    {
      name: 'mobile',
      label_key: 'mobile',
      allow_list: true,
      allow_filter: false,
      order: 4
    },
    {
      name: 'joining_availability',
      label_key: 'availability',
      allow_list: true,
      allow_filter: false,
      order: 5
    },
    {
      name: 'candidate_status',
      label_key: 'candidate_status',
      allow_list: true,
      allow_filter: false,
      order: 6
    }
    // {
    //   name: 'added_by',
    //   label_key: 'created_by',
    //   allow_list: true,
    //   allow_filter: false,
    //   order: 7
    // },
    // {
    //   name: 'created_at',
    //   label_key: 'timestamp',
    //   allow_list: true,
    //   allow_filter: false,
    //   order: 8
    // }
  ]
};
interface actionProp {
  type: string;
  payload: object;
}
const CandidateReducer = (state = initialstate, action: actionProp) => {
  switch (action.type) {
    case 'UPDATE_CANDIDATE_TABLE_COLUMN_LIST':
      return {
        ...state,
        tableColumnList: action.payload
      };
    default:
      return state;
  }
};

export default CandidateReducer;
