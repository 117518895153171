import React from 'react';

const BranchSvg = ({
  height = 21,
  width = 21,
  viewBox = '0 0 512 512'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <>
      <svg
        id="OBJECT"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        fill="currentColor"
      >
        <path d="M465.8,109.2c-32.5-16-71.9-2.6-87.8,30-23.7,48.2,8.9,130.7,8.9,130.7,0,0,85.2-24.6,108.9-72.8,16-32.5,2.5-71.9-30-87.8Z" />
        <path d="M34.6,117.9c-29.8,20.7-37.2,61.6-16.6,91.3,30.6,44.1,118.5,55.8,118.5,55.8,0,0,19.9-86.4-10.7-130.6-20.6-29.8-61.5-37.2-91.3-16.5Z" />
        <path d="M22.5,470h128.4c.6-31.2,3.4-61.4,7.9-89.4H46.4c-13.8,27.6-22,57.9-24,89.4Z" />
        <path d="M90.5,319.5c-9.7,9.7-18.4,20.1-26.2,31.1h100.2c7.3-32.5,29.1-67.8,46-93.1-41.3,10-89.3,31.1-120.1,62Z" />
        <path d="M329.5,350.6c-9.9-37.6-40.3-100.4-73.5-99.6-33.2-.7-63.6,62-73.5,99.6h147Z" />
        <path d="M175,380.6c-5.8,27.7-9.2,58-10.1,89.4h182.1c-.8-31.4-4.3-61.7-10.1-89.4h-162Z" />
        <path d="M421.5,319.5c-30.8-30.8-78.8-52-120.1-62,17,25.3,38.7,60.5,46,93.1h100.2c-7.7-11-16.5-21.4-26.2-31.1Z" />
        <path d="M465.6,380.6h-112.4c4.5,28,7.3,58.2,7.9,89.4h128.4c-2-31.5-10.2-61.7-24-89.4Z" />
        <path d="M256,42c-36.3,0-65.6,29.4-65.6,65.6,0,53.7,65.6,113.4,65.6,113.4,0,0,65.6-59.7,65.6-113.4s-29.4-65.6-65.6-65.6Z" />
        <circle fill="#fff" cx="68.6" cy="167.7" r="22.3" />
        <circle fill="#fff" cx="256" cy="102.4" r="22.3" />
        <circle fill="#fff" cx="435.9" cy="172.6" r="22.3" />
      </svg>
    </>
  );
};
export default BranchSvg;
