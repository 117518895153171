import { useState } from 'react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import behance from 'assets/img/nav-icons/behance.webp';
// import googleCloud from 'assets/img/nav-icons/google-cloud.webp';
// import googleDrive from 'assets/img/nav-icons/google-drive.webp';
import figma from 'assets/img/nav-icons/figma.webp';
import Scrollbar from 'components/base/Scrollbar';
import { GoogleDrive, GoogleCloud } from 'components/_trackivo/common/Icons';
import { connect } from 'react-redux';
import {
  userInfoSelector,
  userServiceSelector
} from 'components/_trackivo/selectors/authSelectors';
import { RootState } from 'store';
import { storeUserInfo } from 'helpers/StorageHelper';
import { getUserHomePageRoute } from 'config/routing';
import { useDispatch } from 'react-redux';
import { getAwsUrl } from 'helpers/AwsHelper';
import { t } from 'i18next';
interface NineDotMenuProps {
  userServieList: any;
  userInfo: any;
}
const NineDotMenu = (props: NineDotMenuProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { userServieList, userInfo } = props;
  const [items] = useState([
    // { img: behance, title: 'Behance' },
    { img: GoogleCloud, title: 'Cloud' },
    // { img: slack, title: 'Slack' },
    // { img: gitlab, title: 'Gitlab' },
    // { img: bitbucket, title: 'BitBucket' },
    { img: GoogleDrive, title: 'Drive' },
    // { img: trello, title: 'Trello' },
    { img: figma, title: 'Figma', width: '20' }
    // { img: twitter, title: 'Twitter' },
    // { img: pinterest, title: 'Pinterest' },
    // { img: ln, title: 'Linkedin' },
    // { img: googleMaps, title: 'Maps' },
    // { img: googlePhotos, title: 'Photos' },
    // { img: spotify, title: 'Spotify' }
  ]);
  if (userServieList.length > 1) {
    const switchService = (service: any) => {
      setLoading(true);
      const updatedDetails = { ...userInfo, ...{ selected_service: service } };
      storeUserInfo(updatedDetails);

      setTimeout(() => {
        setLoading(false);
        window.location.href = getUserHomePageRoute();
        // navigate(getUserHomePageRoute());
      }, 200);
    };
    return (
      <Dropdown.Menu
        align="end"
        className="navbar-dropdown-caret py-0 dropdown-nine-dots shadow border border-300"
      >
        <Card
          className="position-relative border-0"
          /* style={{ height: '20rem', minWidth: 244 }} */
          style={{ height: '15rem', minWidth: 244 }}
        >
          <Scrollbar>
            <Card.Body className="pt-3 px-3 pb-0">
              <Row className="text-center align-items-center g-0">
                {userServieList.map((item: any, index: number) => (
                  <Col xs={4} key={index}>
                    <a
                      onClick={() => switchService(item)}
                      title={t('click_here')}
                      className="cursor-pointer d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3"
                    >
                      <img
                        src={getAwsUrl(item.logo)}
                        alt="behance"
                        width={item.width || 30}
                      />
                      <p className="mb-0 text-black text-truncate fs-10 mt-1 pt-1">
                        {item.name}
                      </p>
                    </a>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Scrollbar>
        </Card>
      </Dropdown.Menu>
    );
  }
  return null;
};
const mapStateToProps = (state: RootState) => {
  return {
    userServieList: userServiceSelector(state),
    userInfo: userInfoSelector(state)
  };
};
export default connect(mapStateToProps, {})(NineDotMenu);
