import React from 'react';

const JobOpeningSvg = ({
  height = 21,
  width = 21,
  viewBox = '0 0 512 512'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <defs>
        <style></style>
      </defs>
      <path
        fill="currentColor"
        strokeWidth={'0px'}
        className="cls-1"
        d="M121,224.7c8.3,0,15-6.7,15-15V80.8c0-8.3-6.7-15-15-15s-15,6.7-15,15v128.9c0,8.3,6.7,15,15,15h0Z"
      />
      <path
        fill="currentColor"
        strokeWidth={'0px'}
        className="cls-1"
        d="M280.1,224.7c8.3,0,15-6.7,15-15V80.8c0-8.3-6.7-15-15-15s-15,6.7-15,15v128.9c0,8.3,6.7,15,15,15h0Z"
      />
      <path
        fill="currentColor"
        strokeWidth={'0px'}
        className="cls-1"
        d="M331.6,224.7c8.3,0,15-6.7,15-15v-55.4l26.1,61.3c2.4,5.6,7.9,9.1,13.8,9.1s2-.1,3-.3c7-1.4,12-7.6,12-14.7V80.8c0-8.3-6.7-15-15-15s-15,6.7-15,15v55.4l-26.1-61.3c-2.8-6.6-9.8-10.2-16.8-8.8-7,1.4-12,7.6-12,14.7v128.9c0,8.3,6.7,15,15,15h0Z"
      />
      <path
        fill="currentColor"
        strokeWidth={'0px'}
        className="cls-1"
        d="M497,131.6h-8.7c-8.3,0-15,6.7-15,15s3.5,11.2,8.7,13.6v20c0,8-6.5,14.5-14.4,14.5s-14.4-6.5-14.4-14.5v-70c0-8,6.5-14.5,14.4-14.5s11.5,3.8,13.6,9.5c2.8,7.8,11.4,11.8,19.2,9,7.8-2.8,11.8-11.4,9-19.2-6.4-17.5-23.2-29.3-41.8-29.3s-44.4,19.9-44.4,44.5v70c0,24.5,19.9,44.5,44.4,44.5s44.4-19.9,44.4-44.5v-33.6c0-8.3-6.7-15-15-15h0Z"
      />
      <path
        fill="currentColor"
        strokeWidth={'0px'}
        className="cls-1"
        d="M15.5,224.7c8.3,0,15-6.7,15-15v-49.5h23.9v49.5c0,8.3,6.7,15,15,15s15-6.7,15-15V80.8c0-8.3-6.7-15-15-15s-15,6.7-15,15v49.5h-23.9v-49.5c0-8.3-6.7-15-15-15S.5,72.5.5,80.8v128.9c0,8.3,6.7,15,15,15h0Z"
      />
      <path
        fill="currentColor"
        strokeWidth={'0px'}
        className="cls-1"
        d="M172.5,224.7c8.3,0,15-6.7,15-15v-49.5h8.8c9.5,0,17.2,7.7,17.2,17.2v32.2c0,8.3,6.7,15,15,15s15-6.7,15-15v-32.2c0-12.4-4.8-23.8-12.7-32.2,7.9-8.4,12.7-19.8,12.7-32.2,0-26-21.2-47.2-47.2-47.2h-23.8c-8.3,0-15,6.7-15,15v128.9c0,8.3,6.7,15,15,15h0ZM187.5,95.8h8.8c9.5,0,17.2,7.7,17.2,17.2s-7.7,17.2-17.2,17.2h-8.8v-34.5Z"
      />
      <path
        fill="currentColor"
        strokeWidth={'0px'}
        className="cls-2"
        d="M416.5,255c-47.6,0-87.1,35-94.3,80.6h-22.3c-6.7-25.4-29.8-44.2-57.2-44.2s-50.6,18.8-57.2,44.2h-25.7c-6.7-25.4-29.8-44.2-57.2-44.2s-50.6,18.8-57.2,44.2H15C6.7,335.6,0,342.3,0,350.6s6.7,15,15,15h30.3c6.7,25.4,29.8,44.2,57.2,44.2s50.6-18.8,57.2-44.2h25.7c6.7,25.4,29.8,44.2,57.2,44.2s50.6-18.8,57.2-44.2h22.3c7.2,45.6,46.8,80.6,94.3,80.6s95.5-42.9,95.5-95.6-42.8-95.6-95.5-95.6h0ZM456.4,341.2l-40,40c-2.9,2.9-6.8,4.4-10.6,4.4s-7.3-1.3-10.1-3.9l-18.6-17c-6.1-5.6-6.5-15.1-.9-21.2,5.6-6.1,15.1-6.5,21.2-.9l8,7.3,29.9-29.9c5.9-5.9,15.4-5.9,21.2,0,5.9,5.9,5.9,15.4,0,21.2h0Z"
      />
    </svg>
  );
};
export default JobOpeningSvg;
