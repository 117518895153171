import store from 'store';
import api from 'config/api';
import { manageAPICall, manageSourceBoosterAPICall } from './CommonCrudAction';
// global search
export const getGlobalSearchData = async (data: object, callback: any) => {
  manageAPICall(api.globalSearch.url, api.globalSearch.method, data, callback);
};
// ATS Dashboard ..........................................
export const getATSDashboardCounter = async (data: object, callback: any) => {
  manageAPICall(
    api.atsDashboardCounter.url,
    api.atsDashboardCounter.method,
    data,
    callback
  );
};
export const getATSDashboardHiringPipeline = async (
  data: object,
  callback: any
) => {
  manageAPICall(
    api.atsDashboardHiringPipeline.url,
    api.atsDashboardHiringPipeline.method,
    data,
    callback
  );
};
export const getATSDashboardApplicatioNChartData = async (
  data: object,
  callback: any
) => {
  manageAPICall(
    api.atsDashboardApplicationChart.url,
    api.atsDashboardApplicationChart.method,
    data,
    callback
  );
};
export const getATSDashboardCandidateStageData = async (
  data: object,
  callback: any
) => {
  manageAPICall(
    api.atsDashboardCandidateStages.url,
    api.atsDashboardCandidateStages.method,
    data,
    callback
  );
};
export const getATSDashboardRateMeterData = async (
  data: object,
  callback: any
) => {
  manageAPICall(
    api.atsDashboardRateMeter.url,
    api.atsDashboardRateMeter.method,
    data,
    callback
  );
};
// calendar...............................
export const getCalenderData = async (data: object, callback: any) => {
  manageAPICall(api.myCalendar.url, api.myCalendar.method, data, callback);
};
export const addCalendarTask = async (data: object, callback: any) => {
  manageAPICall(
    api.addCalendarTask.url,
    api.addCalendarTask.method,
    data,
    callback
  );
};
export const editCalendarTask = async (data: object, callback: any) => {
  manageAPICall(
    api.editCalendarTask.url,
    api.editCalendarTask.method,
    data,
    callback
  );
};
export const getCalendarTaskInfo = async (data: object, callback: any) => {
  manageAPICall(
    api.getCalendarTaskInfo.url,
    api.getCalendarTaskInfo.method,
    data,
    callback
  );
};
export const deleteCalendarTask = async (data: object, callback: any) => {
  manageAPICall(
    api.deleteCalendarTask.url,
    api.deleteCalendarTask.method,
    data,
    callback
  );
};
