// module.exports = {
//   API_URL: process.env.REACT_APP_BACKEND_API_URL,
//   AWS_PROFILE_FOLDER: 'profile',
//   GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
//   AWS_TEMP_URL_VALIDITY_HOUR: 24,
//   AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
//   AWS_SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
//   AWS_REGION: process.env.REACT_APP_AWS_REGION,
//   AWS_BUCKET: process.env.REACT_APP_AWS_BUCKET,
//   CRYPTO_SECRET_KEY: 'ztohxhkzvgxzjxgyzzmg',
//   DEVICE_TYPE: 'W',
//   DEFAULT_COUNTRY_CODE: '91',
//   FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
//   FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   FIREBASE_MESSAGING_SENDER_ID:
//     process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
//   FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
//   FIREBASE_VAPID_KEY: process.env.REACT_APP_FIREBASE_VAPID_KEY
// };
export const APP_TITLE = process.env.REACT_APP_TITLE;
export const CRYPTO_SECRET_KEY = 'ztohxhkzvgxzjxgyzzmg';
export const API_URL = process.env.REACT_APP_BACKEND_API_URL;
// AWS
export const AWS_TEMP_URL_VALIDITY_HOUR = 24;
export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
export const AWS_SECRET_ACCESS_KEY =
  process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const AWS_BUCKET = process.env.REACT_APP_AWS_BUCKET;
// firebase
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
export const FIREBASE_VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;
// login app details
export const APP_CODE = '1.0.0';
export const DEVICE_TYPE = 'W';
export const APP_SETTINGS_REFRESH_EVERY_MINUTES = 20;
// default aws image
export const DEFAULT_AWS_PROFILE_IMAGE = 'public/upload/profile/user.jpg';
export const DEFAULT_AWS_IMAGE = {
  profile: 'public/upload/placeholder/profile.png',
  profileMale: 'public/upload/placeholder/profile_male.png',
  profileFemale: 'public/upload/placeholder/profile_female.png',
  companyLogo: 'public/upload/placeholder/company.png'
};
export const AWS_PROFILE_FOLDER = 'profile';
export const AWS_COMPANY_FOLDER = 'company';
export const AWS_SOURCE_BOOSTER_FOLDER = 'source_booster';
export const AWS_JOB_OPENING_ATTACHMENT_FOLDER = 'job_opening_attachment';

// for mobile code field
export const DEFAULT_MOBILE_CODE_COUNTRY = 'us';
export const DEFAULT_MOBILE_CODE = '1';
// role slugs
export const SUPER_ADMIN_ROLE_SLUG = 'admin';
// SOURCE_BOOSTER
export const SOURCE_BOOSTER_TOKEN_REFRESH_EVERY_MINUTES = 120;
