import React from 'react';

const ClientSvg = ({
  height = 21,
  width = 21,
  viewBox = '0 0 512 512'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <defs>
        <style>
          {`
        .cls-1, .cls-2 {
          stroke-width: 0px;
        }
        `}
        </style>
      </defs>
      <path
        fill="currentColor"
        className="cls-1"
        d="M334.5,146.4c17.8,19.5,27.6,44.9,27.6,71.2v1.1c5.1,2.9,10.6,4.9,16.4,6.1,39.7,9.1,79.3-15.7,88.4-55.4,9.1-39.7-15.7-79.3-55.4-88.4-39.4-9.1-78.8,15.3-88.3,54.7,4,3.3,7.8,6.8,11.3,10.7h0Z"
      />
      <path
        fill="currentColor"
        className="cls-1"
        d="M492.8,250.8c-10.8-11.9-23.8-21.5-38.2-28.4-3.1,2.6-6.4,5.1-9.9,7.3-24.7,16-55.5,19.2-82.9,8.5-2.8-1.8-1.9,3.5-2.9,5.2-4.2,16.5-12.3,31.8-23.6,44.5,21.3,10,40.1,24.6,55.2,42.6h66.5c40.4,1.1,63.7-50.4,35.8-79.8h0Z"
      />
      <path
        fill="currentColor"
        className="cls-1"
        d="M76.9,214.8c21.9,14.2,49.7,15.7,73,3.9,0-4.7.2-9.5.8-14.1,3.4-27,17-51.7,38.1-68.8-8-33.1-37.7-56.5-71.8-56.6-73.5.3-101.1,96.4-40.1,135.6Z"
      />
      <path
        fill="currentColor"
        className="cls-1"
        d="M134,317.5c12.6-12,27-22,42.8-29.4-12.6-14.3-21.2-31.8-24.9-50.5-31.8,13-68.2,7.2-94.4-15.1-14.5,6.9-27.5,16.5-38.2,28.4-27.9,29.4-4.6,80.9,35.8,79.8h66.6c3.7-4.8,7.8-9.2,12.4-13.2h0Z"
      />
      <path
        fill="currentColor"
        className="cls-2"
        d="M168.6,205.7c-10,65.5,58.7,119.2,119.6,94.1,56.4-21,74.8-97.6,33.1-141.4-48.7-55.3-143.5-26.1-152.7,47.3Z"
      />
      <path
        fill="currentColor"
        className="cls-2"
        d="M366.1,330.6c-13-12.5-28.3-22.5-45-29.4-38.3,29.7-91.9,29.7-130.2,0-20.2,8.4-38.3,21.2-52.9,37.4-33.1,34.6-5.5,95.6,42.2,94.2h151.7c54.1.3,76.8-69.3,34.2-102.2h0Z"
      />
    </svg>
  );
};
export default ClientSvg;
