import React from 'react';

const RoleSvg = ({
  height = 21,
  width = 21,
  viewBox = '0 0 512 512'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <>
      <svg
        id="OBJECT"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        fill="currentColor"
      >
        <g>
          <g>
            <path
              d="M256,151c-41.353,0-75,33.647-75,75c0,41.353,33.647,75,75,75c41.353,0,75-33.647,75-75C331,184.647,297.353,151,256,151z
			"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M501.746,211.776l-27.045-8.687c-5.479-22.734-14.414-44.297-26.66-64.292l12.29-24.58
			c2.886-5.771,1.758-12.744-2.813-17.314l-42.422-42.422c-4.585-4.585-11.528-5.684-17.314-2.813l-24.595,12.305
			c-18.252-11.147-38.042-19.585-59.019-25.166l-14.75-30.516C296.869,3.208,291.684,0,286,0h-60
			c-5.684,0-10.869,3.208-13.418,8.291l-14.751,30.517c-20.977,5.581-40.767,14.019-59.019,25.166l-24.595-12.305
			c-5.801-2.915-12.729-1.787-17.314,2.813L54.481,96.903c-4.57,4.57-5.698,11.543-2.813,17.314l12.305,24.595
			c-11.147,18.252-19.585,38.042-25.166,59.019L8.291,212.582C3.208,215.131,0,220.316,0,226v60c0,5.684,3.208,10.869,8.291,13.418
			l30.517,14.751c5.068,18.94,12.612,37.119,22.5,54.214l-10.459,31.362c-1.787,5.391-0.381,11.338,3.633,15.352l42.422,42.422
			c4.028,4.014,9.946,5.42,15.352,3.633l31.362-10.459c18.853,10.942,38.789,18.984,59.473,23.994l8.687,27.06
			C213.813,507.869,219.54,512,226,512h60c6.46,0,12.188-4.131,14.224-10.254l8.687-27.06c20.684-5.01,40.62-13.052,59.473-23.994
			l31.362,10.459c5.405,1.787,11.353,0.381,15.352-3.633l42.422-42.422c4.014-4.014,5.42-9.961,3.633-15.352l-10.459-31.362
			c10.942-18.853,18.984-38.789,23.994-59.473l27.06-8.687C507.869,298.188,512,292.46,512,286v-60
			C512,219.54,507.869,213.813,501.746,211.776z M256,421c-90.981,0-165-74.019-165-165S165.019,91,256,91s165,74.019,165,165
			S346.981,421,256,421z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M328.951,301.247C310.036,319.587,284.359,331,256,331s-54.04-11.413-72.953-29.753
			c-12.393,12.252-21.903,27.437-27.092,44.562C180.687,373.334,216.167,391,256,391c39.825,0,75.302-17.659,100.032-45.176
			C350.848,328.691,341.341,313.5,328.951,301.247z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    </>
  );
};
export default RoleSvg;
